import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { Text } from '@components/base'
import { useScoreStatistics } from '@src/screens/Events/utils/useScoreStatistics'
import { HighlightsCard } from '../Highlights/HighlightsCard'
import { GlucoseScoreGauge } from './GlucoseScoreGauge'

export const GlucoseScoreCard = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const {
    score: { score = 0 },
  } = useScoreStatistics()

  const handleMorePress = () => {
    navigation.navigate('GlucoseScoreModal')
  }

  return (
    <HighlightsCard onMorePress={handleMorePress} title="Daily Glucose Score">
      <View style={styles.container}>
        <GlucoseScoreGauge score={score} />
        <Text type="small" lineSpacing="none" style={styles.subtitle}>
          Overall performance for the day
        </Text>
      </View>
    </HighlightsCard>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  subtitle: {
    maxWidth: 100,
    marginLeft: 8,
  },
})
