import { gql } from '@apollo/client'

export const CANCEL_APPOINTMENT = gql`
  mutation cancelEhrAppointment($id: ID!) {
    cancelEhrAppointment(id: $id) {
      id
      lastState {
        kind
      }
    }
  }
`
