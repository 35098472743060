import moment, { Moment } from 'moment'
import { TWELVE_HOURS_TIME_FORMAT } from '@src/config/momentFormat'
import { ChartData } from '@src/models/dailyCharts/types'
import { ChartValue } from '@src/models/dailyCharts/types'

interface FormattedDates {
  [key: string]: string
}

export const formatDates = (dates: Moment[], format = 'hA') => {
  const formattedDates = {} as FormattedDates
  dates.forEach((momentDate) => {
    formattedDates[momentDate.valueOf()] = momentDate.format(format)
  })
  return formattedDates
}

export const addDisplayDateToData = (dataPoints: ChartValue[] = [], format: string) => {
  return dataPoints.map((dataPoint) => ({
    ...dataPoint,
    displayDate: dataPoint.x.format(format),
  }))
}

export const getMultisliderMinMax = (values: ChartValue[]) => {
  if (values.length === 0) {
    return {
      minMoment: moment().startOf('day').unix(),
      maxMoment: moment().endOf('day').unix(),
    }
  }

  const momentArray = values.map((item) => moment(item.x))
  const minMoment = moment.min(momentArray).unix()
  const maxMoment = moment.max(momentArray).unix()

  return {
    minMoment,
    maxMoment,
  }
}

export const isChartValue = (item?: ChartValue) => {
  return item?.tag === 'Glucose' || item?.tag === 'Ketones' || item?.tag === 'LiveGlucose'
}

export const isRealTimeValue = (item?: ChartValue) => {
  return item?.tag === 'LiveGlucose'
}

export const isDataAvailable = (values: ChartValue[]) => {
  return values.some((value) => value.y && isChartValue(value))
}

export const buildPrimaryValues = (data: ChartData) => {
  return {
    ...data,
    values: (data?.values || [])
      .filter((value) => value.y !== null)
      .map((value) => ({
        x: value.x,
        y: value.y,
        tag: value.tag,
      })),
  }
}

const SECONDS_IN_HOUR = 60 * 60
const TICKS_INTERVAL_IN_HOURS = 3

export const getTickOptions = (values: ChartValue[], rangeDays: number) => {
  let timeFormat = 'hA'
  let tickCount = 1

  if (values.length > 1) {
    const { minMoment, maxMoment } = getMultisliderMinMax(values)

    const filteredIntervalInHours = Math.round((maxMoment - minMoment) / SECONDS_IN_HOUR)

    // multi-day tick format takes more space, so we should show twice less ticks
    const maxTickCount = rangeDays > 1 ? 4 : 7

    if (filteredIntervalInHours <= 6) {
      // show minutes if interval is less than 6 hours
      timeFormat = TWELVE_HOURS_TIME_FORMAT
    }

    if (filteredIntervalInHours <= 3) {
      tickCount = 3
    } else if (filteredIntervalInHours <= 6) {
      tickCount = 4
    } else if (filteredIntervalInHours <= 9) {
      tickCount = 5
    } else {
      // we want 7 ticks to be displayed for single day
      tickCount = Math.min(
        Math.ceil(filteredIntervalInHours / TICKS_INTERVAL_IN_HOURS),
        maxTickCount,
      )
    }
  }

  const tickDateFormat = rangeDays > 1 ? `MM/DD ${timeFormat}` : timeFormat

  return { tickCount, tickDateFormat }
}
