import { gql } from '@apollo/client'

export const FETCH_EHR_AVAILABLE_DATES = gql`
  query ehrAvailableDates(
    $appointmentKind: EhrAppointmentKind
    $providerKind: EhrAppointmentProviderKind
    $timeZone: String
    $appointmentId: ID
    $userState: AddressState
  ) {
    ehrAvailableDates(
      appointmentKind: $appointmentKind
      providerKind: $providerKind
      timeZone: $timeZone
      appointmentId: $appointmentId
      userState: $userState
    ) {
      dates
    }
  }
`
