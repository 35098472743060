import React, { useEffect, useMemo, useState } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, IconButton } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { buildSections } from '@src/screens/Events/utils/utils'
import {
  CompareFilters,
  MealComparisonItem,
  MealPlaceholder,
  MealHistoryList,
} from '@screens/Meals/components/CompareMeals'
import { useMeals } from '@screens/Meals/hooks'
import { allMealsSelector, mealsSortSelector, mealsCalendarSelector } from '@selectors/meals'
import { Meal } from '@src/types'

const COMPARED_MEALS_COUNT = 2

export const CompareMeals = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { params } = useRoute<RouteProp<AppStackParamList, 'CompareMeals'>>()

  const allMeals = useSelector(allMealsSelector)
  const sort = useSelector(mealsSortSelector)
  const calendar = useSelector(mealsCalendarSelector)

  const { meals } = useMeals(params.initialMealIds.split(','))

  const [comparedMeals, setComparedMeals] = useState<Meal[]>([])
  const [filtersVisible, setFiltersVisible] = useState(false)

  const handleMealSelect = (meal: Meal) => {
    if (comparedMeals.length < COMPARED_MEALS_COUNT) {
      setComparedMeals((prev) => [...prev, meal])
    }
  }

  const removeMeal = (meal: Meal) => {
    setComparedMeals((prev) => prev.filter((m) => m.id !== meal.id))
  }

  const handleMealsComparePress = () => {
    navigation.navigate('CompareMealsDetails', {
      mealIds: comparedMeals.map(({ id }) => id).join(','),
    })
  }

  useEffect(() => {
    if (meals.length > 0) {
      setComparedMeals(meals)
    }
  }, [meals])

  useEffect(() => {
    return () => {
      dispatch({ type: 'meals/resetFilters' })
    }
  }, [dispatch])

  const searchIcon = (
    <IconButton
      accessibilityLabel="Toggle filters"
      iconName="funnel"
      size="m"
      type="transparent"
      onPress={() => setFiltersVisible(!filtersVisible)}
    />
  )

  const sections = useMemo(() => {
    const filteredMeals = allMeals.filter((meal) => !comparedMeals.some(({ id }) => meal.id === id))
    return buildSections(filteredMeals)
  }, [allMeals, comparedMeals])

  const [primaryMeal, secondaryMeal] = comparedMeals

  return (
    <NavigationContainer title="Compare Meals" rightAccessories={[searchIcon]}>
      <SafeAreaView style={styles.container} edges={['bottom']}>
        {filtersVisible && <CompareFilters />}
        {primaryMeal ? (
          <>
            <MealComparisonItem meal={primaryMeal} onRemove={() => removeMeal(primaryMeal)} />
            {secondaryMeal ? (
              <MealComparisonItem meal={secondaryMeal} onRemove={() => removeMeal(secondaryMeal)} />
            ) : (
              <MealPlaceholder meal="secondary" />
            )}
          </>
        ) : (
          <MealPlaceholder meal="primary" />
        )}
        <MealHistoryList
          name="meals"
          items={sections}
          calendar={calendar}
          query={sort.query}
          sort={sort}
          filter={{ scope: 'meals' }}
          pagination={{ type: 'date' }}
          onMealSelect={handleMealSelect}
          useCache
        />
        <Button
          accessibilityLabel="Compare meals"
          accessoryLeft={<Icon name="arrows-left-right" />}
          size="large"
          type="primary"
          style={styles.compareButton}
          disabled={comparedMeals.length < COMPARED_MEALS_COUNT}
          onPress={handleMealsComparePress}
        >
          Compare Now
        </Button>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  compareButton: {
    width: 280,
    marginTop: 16,
  },
  noResults: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
