import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import {
  Image,
  LayoutChangeEvent,
  NativeScrollEvent,
  ScrollView,
  useWindowDimensions,
  View,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Selector, useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, TimelineChart, useFeatureFlag } from '@components'
import { DashboardGraph, ThresholdType } from '@screens/Settings/models/settings.types'
import { ChartTypes, PLACEHOLDER_TEXT_BY_CHART_TYPE } from '@src/components/Timeline/constants'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { settingsSelector } from '@src/selectors/settings'
import { Analytics, CustomEventTypes, Device } from '@src/config'
import { useIsRealTimeGlucoseValueWithinChartRange, useLiveGlucoseValue } from '@src/utils/hooks'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { blurredGlucoseChartImage } from '@assets/images'
import { useIsDarkMode } from '@src/config/theme'
import { useShowByosIntegrationWarning } from '@src/screens/OwnSensorOnboarding/hooks/useShowByosIntegrationWarning'
import { ByosIntegrationWarning } from '@src/components/Timeline/ByosIntegrationWarning'
import { RootStoreState } from '@src/models/app.types'
import { TimelineHeader } from '@src/screens/Events/components/TimelineHeader'
import { UpsellCGMBanner } from '@src/screens/Home/components/Banners/UpsellCGMBanner'
import { eventsChartsSelector } from '@src/models/dailyCharts/selectors'
import { ChartValue } from '@src/models/dailyCharts/types'
import { eventsForDateSelector } from '@src/selectors/events'
import { EventsItemType } from '@src/screens/Events/models/events.types'

export interface TimelineProps {
  primaryChartRef: MutableRefObject<any>
  secondaryChartRef: MutableRefObject<any>
  onChartLoadEnd: () => void
  onChartHover?: (payload: any) => void
  hideMultiSlider?: boolean
  hideChartHeader?: boolean
  showLastReadingTooltip?: boolean
  multiSliderValuesChange?: (values: [number, number]) => void
  chartsSelector?: Selector<RootStoreState, { primary: any; secondary: any }>
  customChartHeight?: number
}

const CHART_WIDTH_HEIGHT_RATIO = 1.6
const MAX_CHART_HEIGHT = 330

export const Timeline = React.memo((props: TimelineProps) => {
  const {
    primaryChartRef,
    secondaryChartRef,
    onChartLoadEnd,
    onChartHover,
    hideMultiSlider = false,
    hideChartHeader = false,
    showLastReadingTooltip = false,
    multiSliderValuesChange,
    customChartHeight,
    chartsSelector = eventsChartsSelector<EventsItemType>(eventsForDateSelector),
  } = props

  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const scrollRef = useRef<any>()
  const [selectedChartIndex, setSelectedChartIndex] = useState(0)

  const { primary, secondary } = useSelector(chartsSelector)
  const { dashboardGraph } = useSelector(settingsSelector)
  const { realTimeGlucoseValue, realTimeGlucoseTime } = useLiveGlucoseValue()
  const libre3RealTimeGlucoseChart = useFeatureFlag(Feature.Libre3RealTimeGlucoseChart)
  const isDarkMode = useIsDarkMode()
  const { thirdPartyWarning, nativeWarning } = useShowByosIntegrationWarning()
  const showByosIntegrationWarning = thirdPartyWarning || nativeWarning

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const isRealTimeGlucoseValueWithinChartRange = useIsRealTimeGlucoseValueWithinChartRange(
    primary.values,
    realTimeGlucoseTime,
  )

  if (
    libre3RealTimeGlucoseChart &&
    realTimeGlucoseValue &&
    realTimeGlucoseTime &&
    isRealTimeGlucoseValueWithinChartRange
  ) {
    // filter out any existing live glucose values
    primary.values = primary.values.filter((point: ChartValue) => point.tag !== 'LiveGlucose')

    const realTimeChartDataPoint = {
      tag: 'LiveGlucose',
      x: moment(realTimeGlucoseTime),
      y: realTimeGlucoseValue,
      fake: false,
    } as ChartValue

    // add the latest live glucose value
    primary.values.push(realTimeChartDataPoint)
  }

  const dimensions = useWindowDimensions()
  const isScanDisabled = useDisableInAppScan()
  const [contentWidth, setContentWidth] = useState(dimensions.width)

  const onNavigateToCard = (index: number, contentWidth: number) => {
    if (!scrollRef.current) {
      return
    }

    scrollRef.current.scrollTo({ x: contentWidth * index, y: 0, animated: true })
  }

  const handleSelectChartIndex = (index: number) => {
    onNavigateToCard(index, contentWidth)
  }

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const newContentWidth = nativeEvent.layout.width
    if (!newContentWidth) {
      return
    }

    if (newContentWidth !== contentWidth) {
      onNavigateToCard(selectedChartIndex, newContentWidth)
    }
    setContentWidth(newContentWidth)
  }

  const onScroll = ({ nativeEvent }: { nativeEvent: NativeScrollEvent }) => {
    const index = Math.round(nativeEvent.contentOffset.x / contentWidth)
    if (index !== selectedChartIndex) {
      Analytics.track(CustomEventTypes.EventsSelectChart, {
        type: index === 0 ? 'glucose' : 'ketone',
      })
      setSelectedChartIndex(index)
    }
  }

  const onZoomChart = () => {
    const type = selectedChartIndex === 0 ? ChartTypes.Glucose : ChartTypes.Ketones
    navigation.navigate('ZoomChart', {
      type,
      forceLandscapeOrientation: !Device.hasLargeScreen(dimensions),
    })
  }

  const onInfoPress = () => {
    navigation.navigate('TimelineTutorialModal', {
      timeline: selectedChartIndex === 0 ? 'Glucose' : 'Ketone',
    })
  }

  const onSettingsPress = () => {
    if (selectedChartIndex === 0) {
      navigation.navigate('GlucoseThresholdSettings', { source: 'Events' })
    } else {
      navigation.navigate('ThresholdSettings', { type: ThresholdType.Ketone, source: 'Events' })
    }
  }

  const isKetoneChartIncluded = dashboardGraph === DashboardGraph.Combined

  useEffect(() => {
    if (!isKetoneChartIncluded) {
      setSelectedChartIndex(0)
      onNavigateToCard(0, contentWidth)
    }
  }, [isKetoneChartIncluded, setSelectedChartIndex, contentWidth])

  const chartItems = useMemo(
    () => (isKetoneChartIncluded ? ['Glucose Chart', 'Ketone Chart'] : ['Glucose Chart']),
    [isKetoneChartIncluded],
  )

  const chartStyle = {
    width: contentWidth,
  }

  const height = customChartHeight
    ? customChartHeight
    : Math.min(contentWidth / CHART_WIDTH_HEIGHT_RATIO, MAX_CHART_HEIGHT)

  const headerComponent = (
    <TimelineHeader
      title={chartItems[selectedChartIndex]}
      charts={chartItems}
      testID="Timeline"
      selectedChartIndex={selectedChartIndex}
      onSelectChartIndex={handleSelectChartIndex}
      onExpandPress={onZoomChart}
      onInfoPress={onInfoPress}
      onSettingsPress={onSettingsPress}
    />
  )

  if (!isCGMFeatureAvailable) {
    return (
      <UpsellCGMBanner
        image={
          <Image
            style={{ height, width: contentWidth }}
            source={blurredGlucoseChartImage(isDarkMode).imageSource}
          />
        }
        header={!hideChartHeader && headerComponent}
      />
    )
  }

  if (showByosIntegrationWarning) {
    return (
      <>
        {!hideChartHeader && headerComponent}
        <View>
          <Image
            style={{ height, width: contentWidth }}
            source={blurredGlucoseChartImage(isDarkMode).imageSource}
          />
          <ByosIntegrationWarning
            thirdPartyWarning={thirdPartyWarning}
            nativeWarning={nativeWarning}
          />
        </View>
      </>
    )
  }

  return (
    <View style={styles.container}>
      {!hideChartHeader && headerComponent}
      <ScrollView
        ref={scrollRef}
        horizontal
        pagingEnabled
        scrollEnabled={false}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        onScroll={onScroll}
        onLayout={onLayout}
        scrollEventThrottle={0}
        style={styles.scrollView}
        contentContainerStyle={{ height }}
      >
        <TimelineChart
          chartRef={primaryChartRef}
          style={chartStyle}
          data={primary}
          type={ChartTypes.Glucose}
          onChange={onChartHover}
          onLoadEnd={onChartLoadEnd}
          multiSliderValuesChange={multiSliderValuesChange}
          placeholder={isScanDisabled ? null : PLACEHOLDER_TEXT_BY_CHART_TYPE[ChartTypes.Glucose]}
          hideMultiSlider={hideMultiSlider}
          showLastReadingTooltip={showLastReadingTooltip}
        />
        {isKetoneChartIncluded && (
          <TimelineChart
            chartRef={secondaryChartRef}
            style={chartStyle}
            data={secondary}
            type={ChartTypes.Ketones}
            onChange={onChartHover}
            onLoadEnd={onChartLoadEnd}
            placeholder={PLACEHOLDER_TEXT_BY_CHART_TYPE[ChartTypes.Ketones]}
            showPoints
            connectNulls
            hideMultiSlider={hideMultiSlider}
          />
        )}
      </ScrollView>
    </View>
  )
})

const themedStyles = StyleService.create({
  scrollView: {
    flexGrow: 0,
  },
  container: {
    backgroundColor: 'theme.background',
  },
})
