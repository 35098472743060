import { gql } from '@apollo/client'

export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleEhrAppointment($id: ID!, $date: String) {
    rescheduleEhrAppointment(id: $id, date: $date) {
      id
      lastState {
        kind
      }
    }
  }
`
