import React from 'react'
import { DrawerContentComponentProps, createDrawerNavigator } from '@react-navigation/drawer'
import { useTheme } from '@ui-kitten/components'
import { createStackNavigator } from '@react-navigation/stack'
import { TabNavigator } from '@src/navigation'
import { HomeScreen } from '@screens'
import { DrawerContent } from '@src/navigation/components/drawer/DrawerContent'
import { useHasLargeScreen } from '@src/config/device'
import { useDrawerRoutes } from '../components/drawer/hooks'
import { DashboardStackParamsList, DrawerParamList } from '../types'

export const LARGE_SCREEN_DRAWER_WIDTH = 280

const DashboardStack = createStackNavigator<DashboardStackParamsList>()

const DrawerStack = createDrawerNavigator<DrawerParamList>()

const DashboardNavigator = () => (
  <DashboardStack.Navigator screenOptions={{ headerShown: false }}>
    <DashboardStack.Screen name="Home" component={HomeScreen} />
  </DashboardStack.Navigator>
)

const drawerContent = (props: DrawerContentComponentProps) => <DrawerContent {...props} />

export const DrawerNavigator = () => {
  const theme = useTheme()
  const hasLargeScreen = useHasLargeScreen()
  const drawerRoutes = useDrawerRoutes()

  return (
    <DrawerStack.Navigator
      defaultStatus="closed"
      initialRouteName="Dashboard"
      drawerContent={drawerContent}
      screenOptions={{
        headerShown: false,
        drawerType: hasLargeScreen ? 'permanent' : 'front',
        swipeEnabled: false,
        drawerStyle: {
          width: hasLargeScreen ? LARGE_SCREEN_DRAWER_WIDTH : '75%',
          backgroundColor: theme['theme.background'],
          borderTopRightRadius: hasLargeScreen ? 0 : 16,
          borderBottomRightRadius: hasLargeScreen ? 0 : 16,
        },
        overlayColor: hasLargeScreen ? 'transparent' : theme['theme.overlay'],
      }}
    >
      <DrawerStack.Screen
        name="Dashboard"
        component={hasLargeScreen ? DashboardNavigator : TabNavigator}
      />
      {hasLargeScreen &&
        drawerRoutes.map(({ routeName, component }) => (
          <DrawerStack.Screen key={routeName} name={routeName} component={component} />
        ))}
    </DrawerStack.Navigator>
  )
}
