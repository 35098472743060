import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { Divider } from '@ui-kitten/components'
import { View } from 'react-native'
import { Product, SchedulePhase, Subscription, UiPillType } from '@src/types'
import { isSubscriptionActive } from '@src/hooks/useActiveSubscriptions'
import { Feature, useFeatureFlag } from '@components'
import { CheckoutKind } from '@screens/Marketplace/types/types.ts'
import {
  isTrialUpgradable,
  getSubscriptionTagDescriptor,
  isProductUpgradable,
  isFreeSoftwareOnlySubscription,
} from '../utils/utils'
import { ProductSummary } from './ProductSummary'

export interface ServicesProps {
  products: Product[]
  subscriptions: (Pick<
    Subscription,
    | 'id'
    | 'cancelAt'
    | 'price'
    | 'currentPeriodEndAt'
    | 'commitmentEndAt'
    | 'resumesAt'
    | 'resetBillingCycleAnchorAt'
    | 'status'
    | 'paused'
  > & {
    primaryProduct: Pick<Product, 'title' | 'details' | 'interval' | 'id' | 'key'> & {
      eligibleUpgradeProducts: Pick<Product, 'id'>[]
    }
  })[]
  upcomingSubscriptionPhases: SchedulePhase[]
}

export const Services = ({
  products,
  subscriptions,
  upcomingSubscriptionPhases,
}: ServicesProps) => {
  const navigation = useNavigation()
  const upgradeSoftwareOnlyProduct = useFeatureFlag(Feature.UpgradeSoftwareOnlyProduct)

  const onTrialUpgradePress = (
    subscription: Pick<Subscription, 'id' | 'cancelAt'> & { primaryProduct: Pick<Product, 'id'> },
  ) => {
    const product = products.find((product) => product.id === subscription.primaryProduct.id)

    if (!product) {
      return
    }

    navigation.navigate('SubscriptionCheckout', {
      subscriptionId: subscription.id,
      product,
      trialEndsAt: subscription.cancelAt,
      checkoutKind: CheckoutKind.UpgradeTrial,
    })
  }

  const onProductUpgradePress = (
    subscription: Pick<Subscription, 'id'> & {
      primaryProduct: { eligibleUpgradeProducts: Pick<Product, 'id'>[] }
    },
    product: Pick<Product, 'id'>,
  ) => {
    const effectiveProduct = products.find((p) => p.id === product.id)

    if (!effectiveProduct) {
      return
    }

    navigation.navigate('SubscriptionCheckout', {
      subscriptionId: subscription.id,
      product: effectiveProduct,
      checkoutKind: CheckoutKind.UpgradeProduct,
    })
  }

  const handleManageSubscriptionPress = (
    subscription: Pick<Subscription, 'status' | 'id' | 'cancelAt'> & {
      primaryProduct: Pick<Product, 'id' | 'key'> & {
        eligibleUpgradeProducts: Pick<Product, 'id'>[]
      }
    },
    product: Pick<Product, 'id'>,
  ) => {
    if (
      isProductUpgradable(subscription) &&
      (upgradeSoftwareOnlyProduct || !isFreeSoftwareOnlySubscription(subscription.primaryProduct))
    ) {
      onProductUpgradePress(subscription, product)
    } else if (isTrialUpgradable(subscription)) {
      onTrialUpgradePress(subscription)
    } else {
      navigation.navigate('ProgramDetails', { subscriptionId: subscription.id })
    }
  }

  const handleManageUpcomingSubscriptionPress = () => {
    navigation.navigate('ManageUpcomingSubscriptionModal')
  }

  return (
    <>
      {subscriptions.map((subscription) => {
        const eligibleUpgradeProducts = products.filter((product) =>
          subscription.primaryProduct.eligibleUpgradeProducts.some(
            (minProduct) => minProduct.id === product.id,
          ),
        )

        return (
          <View key={subscription.id}>
            <ProductSummary
              product={subscription.primaryProduct}
              eligibleUpgradeProducts={eligibleUpgradeProducts}
              price={subscription.price}
              currentPeriodEndAt={subscription.currentPeriodEndAt}
              commitmentEndAt={subscription.commitmentEndAt}
              cancelAt={subscription.cancelAt}
              resumesAt={subscription.resumesAt}
              resetBillingCycleAnchorAt={subscription.resetBillingCycleAnchorAt}
              isActive={isSubscriptionActive(subscription)}
              manageTitle={isTrialUpgradable(subscription) ? 'Upgrade' : 'Show Program Details'}
              onManagePress={(product) => {
                handleManageSubscriptionPress(subscription, product)
              }}
              {...getSubscriptionTagDescriptor(subscription)}
            />
            <Divider appearance="large" />
          </View>
        )
      })}
      {upcomingSubscriptionPhases.map(
        (phase) =>
          phase.primaryProduct && (
            <View key={phase.id}>
              <ProductSummary
                product={phase.primaryProduct}
                price={phase.price}
                isActive
                isTrial={false}
                pillLabel="SCHEDULED TO START"
                pillStatus={UiPillType.Success}
                manageTitle="Manage Subscription"
                onManagePress={handleManageUpcomingSubscriptionPress}
              />
              <Divider appearance="large" />
            </View>
          ),
      )}
    </>
  )
}
