import moment from 'moment'
import { Model } from '@models'
import { EffectiveDailyMeasurement, HealthDataMeasurementType, Measurement } from '@src/types'
import { transformHistoryOrEventItem } from '@src/transforms/eventAndHistoryItemTransform'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { calendarDateSelector } from '@src/selectors/app'
import { FETCH_EFFECTIVE_DAILY_MEASUREMENT } from '../graphql/fetchEffectiveDailyMeasurement'
import { UPDATE_EFFECTIVE_DAILY_MEASUREMENT } from '../graphql/updateEffectiveDailyMeasurement'
import { DailyMeasurementsStoreState } from './dailyMeasurements.types'

const measurementsCacheClearPayload = (payload: EffectiveDailyMeasurement) => ({
  includeDates: [payload.date],
})

export default class DailyMeasurements {
  namespace = 'dailyMeasurements'

  state = {
    ...Model.defaultState,
    effectiveMeasurements: {},
  }

  effects = {
    fetchEffectiveMeasurement: Model.buildEffect({
      name: `${this.namespace}/fetchEffectiveMeasurement`,
      query: FETCH_EFFECTIVE_DAILY_MEASUREMENT,
      dataPath: 'effectiveDailyMeasurement',
      reducers: [{ name: 'saveEffectiveMeasurement' }],
    }),
    updateEffectiveMeasurement: Model.buildEffect({
      name: `${this.namespace}/fetchEffectiveMeasurement`,
      query: UPDATE_EFFECTIVE_DAILY_MEASUREMENT,
      variables: ({
        type,
        date,
        measurement,
      }: {
        type: HealthDataMeasurementType
        date: string
        measurement: Measurement
      }) => ({ type, date, selectedSource: measurement.externalSource }),
      dataPath: 'updateEffectiveDailyMeasurement',
      reducers: [{ name: 'saveEffectiveMeasurement' }],
      optimistic: true,
      optimisticReducers: [
        {
          name: 'events/appendOrReplaceList',
          *unless({ measurement }: { measurement: Measurement }, { select }: any) {
            const { startDate, endDate } = yield select(calendarDateSelector)
            const startTime = moment(startDate).startOf('day')
            const endTime = moment(endDate).endOf('day')

            return !(
              moment(measurement.occurredAt).isBetween(startTime, endTime, undefined, '[]') ||
              moment(measurement.occurredAt).isBetween(startTime, endTime, undefined, '[]')
            )
          },
          payload: ({ measurement }: { measurement: Measurement }) => ({
            ...measurement,
            isDailyMeasurement: true,
          }),
          transform: transformHistoryOrEventItem,
          storePath: 'events',
        },
      ],
      cacheReducers: [
        {
          name: 'events/cacheClear',
          payload: { matchName: 'events/fetch', ...measurementsCacheClearPayload },
        },
        {
          name: 'insights/cacheClear',
          payload: measurementsCacheClearPayload,
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    saveAvailableMeasurement: (
      state: DailyMeasurementsStoreState,
      { payload }: { payload: Measurement },
    ) => {
      const newMeasurement = transformHistoryOrEventItem(payload)
      const date = moment(newMeasurement.occurredAt).format(DATE_FORMAT)
      const type = newMeasurement.type as HealthDataMeasurementType
      let newAvailableMeasurements = [
        ...(state.effectiveMeasurements[date]?.[type]?.availableMeasurements || []),
      ] as Measurement[]

      const existingMeasurementIdx = newAvailableMeasurements.findIndex(
        (m) => m.externalSource === newMeasurement.externalSource,
      )

      if (existingMeasurementIdx > -1) {
        newAvailableMeasurements = newAvailableMeasurements.map((measurement, index) => {
          if (index === existingMeasurementIdx) {
            return newMeasurement
          }
          return measurement
        })
      } else {
        newAvailableMeasurements.push(newMeasurement)
      }

      return {
        ...state,
        effectiveMeasurements: {
          ...state.effectiveMeasurements,
          [date]: {
            ...state.effectiveMeasurements[date],
            [type]: {
              ...(state.effectiveMeasurements[date]?.[type] || {}),
              availableMeasurements: newAvailableMeasurements,
              selectedSource: newMeasurement.externalSource,
            },
          },
        },
      }
    },
    saveEffectiveMeasurement: (
      state: DailyMeasurementsStoreState,
      { payload }: { payload: EffectiveDailyMeasurement },
    ) => {
      const availableMeasurements = payload.availableMeasurements.map((measurement) =>
        transformHistoryOrEventItem(measurement),
      )
      return {
        ...state,
        effectiveMeasurements: {
          ...state.effectiveMeasurements,
          [payload.date]: {
            ...state.effectiveMeasurements[payload.date],
            [payload.type]: {
              ...payload,
              availableMeasurements,
            },
          },
        },
      }
    },
  }
}
