import React from 'react'
import { View, Image, ImageStyle } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { InlineAlert, ScrollViewWithFade } from '@src/components'
import {
  TERRA_GOOGLE_FIT_NAVIGATION_SOURCES,
  useHealthPermissionScreenProps,
} from '@src/screens/Home/utils/useHealthPermissionScreenProps'
import { UiInlineAlert } from '@src/types'
import { useTutorialNavigation } from '../Tutorials/hooks'
import { getHealthPermissionsModalStateInteractions } from '../Home/utils/hooks'
import { useByosOnboardingTutorial } from './hooks'

export const HealthPermissionScreen = () => {
  const styles = useStyleSheet(themedStyle)

  const { group: tutorialGroup, sensorKind: tutorialSensorKind } = useByosOnboardingTutorial()
  const navigateToTutorial = useTutorialNavigation(tutorialGroup, tutorialSensorKind)
  const { markAsShown: markHealthPermissionsAsShown } = getHealthPermissionsModalStateInteractions()

  const onComplete = () => {
    markHealthPermissionsAsShown()
    navigateToTutorial()
  }

  const {
    sourceType,
    title,
    imageSource,
    allowButtonText,
    onAllowButtonPress,
  } = useHealthPermissionScreenProps({
    onComplete,
    navigationSource: TERRA_GOOGLE_FIT_NAVIGATION_SOURCES.OwnSensorOnboardingPermissions,
  })

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <ScrollViewWithFade style={styles.scrollView} fadeColor={styles.container.backgroundColor}>
        <View style={styles.imageContainer}>
          <Image source={imageSource} style={styles.image as ImageStyle} />
        </View>
        <View style={styles.textContent}>
          <Text type="title-2" style={styles.title}>
            {title}
          </Text>
          <Text type="large" style={styles.description}>
            First, we need to connect your {sourceType} to Nutrisense.
          </Text>
        </View>
      </ScrollViewWithFade>
      <InlineAlert
        category={UiInlineAlert.Info}
        style={styles.inlineAlert}
        message={`As you are connecting with ${sourceType}, make sure to toggle on Blood Glucose`}
      />
      <Button
        type="primary"
        size="block"
        onPress={onAllowButtonPress}
        accessibilityLabel={allowButtonText}
      >
        {allowButtonText}
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    flex: 1,
  },
  imageContainer: {
    maxHeight: 130,
    marginTop: 44,
    justifyContent: 'center',
  },
  image: {
    maxWidth: 250,
    height: undefined,
    maxHeight: 130,
    alignSelf: 'center',
    aspectRatio: 16 / 9,
  },
  textContent: {
    paddingTop: 56,
  },
  title: {
    textAlign: 'center',
  },
  description: {
    paddingTop: 24,
    paddingBottom: 8,
    textAlign: 'center',
  },
  inlineAlert: {
    marginVertical: 24,
  },
})
