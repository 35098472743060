import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Slider, ProgressSlider } from '@components'
import { Text } from '@components/base'
import { NUTRITION_CHART_COLORS } from '@src/screens/Home'
type MacroType = 'calories' | 'protein' | 'carbs' | 'netCarbs' | 'fat'

interface MacroDetails {
  title: string
  units: string
}

const macroDetails: Record<MacroType, MacroDetails> = {
  calories: { title: 'Calories', units: 'cal' },
  carbs: { title: 'Carbs', units: 'g' },
  netCarbs: { title: 'Net Carbs', units: 'g' },
  protein: { title: 'Protein', units: 'g' },
  fat: { title: 'Fat', units: 'g' },
}

interface MacroSliderProps {
  macro: MacroType
  value: number
  maxValue: number
  percent?: number
  step?: number
  style?: StyleProp<ViewStyle>
  variant?: 'compact' | 'expanded' | 'edit'
  onValueChange?: (macro: MacroType, value: number) => void
}

export const MacroSlider = ({
  macro,
  value,
  maxValue,
  percent,
  step = 5,
  style,
  variant = 'compact',
  onValueChange,
}: MacroSliderProps) => {
  const styles = useStyleSheet(themedStyles)

  const { title, units } = macroDetails[macro]

  const isCompact = variant === 'compact'
  const isEditing = variant === 'edit'

  const textType = isCompact ? 'small' : 'regular'
  const roundedPercent = percent && Math.round(percent * 100)
  const valueLabel = `${Math.round(value)}`
  const percentLabel = percent !== undefined ? `${roundedPercent}%` : ''

  const getValueTitle = () => {
    if (isEditing) {
      return ''
    } else {
      return `${valueLabel} ${units}`
    }
  }

  const getPercentTitle = () => {
    if (isEditing) {
      return macro === 'calories' && percent !== undefined ? `${percentLabel} Allocated` : ''
    } else {
      return `(${percentLabel})`
    }
  }

  const getDailyGoalLabel = () => {
    if (isEditing) {
      return `${valueLabel} ${units}`
    } else {
      return `${maxValue} ${units}`
    }
  }

  const getAllocationStyle = () => {
    if (!roundedPercent || !isEditing || macro !== 'calories') {
      return undefined
    } else if (roundedPercent > 100) {
      return styles.allocationOver
    } else if (roundedPercent < 100) {
      return styles.allocationUnder
    } else {
      return styles.allocationEqual
    }
  }

  const macroTrackColor = { backgroundColor: NUTRITION_CHART_COLORS[macro].background }
  const macroProgressColor = { backgroundColor: NUTRITION_CHART_COLORS[macro].progress }

  return (
    <View style={style}>
      <View style={styles.descriptors}>
        <View style={styles.titleContainer}>
          <Text type={textType}>{title}</Text>
          {!isCompact && (
            <View style={styles.dailyGoalContainer}>
              <Text type="regular" style={styles.dailyGoalLabel}>
                {'Daily Goal: '}
              </Text>
              <Text type="regular">{getDailyGoalLabel()}</Text>
            </View>
          )}
        </View>
        <View style={styles.valueTitleContainer}>
          <Text type={textType}>{getValueTitle()}</Text>
          <Text type={textType} style={[styles.percentTitle, getAllocationStyle()]}>
            {` ${getPercentTitle()}`}
          </Text>
        </View>
      </View>
      <View style={isCompact && styles.compactSliderContainer}>
        {!isEditing && (
          <ProgressSlider
            progress={value / maxValue}
            trackColor={macroTrackColor}
            progressColor={[isCompact && styles.compactBar, macroProgressColor]}
          />
        )}
      </View>
      {isEditing && (
        <>
          <Slider
            min={0}
            max={maxValue}
            step={step}
            values={[value ?? maxValue]}
            onValuesChange={(values: number[]) => onValueChange?.(macro, values[0])}
            trackStyle={styles.sliderTrack}
            markerStyle={styles.sliderMarker}
            trackColor={macroTrackColor}
            progressColor={macroProgressColor}
          />
          <View style={styles.descriptors}>
            <Text type="regular" bold style={styles.subtitle}>
              0
            </Text>
            <Text type="regular" bold style={styles.subtitle}>
              {maxValue}
            </Text>
          </View>
        </>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  descriptors: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'column',
  },
  title: {
    textTransform: 'uppercase',
  },
  valueTitleContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  dailyGoalLabel: {
    color: 'theme.text.tertiary',
  },
  dailyGoalContainer: {
    flexDirection: 'row',
  },
  percentTitle: {
    color: 'theme.text.tertiary',
  },
  allocationUnder: {
    color: 'theme.range.regular',
  },
  allocationOver: {
    color: 'theme.range.bad',
  },
  allocationEqual: {
    color: 'theme.range.good',
  },
  subtitle: {
    textTransform: 'uppercase',
    color: 'theme.text.secondary',
  },
  compactBar: {
    height: 6,
    borderRadius: 3,
  },
  compactSliderContainer: {
    height: 6,
  },
  sliderTrack: {
    height: 8,
    borderRadius: 4,
  },
  sliderMarker: {
    marginTop: 8,
    height: 18,
    width: 18,
  },
})
