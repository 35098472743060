import { gql } from '@apollo/client'

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on User {
    id
    email
    fullName
    firstName
    lastName
    dateOfBirth
    phoneNumber
    avatar
    timeZone
    intercomDigest
    role
    surveyLinks {
      id
      finished
      startedAt
      state
      survey {
        header
        kind
        research
      }
    }
    uiStates {
      name
      value
      createdAt
    }
    lastPrescriptionApprovalRequest {
      id
      state
    }
    lastResearchApprovalRequest {
      id
      state
    }
    lastCoreSubscription {
      id
      subscribedAt
    }
    features
    migrationFromInsuranceEnabled
    followupSchedulingAvailable
    signUpSource
  }
`
