import { gql } from '@apollo/client'
import { EHR_APPOINTMENT_FIELDS } from '@src/graphql/fragments/ehrAppointment'

export const BOOK_APPOINTMENT = gql`
  ${EHR_APPOINTMENT_FIELDS}
  mutation ehrBookAppointment(
    $timeZone: String
    $date: String
    $appointmentKind: EhrAppointmentKind
    $attendanceChargeKind: EhrAppointmentAttendanceChargeKind
    $coachId: ID
    $pendingAppointmentId: ID
  ) {
    ehrBookAppointment(
      timeZone: $timeZone
      date: $date
      appointmentKind: $appointmentKind
      attendanceChargeKind: $attendanceChargeKind
      coachId: $coachId
      pendingAppointmentId: $pendingAppointmentId
    ) {
      ...EhrAppointmentFields
    }
  }
`
