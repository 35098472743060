import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NativeModules, Linking, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { InAppBrowser } from 'react-native-inappbrowser-reborn'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Toggle } from '@components/base'
import { InlineAlert, ScrollViewWithFade, Section } from '@components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppRouteProp, AppStackParamList } from '@src/navigation/types'
import { useSnack } from '@src/utils/navigatorContext'
import { terraProviderSelector, terraProvidersSelector } from '@src/selectors/integrations'
import {
  ErrorMessages,
  Logger,
  LoggerScreens,
  Device,
  Analytics,
  CustomEventTypes,
} from '@src/config'
import { ListProviders } from '@src/types'
import { TERRA_GOOGLE_FIT_NAVIGATION_SOURCES } from '@src/screens/Home/utils/useHealthPermissionScreenProps'
import { useByosOnboardingTutorial } from '@src/screens/OwnSensorOnboarding/hooks'
import { useTutorialNavigation } from '@src/screens/Tutorials/hooks'
import { useTerraAppleHealth } from '../../utils/hooks'
import { TERRA_APPLE_HEALTH } from '../../models/settings.types'
import { AllTerraProvidersQuery } from '../../graphql/terraProviders.generated'

const { scheme } = NativeModules.Configuration

enum TERRA_DATA_TYPES {
  ExerciseActivity = 'exercise_activity',
  StepsCountMeasurement = 'steps_count_measurement',
  MeditationActivity = 'meditation_activity',
  SleepActivity = 'sleep_activity',
  RunningDistanceMeasurement = 'running_distance_measurement',
  BodyFatPercentageMeasurement = 'body_fat_percentage_measurement',
  FlightsClimbedMeasurement = 'flights_climbed_measurement',
  ExerciseTimeMeasurement = 'exercise_time_measurement',
  WeightMeasurement = 'weight_measurement',
  CyclingDistanceMeasurement = 'cycling_distance_measurement',
  BloodGlucoseMeasurement = 'glucose_measurement',
  Meals = 'meals',
  KetoneMeasurement = 'ketone_measurement',
}

const TERRA_DATA_TYPES_TITLES: { [key in TERRA_DATA_TYPES]: string } = {
  [TERRA_DATA_TYPES.ExerciseActivity]: 'Exercises',
  [TERRA_DATA_TYPES.StepsCountMeasurement]: 'Steps',
  [TERRA_DATA_TYPES.MeditationActivity]: 'Meditation',
  [TERRA_DATA_TYPES.SleepActivity]: 'Sleep',
  [TERRA_DATA_TYPES.RunningDistanceMeasurement]: 'Running Distance',
  [TERRA_DATA_TYPES.BodyFatPercentageMeasurement]: 'Body fat percentage',
  [TERRA_DATA_TYPES.FlightsClimbedMeasurement]: 'Flights Climbed',
  [TERRA_DATA_TYPES.ExerciseTimeMeasurement]: 'Exercise Time',
  [TERRA_DATA_TYPES.WeightMeasurement]: 'Weight',
  [TERRA_DATA_TYPES.CyclingDistanceMeasurement]: 'Cycling Distance',
  [TERRA_DATA_TYPES.BloodGlucoseMeasurement]: 'Blood Glucose',
  [TERRA_DATA_TYPES.Meals]: 'Meals',
  [TERRA_DATA_TYPES.KetoneMeasurement]: 'Blood Ketones',
}

export const TerraProvider = () => {
  const route = useRoute<AppRouteProp<'TerraProvider'>>()

  const { provider: providerId, cancel: isDeleteConfirmed, source } = route.params

  // source is used to track where user came from to track google fit sync event
  // we need to store the initial route source as the route
  // is being changed when user navigates back from browser ( terra auth flow)
  const sourceRef = useRef(source)

  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const findTerraProvider = useSelector(terraProviderSelector)
  const provider = findTerraProvider(providerId)

  const providers = useSelector(terraProvidersSelector)
  const appleHealthProvider = providers.find((pr) => pr.name === TERRA_APPLE_HEALTH)

  const showSnack = useSnack()
  const insets = useSafeAreaInsets()

  const styles = useStyleSheet(themedStyles)
  const [disabledTypes, setDisabledTypes] = useState(provider.disabledTypes || [])
  const [providerConnected, setProviderConnected] = useState(provider.active)
  const authInProgress = useRef<boolean>()

  const initAppleHealthConnection = useTerraAppleHealth()

  const { group: tutorialGroup, sensorKind: tutorialSensorKind } = useByosOnboardingTutorial()
  const navigateToTutorial = useTutorialNavigation(tutorialGroup, tutorialSensorKind)

  const authRedirectUrl =
    sourceRef.current === TERRA_GOOGLE_FIT_NAVIGATION_SOURCES.OwnSensorOnboardingPermissions
      ? `${scheme}://onboarding/terraProvider/${providerId}`
      : `${scheme}://app/settings/integrations`

  const setAuthInProgress = (authState: boolean) => {
    authInProgress.current = authState
  }

  useEffect(() => {
    if (!authInProgress.current) {
      return
    }
    if (!route.params.resource) {
      return
    }

    if (!route.params.reason) {
      if (
        provider.name === 'Google Fit' &&
        sourceRef.current &&
        [
          TERRA_GOOGLE_FIT_NAVIGATION_SOURCES.OnboardingPermissions,
          TERRA_GOOGLE_FIT_NAVIGATION_SOURCES.OwnSensorOnboardingPermissions,
        ].includes(sourceRef.current as TERRA_GOOGLE_FIT_NAVIGATION_SOURCES)
      ) {
        Analytics.track(CustomEventTypes.OnboardingGoogleFitSyncEnabled, {
          source: sourceRef.current,
        })
      }
      showSnack(`${provider.name} sync turned on`, null, 'success')
    }

    setAuthInProgress(false)

    dispatch({
      type: 'settings/fetchTerraProviders',
      success: (response: AllTerraProvidersQuery['allTerraProviders']) => {
        const active = response.providers.find((pr) => pr.provider === providerId && pr.active)
        if (
          sourceRef.current === TERRA_GOOGLE_FIT_NAVIGATION_SOURCES.OwnSensorOnboardingPermissions
        ) {
          navigateToTutorial()
        }
        if (!active) {
          setProviderConnected(false)
        }
      },
      failure: () => {
        setProviderConnected(false)
      },
    })
  }, [dispatch, route, provider, providerId, showSnack, source, navigateToTutorial])

  // Auth flow for Terra providers consists on three steps
  // 0. user press toggle which trigger initRegularAuthFlow
  // 1. initRegularAuthFlow receive auth url from backend via settings/generateTerraAuthUrl
  // 2. user need to go through provider auth process in InAppBrowser which is handled by this method
  // 3. after user completed auth in browser backend receives webhook with user info and
  //    we refetch providers list from backend
  const initRegularAuthFlow = (providerId: string) => {
    setAuthInProgress(true)
    setProviderConnected(true)
    dispatch({
      type: 'settings/generateTerraAuthUrl',
      payload: { provider: providerId, authRedirectUrl },
      complete: (response: { url: string; userId: string }) => {
        openProviderAuth(response.url)
      },
      failure: () => {
        setAuthInProgress(false)
        showSnack(ErrorMessages.ServerError, null, 'error')
      },
    })
  }

  const initAppleAuthFlow = async () => {
    setAuthInProgress(true)
    setProviderConnected(true)
    const result = await initAppleHealthConnection()

    if (!result) {
      setProviderConnected(false)
      setAuthInProgress(false)
      showSnack(ErrorMessages.ServerError, null, 'error')
      return
    }

    dispatch({
      type: 'settings/fetchTerraProviders',
      success: (response: ListProviders) => {
        const active = response.providers.find((pr) => pr.provider === providerId && pr.active)
        if (!active) {
          setProviderConnected(false)
        } else {
          showSnack(`${provider.name} sync turned on`, null, 'success')
        }
        setAuthInProgress(false)
      },
      failure: () => {
        setAuthInProgress(false)
        showSnack(ErrorMessages.ServerError, null, 'error')
      },
    })
  }

  const openProviderAuth = async (url: string) => {
    try {
      if (await InAppBrowser.isAvailable()) {
        const result = await InAppBrowser.openAuth(url, authRedirectUrl, {
          // do not close browser on Android when app goes to background
          // https://github.com/proyecto26/react-native-inappbrowser/issues/213
          showInRecents: true,
        })
        if (result.type === 'success') {
          if (result.url) {
            Linking.openURL(result.url)
          }
        } else if (authInProgress.current) {
          setAuthInProgress(false)
          setProviderConnected(false)
        }
      }
    } catch (error: any) {
      setAuthInProgress(false)
      setProviderConnected(false)
      const errorMessage = error.message ? error.message : ErrorMessages.TerraAuthorizationFailed
      Logger.sendError(LoggerScreens.TerraProvider, errorMessage, {
        error,
        providerName: provider.name,
      })
      showSnack(errorMessage, null, 'error')
    }
  }

  const toggleDataType = (dataType: TERRA_DATA_TYPES) => {
    if (!disabledTypes.includes(dataType)) {
      setDisabledTypes([...disabledTypes, dataType])
    } else {
      setDisabledTypes(disabledTypes.filter((type) => type !== dataType))
    }
  }

  const onDataTypeToggle = (dataType: TERRA_DATA_TYPES) => {
    toggleDataType(dataType)
    dispatch({
      type: 'settings/toggleProviderDataType',
      payload: { provider: providerId, dataType },
      failure: () => {
        toggleDataType(dataType)
        showSnack(ErrorMessages.ServerError, null, 'error')
      },
    })
  }

  const isDataTypeEnabled = (dataType: TERRA_DATA_TYPES) =>
    provider.active && !disabledTypes.includes(dataType)

  const renderDataTypes = () => (
    <View style={Device.ios && styles.dataTypes}>
      {(provider.availableTypes as TERRA_DATA_TYPES[]).map((dataType) => (
        <Section title={TERRA_DATA_TYPES_TITLES[dataType]} key={dataType}>
          <Toggle
            disabled={!provider.active}
            checked={isDataTypeEnabled(dataType)}
            onChange={() => onDataTypeToggle(dataType)}
          />
        </Section>
      ))}
    </View>
  )

  const confirmSyncDelete = () => {
    navigation.navigate('CancelModal', {
      title: `Are you sure you want to delete synchronization with ${provider.name}?`,
      cancelText: 'No, Keep it synced',
      confirmText: 'Yes, delete synchronization',
      parentScreen: 'TerraProvider',
    })
  }

  useEffect(() => {
    if (isDeleteConfirmed) {
      setProviderConnected(false)
      dispatch({
        type: 'settings/deauthTerraProvider',
        payload: { provider: providerId },
        complete: () => {
          showSnack(`${provider.name} sync turned off`)
        },
        failure: () => {
          showSnack(ErrorMessages.ServerError, null, 'error')
        },
      })
    }
  }, [providerId, isDeleteConfirmed, dispatch, showSnack, provider.name])

  return (
    <NavigationContainer title={provider.name} showLoadingIndicator>
      <View style={[styles.container, { paddingBottom: insets.bottom }]}>
        <Section title="Integration" style={styles.section} />
        <View>
          <Section title={`${provider.name} Sync`}>
            <Toggle
              checked={providerConnected || provider.active}
              onChange={() => {
                if (providerConnected || provider.active) {
                  confirmSyncDelete()
                } else {
                  if (provider.name === TERRA_APPLE_HEALTH) {
                    initAppleAuthFlow()
                  } else {
                    initRegularAuthFlow(provider.provider)
                  }
                }
              }}
            />
          </Section>
        </View>

        <Section title="Data" style={styles.section} />
        <ScrollViewWithFade>{renderDataTypes()}</ScrollViewWithFade>
        {Device.ios && !appleHealthProvider && (
          <InlineAlert
            message={
              'If Apple Health is enabled, there may be conflicting measurements with this data source. ' +
              'We suggest disabling whatever data source you believe will be inaccurate.'
            }
            style={styles.alert}
          />
        )}
      </View>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  section: {
    height: 40,
    backgroundColor: 'theme.surface.base2',
  },
  alert: {
    margin: 16,
  },
  dataTypes: {
    paddingBottom: 16,
  },
})
