import React from 'react'
import { ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { authenticatedUserSelector } from '@src/selectors/app'
import { PaymentMethodCard } from '@screens/Marketplace/components/checkout/PaymentMethodCard.tsx'
import { RootStackParamList } from '@navigation/types'

export const PaymentFailed = () => {
  const styles = useStyleSheet(themedStyle)
  const route = useRoute<RouteProp<RootStackParamList, 'PaymentFailed'>>()
  const { videoCallId, feeProduct, appointmentType, appointmentKind } = route.params

  const insets = useSafeAreaInsets()

  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const user = useSelector(authenticatedUserSelector)
  const { paymentMethod } = user

  const onEditPaymentMethodPress = () => {
    navigation.navigate('CreatePaymentMethod')
  }

  const onRetryCharge = () => {
    navigation.navigate('FeePaymentScreen', {
      videoCallId,
      feeProduct,
      appointmentType,
      appointmentKind,
    })
  }

  const onExit = () => {
    navigation.dispatch((state) => {
      // Remove current route
      const routes = state.routes.slice(0, -1)

      // navigate to NutritionistHub with FeePaymentIncompleteModal
      const newRoutes = [
        ...routes,
        { name: 'Drawer', params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } } },
        { name: 'FeePaymentIncompleteModal' },
      ]

      return CommonActions.reset({
        index: newRoutes.length - 1,
        routes: newRoutes,
      } as any)
    })
  }

  return (
    <NavigationContainer
      title="Payment failed"
      goBack={onExit}
      leftIcon={<Icon name="x" size="20" weight="bold" style={styles.closeIcon} />}
    >
      <ScrollView
        style={styles.container}
        contentContainerStyle={[styles.contentContainer, { paddingBottom: insets.bottom + 16 }]}
        bounces={false}
      >
        <View style={styles.cardContainer}>
          <Icon name="credit-card" style={styles.icon} />
          <Text style={styles.content} type="title-2">
            Update your payment method
          </Text>
          <Text style={styles.content} type="regular">
            Unfortunately we were unable to process your payment. Please update your payment method.
          </Text>
          <PaymentMethodCard
            paymentMethod={paymentMethod}
            onEditPaymentMethodPress={onEditPaymentMethodPress}
          />
        </View>
        <Button
          accessibilityLabel="Retry Charge"
          size="block"
          type="primary"
          onPress={onRetryCharge}
        >
          Retry Charge
        </Button>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
    padding: 16,
    justifyContent: 'space-between',
  },
  content: {
    textAlign: 'center',
  },
  cardContainer: {
    gap: 16,
  },
  closeIcon: {
    marginLeft: 16,
  },
  icon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    marginTop: 8,
    marginBottom: 8,
  },
})
