import React from 'react-native'
import { ByosDelaySyncInfo } from './ByosDelaySyncInfo'
import { DexcomDataSyncPopup } from './DexcomDataSyncPopup'
import { DexcomSwitchPopup } from './DexcomSwitchPopup'
import { ExpiringSensorPopup } from './ExpiringSensorPopup'
import { MissingDexcomIntegrationPopup } from './MissingDexcomIntegrationPopup'
import { SensorStatusPopup } from './SensorStatusPopup'
import { LinkupDelayPopup } from './LinkupDelayPopup'
import { PrescriptionDexcomSwitchPopup } from './PrescriptionDexcomSwitchPopup'
import { LossWeighRecurrentCallsPopup } from './LossWeightReccurentCallsPopup'

export const InformationPopups = () => {
  return (
    <>
      <LinkupDelayPopup />
      <MissingDexcomIntegrationPopup />
      <DexcomDataSyncPopup />
      <DexcomSwitchPopup />
      <ByosDelaySyncInfo />
      <SensorStatusPopup />
      <ExpiringSensorPopup />
      <PrescriptionDexcomSwitchPopup />
      <LossWeighRecurrentCallsPopup />
    </>
  )
}
