import React from 'react'
import { useSelector } from 'react-redux'
import { SensorStatus, SensorTypes } from 'react-native-freestyle-libre'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity } from 'react-native'
import { StyleService } from '@src/style/service'
import { InlineAlert } from '@src/components/InlineAlert'
import { sensorSelector } from '@src/selectors/sensor'
import { useConnectingToSensorViaBluetooth } from '@src/utils/hooks'
import { useSecondsAfterSensorActivation } from '@src/screens/Scans/hooks/useSecondsAfterSensorActivation'
import { sensorIsWarmingUp } from '@src/screens/Scans/utils/utils'
import { UiInlineAlert } from '@src/types'
import { Feature, useFeatureFlag } from '@src/components'
import { Intercom, contactSupport } from '@src/utils'
import { Debug } from '@src/config'
import { useBluetoothStatus } from '../../screens/Home/utils/useBluetoothStatus'

export const SensorStatusPopup = () => {
  const { isBluetoothEnabled, isBluetoothPermitted } = useBluetoothStatus()
  const sensor = useSelector(sensorSelector)
  const currentSensorIsLibre3 = sensor?.model === SensorTypes.Libre3
  const connectingToBluetooth = useConnectingToSensorViaBluetooth()
  const navigation = useNavigation()
  const libre3RealTime = useFeatureFlag(Feature.Libre3RealTime)

  const secondsAfterSensorActivation = useSecondsAfterSensorActivation({
    updateCondition: sensorIsWarmingUp,
  })

  if (sensor?.lastStatusChange?.failure) {
    const onPress = () => {
      if (!Debug.shouldEnableIntercom()) {
        contactSupport()
        return
      }
      Intercom.showIntercomMessenger({ source: 'Events' })
    }

    return (
      <TouchableOpacity
        accessibilityLabel="Sensor failed - contact support"
        activeOpacity={0.7}
        onPress={onPress}
      >
        <InlineAlert
          style={styles.popup}
          category={UiInlineAlert.Danger}
          message="Sensor failed. Do not remove — tap to contact customer support now."
        />
      </TouchableOpacity>
    )
  }

  if (
    secondsAfterSensorActivation &&
    sensorIsWarmingUp({
      secondsAfterSensorActivation,
      sensorModel: sensor?.model as SensorTypes | undefined,
      liveDataEnabled: libre3RealTime,
    }) &&
    sensor?.status !== SensorStatus.Ready
  ) {
    const onPress = () => {
      navigation.navigate('SensorWarmUpModal')
    }

    return (
      <TouchableOpacity accessibilityLabel="Sensor settings" activeOpacity={0.7} onPress={onPress}>
        <InlineAlert
          style={styles.popup}
          category={UiInlineAlert.Info}
          message="Your sensor is warming up."
        />
      </TouchableOpacity>
    )
  }

  if (!isBluetoothPermitted || !currentSensorIsLibre3) {
    return null
  }

  if (isBluetoothEnabled === false) {
    return (
      <InlineAlert
        style={styles.popup}
        category={UiInlineAlert.Warning}
        message="Turn on Bluetooth to see your latest data."
      />
    )
  }
  if (connectingToBluetooth) {
    return (
      <InlineAlert
        style={styles.popup}
        category={UiInlineAlert.Info}
        message="Reconnecting and syncing sensor data..."
      />
    )
  }
  return null
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
