import { isUndefined } from 'lodash'
import { PaginationType } from '@src/screens/Events/models/events.types'
import { Sort } from '@src/models/app.types'

export interface Variables {
  pagination: PaginationType
  filter: Omit<Sort, 'types'> & {
    types: { key: string; value: string[] }[]
  }
}

export interface FilterPayload {
  startDate: string
  endDate: string
  period?: string
  types: {
    key: string
    value: string[]
  }[]
}

export interface Payload {
  filter: FilterPayload
}

const listFilterCacheKey = (name: string, variables: Variables) => {
  const {
    pagination: { type, page, pageSize, endTime } = {},
    filter: { startDate, endDate, orderBy, order, query, startHour, endHour, types = [] } = {},
  } = variables

  const filterTypes = types.flatMap(({ key, value }) => [key, ...value])

  const dateRange = [startDate, endDate]
  const ordering = [orderBy, order]
  const filters = [startHour, endHour, query, ...filterTypes]
  const pagination = type === 'date' ? [endTime, pageSize] : [endTime, page, pageSize]
  const parts = [name, ...dateRange, ...pagination, ...ordering, ...filters]

  return parts.filter((part) => !isUndefined(part) && part !== '').join(':')
}

const dateRangeCacheKey = (name: string, payload: Payload) => {
  const { filter: { startDate, endDate } = {} } = payload
  return `${name}:${startDate}:${endDate}`
}

const dateRangeTypesCacheKey = (name: string, payload: Payload) => {
  const {
    filter: { types = [], period = '' },
  } = payload

  const keys = types.map((type) => type.key)
  const values = types.flatMap((type) => type.value)

  const sortedKeysValues = keys.concat(values).sort()

  const keyValuesPart = sortedKeysValues.join(':')

  const dateRangeKeyPart = dateRangeCacheKey(name, payload)

  return `${period}${dateRangeKeyPart}:${keyValuesPart}`
}

export default { listFilterCacheKey, dateRangeCacheKey, dateRangeTypesCacheKey }
