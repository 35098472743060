import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ProgressSlider } from '@src/components'
import { Text } from '@components/base'
import { useMicroData } from './utils/useMicroData'

const GENERAL_SECTION = {
  title: 'General',
  nutrients: ['energyKj', 'water'],
}

const CARBOHYDRATES_SECTION = {
  title: 'Carbohydrates',
  nutrients: ['fiber', 'sugars', 'glucose', 'fructose'],
}

const LIPIDS_SECTION = {
  title: 'Lipids',
  nutrients: [
    'saturatedFat',
    'monounsaturatedFat',
    'polyunsaturatedFat',
    'transFat',
    'cholesterol',
  ],
}

const VITAMINS_SECTION = {
  title: 'Vitamins',
  nutrients: ['vitaminC', 'vitaminAIu', 'retinol'],
}

const MINERALS_SECTION = {
  title: 'Minerals',
  nutrients: [
    'iron',
    'zinc',
    'copper',
    'sodium',
    'calcium',
    'fluoride',
    'selenium',
    'magnesium',
    'manganese',
    'potassium',
    'phosphorus',
  ],
}

interface Section {
  title: string
  nutrients: string[]
}

const SECTIONS: Section[] = [
  GENERAL_SECTION,
  CARBOHYDRATES_SECTION,
  LIPIDS_SECTION,
  VITAMINS_SECTION,
  MINERALS_SECTION,
]

export const MicroSummary = () => {
  const styles = useStyleSheet(themedStyles)

  const micronutrientData = useMicroData()

  return SECTIONS.map((section) => (
    <View key={section.title}>
      <Text type="small" bold style={styles.sectionTitle}>
        {section.title}
      </Text>
      {section.nutrients.map((nutrientName) => {
        const { value, units, maxValue, percent, title } = micronutrientData[nutrientName]

        return (
          <View key={nutrientName}>
            <View style={styles.nutritionItemContainer}>
              <Text type="small">{title}</Text>
              <Text type="small">
                {value}/{maxValue} ({units})
              </Text>
            </View>
            <View style={styles.progressBarContainer}>
              <ProgressSlider progress={percent} />
            </View>
          </View>
        )
      })}
    </View>
  ))
}

const themedStyles = StyleService.create({
  sectionTitle: {
    textTransform: 'uppercase',
    marginBottom: 16,
  },
  progressBarContainer: {
    paddingTop: 8,
    marginBottom: 24,
  },
  nutritionItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
