import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  appointmentRecurrenceSelector,
  insurancePolicySelector,
  pastAppointmentsSelector,
  pastEhrAppointmentsSelector,
  upcomingAppointmentsSelector,
  upcomingEhrAppointmentsSelector,
} from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { Button } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { isCallConfirmed } from '@screens/NutritionistHub/utils.ts'
import {
  Appointment,
  EhrAppointment,
  EhrAppointmentKind,
  EhrAppointmentStateKind,
  InsuranceBerryStreetAppointmentType,
} from '@src/types'
import { userSelector } from '@src/selectors/app'
import { DEFAULT_VIDEO_CALL_INFO } from '../../constants'

export const AllVideoCalls = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()

  const user = useSelector(userSelector)

  const upcomingAppointments = useSelector(upcomingAppointmentsSelector)
  const upcomingEhrAppointments = useSelector(upcomingEhrAppointmentsSelector)

  const pastAppointments = useSelector(pastAppointmentsSelector)
  const pastEhrAppointments = useSelector(pastEhrAppointmentsSelector)

  const appointmentRecurrence = useSelector(appointmentRecurrenceSelector)
  const insurancePolicy = useSelector(insurancePolicySelector)

  const [upcomingSelected, setUpcomingSelected] = useState(true)

  useEffect(() => {
    if (user?.migrationFromInsuranceEnabled) {
      dispatch({ type: 'nutritionistHub/fetchPastEhrAppointments' })
    } else {
      dispatch({ type: 'nutritionistHub/fetchPastAppointments' })
    }

    dispatch({ type: 'nutritionistHub/fetchAppointmentRecurrence' })
  }, [dispatch, user?.migrationFromInsuranceEnabled])

  useFocusEffect(
    useCallback(() => {
      if (user?.migrationFromInsuranceEnabled) {
        dispatch({ type: 'nutritionistHub/fetchUpcomingEhrAppointments' })
      } else {
        dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
      }
    }, [dispatch, user?.migrationFromInsuranceEnabled]),
  )

  const onUpsertRecurrence = () => {
    if (!insurancePolicy) {
      return
    }

    navigation.navigate('UpsertRecurrence', { appointmentRecurrence, insurancePolicy })
  }

  const upsertButtonText = appointmentRecurrence ? ' Edit Recurrence' : 'Schedule a Recurring Call'

  const calls = useMemo(() => {
    const onCallCardPress = (appointment: Appointment) => {
      const {
        id,
        meetingAt,
        status,
        title,
        nutritionistName,
        addToGoogleCalendarLink,
        unauthenticatedIcsLink,
        appointmentType,
        chargeType,
      } = appointment

      if (isCallConfirmed(status)) {
        navigation.navigate('VideoCallConfirmedModal', {
          id,
          title: title || DEFAULT_VIDEO_CALL_INFO.title,
          nutritionistName,
          date: meetingAt,
          addToGoogleCalendarLink,
          unauthenticatedIcsLink,
          appointmentType: appointmentType || InsuranceBerryStreetAppointmentType.Initial_60,
          chargeType,
        })
        return
      }

      navigation.navigate('VideoCallInReviewModal', {
        title: title || DEFAULT_VIDEO_CALL_INFO.title,
        nutritionistName,
        date: meetingAt,
      })
    }

    const onAppointmentCardPress = (appointment: EhrAppointment) => {
      const {
        id,
        meetingAt,
        lastState,
        title,
        providerName,
        addToGcalLink,
        unauthenticatedIcsLink,
        kind,
        attendances,
      } = appointment

      if (lastState.kind === EhrAppointmentStateKind.Scheduled) {
        navigation.navigate('VideoCallConfirmedModal', {
          id,
          title: title || DEFAULT_VIDEO_CALL_INFO.title,
          nutritionistName: providerName,
          date: meetingAt,
          addToGoogleCalendarLink: addToGcalLink,
          unauthenticatedIcsLink,
          appointmentKind: kind || EhrAppointmentKind.Initial_60,
          chargeKind: attendances[0]?.chargeKind,
        })
        return
      }

      navigation.navigate('VideoCallInReviewModal', {
        title: title || DEFAULT_VIDEO_CALL_INFO.title,
        nutritionistName: providerName,
        date: meetingAt,
      })
    }

    if (user?.migrationFromInsuranceEnabled) {
      return (upcomingSelected ? upcomingEhrAppointments : pastEhrAppointments).map(
        (appointment) => {
          const { id, meetingAt, title, lastState, providerName, kind, recurrent } = appointment
          const inFuture = meetingAt && moment(meetingAt).isAfter(moment())
          return (
            <CallCard
              key={id}
              title={title}
              meetingAt={meetingAt}
              lastStateKind={lastState.kind}
              nutritionistName={providerName}
              appointmentKind={kind}
              recurrent={recurrent}
              onPress={inFuture ? () => onAppointmentCardPress(appointment) : undefined}
            />
          )
        },
      )
    }

    return (upcomingSelected ? upcomingAppointments : pastAppointments).map((appointment) => {
      const {
        id,
        meetingAt,
        title,
        meetingStatus,
        nutritionistName,
        appointmentType,
        recurrent,
      } = appointment
      const inFuture = meetingAt && moment(meetingAt).isAfter(moment())
      return (
        <CallCard
          key={id}
          title={title}
          meetingAt={meetingAt}
          meetingStatus={meetingStatus}
          nutritionistName={nutritionistName}
          appointmentType={appointmentType}
          recurrent={recurrent}
          onPress={inFuture ? () => onCallCardPress(appointment) : undefined}
        />
      )
    })
  }, [
    navigation,
    pastAppointments,
    upcomingAppointments,
    upcomingSelected,
    user?.migrationFromInsuranceEnabled,
    upcomingEhrAppointments,
    pastEhrAppointments,
  ])

  return (
    <NavigationContainer
      title="Video calls"
      style={{ flexGrow: 1, paddingBottom: insets?.bottom || 0 }}
    >
      <View style={styles.buttons}>
        <Button
          accessibilityLabel="upcomingCalls"
          type={upcomingSelected ? 'primary' : 'outline'}
          size="small"
          onPress={() => setUpcomingSelected(true)}
        >
          Upcoming
        </Button>
        <Button
          accessibilityLabel="pastCalls"
          type={upcomingSelected ? 'outline' : 'primary'}
          size="small"
          onPress={() => setUpcomingSelected(false)}
        >
          Past
        </Button>
      </View>
      <ScrollView style={styles.container}>
        <View style={styles.callsContainer}>{calls}</View>
      </ScrollView>
      {upcomingSelected && insurancePolicy?.eligible && (
        <Button
          type="primary"
          size="block"
          style={styles.recurrenceButton}
          onPress={onUpsertRecurrence}
          accessibilityLabel={upsertButtonText}
        >
          {upsertButtonText}
        </Button>
      )}
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 16,
  },
  callsContainer: {
    paddingHorizontal: 16,
    rowGap: 24,
  },
  recurrenceButton: {
    margin: 16,
  },
})
