import { gql } from '@apollo/client'

export const FETCH_EHR_ALL_AVAILABILITIES = gql`
  query ehrAllAvailabilities {
    ehrAllAvailabilities {
      dayOfWeek
      appointmentKind
      rangeStartAt
      rangeEndAt
    }
  }
`
