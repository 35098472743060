import React, { ReactElement } from 'react'
import { MeasurementListItem } from '@src/components/list/items/MeasurementListItem'
import { MealListItem } from '@src/components/list/items/MealListItem'
import { ActivityListItem } from '@src/components/list/items/ActivityListItem'
import { LocalMeal as Meal, Measurement } from '@src/screens/Events/models/events.types'
import { Activity, JournalEntry } from '@src/types'
import { ITEM_HEIGHT } from './common/ListItemWithScore'
import { JournalEntryListItem } from './items/JournalEntryListItem'

export interface ListItemProps<T> {
  item: T
  highlighted: boolean
  canJumpToDate: boolean
  onItemPress?: (item: T) => void
}

interface ListItemOptions {
  highlighted?: boolean
  canJumpToDate?: boolean
}

export type ListItem =
  | typeof MeasurementListItem
  | typeof MealListItem
  | typeof ActivityListItem
  | typeof JournalEntryListItem

export type ItemType = Measurement | Meal | Activity | JournalEntry

export const getListItem = (
  item: ItemType,
  { highlighted = false, canJumpToDate = true }: ListItemOptions = {},
): ReactElement<ListItem> | null => {
  switch (item.__typename) {
    case 'Measurement':
      return (
        <MeasurementListItem item={item} highlighted={highlighted} canJumpToDate={canJumpToDate} />
      )
    case 'Meal':
      return <MealListItem item={item} highlighted={highlighted} canJumpToDate={canJumpToDate} />
    case 'Activity':
      return (
        <ActivityListItem item={item} highlighted={highlighted} canJumpToDate={canJumpToDate} />
      )
    case 'JournalEntry':
      return (
        <JournalEntryListItem item={item} highlighted={highlighted} canJumpToDate={canJumpToDate} />
      )
    default:
      console.error('Unknown list item type', item.__typename)
      return null
  }
}

export const getListItemHeight = <T extends ItemType>(item: T): number => {
  switch (item.__typename) {
    case 'Measurement':
    case 'Meal':
    case 'Activity':
    case 'JournalEntry':
      return ITEM_HEIGHT
    default:
      console.error('Unknown list item type', item.__typename)
      return 0
  }
}
