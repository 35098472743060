import { uniqueId } from 'lodash'
import moment from 'moment'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { EventsItemType } from '@src/screens/Events/models/events.types'

const buildSection = (date: string, index: number, initialItem: EventsItemType) => ({
  id: uniqueId(date),
  index,
  title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
  occurredAt: moment(date),
  data: [initialItem],
})

export const buildSections = (items: EventsItemType[]) => {
  if (items.length === 0) {
    return []
  }

  let item = items[0]
  let itemDate = moment(item.occurredAt).format(DATE_FORMAT)

  let sectionIndex = 0

  const sections = [buildSection(itemDate, sectionIndex, item)]

  for (let index = 1; index < items.length; index += 1) {
    item = items[index]
    const newItemDate = moment(item.occurredAt).format(DATE_FORMAT)
    if (newItemDate !== itemDate) {
      // push new section
      itemDate = newItemDate
      sectionIndex += 1
      sections.push(buildSection(itemDate, sectionIndex, item))
    } else {
      // add item to existing section
      sections[sectionIndex].data.push(item)
    }
  }

  return sections
}
