import { pick, omit } from 'lodash'
import { Model } from '@models'
import { initCalendar, processDateRangePayload, validateDateRange } from '@models/helper'
import { unlessEventHasSelectedDate } from '@models/utils'
import { Caching } from '@utils'
import reducers from '../reducers'
import { transform } from '../transforms'
import ALL_MEALS from '../graphql/allMeals'
import ALL_FAVORITE_MEALS from '../graphql/allFavoriteMeals'
import CREATE_MEAL from '../graphql/createMeal'
import FETCH_MEAL from '../graphql/fetchMeal'
import DELETE_MEAL from '../graphql/deleteMeal'
import MEAL_IMAGE_DESCRIPTION from '../graphql/mealImageDescription'

export const MEALS_DEFAULT_SORT_STATE = {
  orderBy: 'occurred_at',
  order: 'descending',
  types: [],
  query: '',
  startHour: 0,
  endHour: 24,
  startDate: '',
  endDate: '',
}

const mealsCacheClearPayload = (response) => ({ includeDates: [response.occurredAt] })

export default class Meals {
  namespace = 'meals'

  state = {
    ...Model.defaultState,

    calendar: initCalendar(),
    sort: MEALS_DEFAULT_SORT_STATE,
    meals: [],
    recentMeals: [],
    favorites: [],
    mealDraft: {},
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ALL_MEALS,
      dataPath: 'allMeals',
      variables: ({ filter, pagination }) => ({
        filter: {
          ...pick(filter, ['order', 'orderBy', 'query', 'startDate', 'endDate']),
          types: [{ key: 'meals', value: filter.types || [] }],
        },
        pagination,
      }),
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('meals/fetch', variables),
      reducers: [
        {
          name: 'fetchListInfinite',
          dataPath: 'meals',
          storePath: 'meals',
          transform,
        },
      ],
    }),

    fetchMeal: Model.buildEffect({
      name: `${this.namespace}/fetchMeal`,
      query: FETCH_MEAL,
      dataPath: 'meal',
      reducers: [{ name: 'appendOrReplaceList', transform }],
    }),

    fetchFavorites: Model.buildEffect({
      name: `${this.namespace}/fetchFavorites`,
      caching: true,
      query: ALL_FAVORITE_MEALS,
      dataPath: 'allMeals',
      reducers: [{ name: 'fetchList', transform, storePath: () => 'favorites' }],
    }),

    getMealImageDescription: Model.buildEffect({
      name: `${this.namespace}/getMealImageDescription`,
      query: MEAL_IMAGE_DESCRIPTION,
      dataPath: 'mealImageDescription',
    }),

    fetchRecentMeals: Model.buildEffect({
      name: `${this.namespace}/fetchRecentMeals`,
      caching: true,
      query: ALL_FAVORITE_MEALS,
      dataPath: 'allMeals',
      reducers: [{ name: 'fetchList', transform, storePath: () => 'recentMeals' }],
    }),

    updateFavorite: Model.buildEffect({
      name: `${this.namespace}/updateFavorite`,
      optimistic: true,
      query: CREATE_MEAL,
      dataPath: 'createMeal',
      variables: (payload) => omit(payload, 'ingredients'),
      optimisticReducers: [
        {
          name: 'updateMealFavoriteStatus',
        },
      ],
      reducers: [
        {
          name: 'updateMealFavoriteStatus',
        },
        { name: 'updateListItem', transform, storePath: 'meals' },
        { name: 'events/updateListItem', transform, storePath: 'events' },
      ],
      errorReducers: [
        {
          name: 'updateMealFavoriteStatus',
          payload: (_, { effectPayload }) => ({
            ...effectPayload,
            favorite: !effectPayload.favorite,
          }),
        },
      ],
      cacheReducers: [
        { name: 'cacheClear', payload: { matchName: 'meals/fetch' } },
        {
          name: 'events/cacheClear',
          payload: { ...mealsCacheClearPayload, matchName: 'events/fetch' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'meals/fetchFavorites' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'meals/fetchRecentMeals' },
        },
      ],
    }),

    submit: Model.buildEffect({
      name: `${this.namespace}/submit`,
      query: CREATE_MEAL,
      optimistic: true,
      dataPath: 'createMeal',
      reducers: [
        { name: 'appendOrReplaceList', transform, replaceFake: true },
        {
          name: 'events/appendOrReplaceList',
          transform,
          replaceFake: true,
          unless: unlessEventHasSelectedDate,
          storePath: 'events',
        },
        { name: 'dailyNutrition/fetch', useCache: false },
        { name: 'updateMealFavoriteStatus' },
        {
          name: 'updateRecentMeals',
          payload: (response) => ({ ...response, recentItem: true }),
        },
      ],
      optimisticReducers: [
        { name: 'saveDraft', payload: {} },
        { name: 'events/backup', storePath: 'events' },
        { name: 'appendOrReplaceList', transform },
        {
          name: 'events/appendOrReplaceList',
          transform,
          unless: unlessEventHasSelectedDate,
          storePath: 'events',
        },
        { name: 'notifications/fetch' },
      ],
      errorReducers: [
        {
          name: 'saveDraft',
          payload: (_, { effectPayload }) => ({
            ...transform(effectPayload),
            hasUnsavedChanges: true,
          }),
        },
        { name: 'events/restore', storePath: 'events' },
      ],
      cacheReducers: [
        { name: 'cacheClear', payload: { matchName: 'meals/fetch' } },
        {
          name: 'events/cacheClear',
          payload: { ...mealsCacheClearPayload, matchName: 'events/fetch' },
        },
        {
          name: 'dailyNutrition/cacheClear',
          payload: { matchName: 'dailyNutrition/fetch' },
        },
        {
          name: 'insights/cacheClear',
          payload: mealsCacheClearPayload,
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'meals/fetchFavorites' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'meals/fetchRecentMeals' },
        },
      ],
    }),

    delete: Model.buildEffect({
      name: `${this.namespace}/delete`,
      optimistic: true,
      query: DELETE_MEAL,
      dataPath: 'deleteMeal',
      variables: (payload) => {
        return { id: payload.id }
      },
      optimisticReducers: [
        { name: 'events/backup', storePath: 'events' },
        { name: 'events/deleteList', transform, storePath: 'events' },
      ],
      reducers: [
        {
          name: 'updateMealFavoriteStatus',
          payload: (response) => ({ ...response, favorite: false }),
        },
        {
          name: 'updateRecentMeals',
          payload: (response) => ({ ...response, recentItem: false }),
        },
        { name: 'dailyNutrition/fetch', useCache: false },
      ],
      errorReducers: [{ name: 'events/restore', storePath: 'events' }],
      cacheReducers: [
        {
          name: 'events/cacheClear',
          payload: { ...mealsCacheClearPayload, matchName: 'events/fetch' },
        },
        {
          name: 'dailyNutrition/cacheClear',
          payload: { matchName: 'dailyNutrition/fetch' },
        },
        {
          name: 'insights/cacheClear',
          payload: mealsCacheClearPayload,
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'meals/fetchFavorites' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'meals/fetchRecentMeals' },
        },
      ],
    }),

    changeDateRange: Model.buildEffect({
      name: `${this.namespace}/changeDateRange`,
      validation: validateDateRange,
      reducers: [{ name: 'updateCalendarState', payload: processDateRangePayload }],
    }),
  }

  reducers = reducers
}
