import {
  get,
  keys,
  isEmpty,
  uniqBy,
  omit,
  xor,
  orderBy,
  camelCase,
  minBy,
  values,
  Dictionary,
} from 'lodash'
import moment from 'moment'
import { set } from 'lodash/fp'
import { AnyAction } from 'redux'

const CACHE_MAX_SIZE = 100

export interface Action extends AnyAction {
  storePath: string
  dataPath?: string
  payload?: any
  cacheKey?: string
  filter?: any
  metadata?: any
  pagination?: any
  replaceFake?: any
  transform?: any
  select?: any
}

interface CacheClearAction {
  payload: {
    matchName?: string
    includeDates?: string[]
  }
}

const commonReducers = {
  // used in users, activities, events, ingredients, meals and insights
  fetchList: (state: Dictionary<any>, action: Action) => {
    const { storePath, dataPath = '', payload, transform, pagination } = action

    const payloadCollection = get(payload, dataPath ?? '', [])
    const storeCollection = get(state, storePath, [])

    const items = payloadCollection.map((item: any) =>
      typeof transform === 'function' ? transform(item) : item,
    )
    const list =
      pagination && pagination.page > 1 ? uniqBy([...storeCollection, ...items], 'id') : items

    return set(storePath, list, state)
  },

  // used in events, meals and scans
  fetchListInfinite: (state: Dictionary<any>, action: Action) => {
    const { payload, dataPath, storePath, transform, metadata } = action
    const payloadCollection = get(payload, dataPath ?? '', [])
    const storeCollection = get(state, storePath, [])

    let list =
      !metadata || metadata['isRefresh']
        ? uniqBy(payloadCollection, 'id')
        : uniqBy([...storeCollection, ...payloadCollection], 'id')

    list = list.map((item) => (typeof transform === 'function' ? transform(item) : item))

    return set(storePath, list, state)
  },

  // used in events and meals
  updateListItem: (state: Dictionary<any>, action: Action) => {
    const { payload, storePath, transform } = action

    const storeCollection: Array<any> = get(state, storePath)

    const item = typeof transform === 'function' ? transform(payload) : payload
    const newList = storeCollection.map((element) => {
      if (element.id === item.id && element.__typename === item.__typename) {
        return { ...element, ...item }
      }
      return element
    })

    return set(storePath, newList, state)
  },

  // used in events, ingredients and meals
  appendOrReplaceList: (state: Dictionary<any>, action: Action) => {
    const {
      payload,
      storePath,
      transform,
      replaceFake: replaceFakeParam,
      reSort: reSortParam = true,
    } = action

    const storeCollection: Array<any> = get(state, storePath, [])

    const item = typeof transform === 'function' ? transform(payload) : payload
    let existingIndex = storeCollection.findIndex(
      (el: any) => el.id === item.id && el.__typename === item.__typename,
    )
    if (replaceFakeParam && existingIndex < 0) {
      existingIndex = storeCollection.findIndex(
        (el: any) => el.id === undefined && el.fake === true,
      )
    }
    // New manual daily measurements will replace an existing measurement with the same type and date
    if (item.isDailyMeasurement) {
      existingIndex = storeCollection.findIndex(
        (el: any) => el.type === item.type && moment(item.occurredAt).isSame(el.occurredAt, 'day'),
      )
    }

    let newList = []
    if (existingIndex > -1) {
      const existingItem = storeCollection[existingIndex]
      newList = storeCollection.map((element, index) => {
        if (index === existingIndex) {
          return replaceFakeParam ? omit({ ...existingItem, ...item }, 'fake') : item
        }
        return element
      })
    } else {
      newList = [...storeCollection, item]
    }

    if (reSortParam) {
      const {
        sort: { order, orderBy: orderByParam } = {
          order: 'ascending',
          orderBy: 'occurredAt',
        },
      } = state

      const newOrder = order === 'ascending' ? 'asc' : 'desc'

      newList = orderBy(newList, [camelCase(orderByParam), 'type', 'title'], [newOrder])
    }

    return set(storePath, newList, state)
  },

  // used in events, ingredients, scans
  deleteList: (state: Dictionary<any>, action: Action) => {
    const { payload, storePath } = action
    const storeCollection: Array<any> = get(state, storePath, [])

    const item = payload

    const newList = storeCollection.filter((element) => element.id !== item.id)

    return set(storePath, newList, state)
  },

  // used in events and ingredients
  backup: (state: Dictionary<any>, action: Action) => {
    const { storePath } = action
    const storeCollection = get(state, storePath, [])
    const order = get(state, 'order')
    return {
      ...state,
      backup: {
        [storePath]: storeCollection,
        order,
      },
    }
  },

  // used in events, ingredients and meals
  restore: (state: Dictionary<any>, action: Action) => {
    const { storePath } = action
    if (!state.backup) {
      return state
    }
    return {
      ...state,
      [storePath]: state.backup[storePath],
      order: state.backup.order,
    }
  },

  // used everywhere
  cacheSet: (state: Dictionary<any>, action: Action) => {
    const { cacheKey, payload } = action
    if (!cacheKey) {
      return state
    }

    // find oldest key (LRU strategy)
    let omitKey
    const cacheSize = keys(state.cache).length
    if (cacheSize > CACHE_MAX_SIZE - 1) {
      omitKey = minBy(values(state.cache), 'lastTouch').key
    }

    return {
      ...state,
      cache: {
        ...omit(state.cache, omitKey),
        [cacheKey]: { key: cacheKey, lastTouch: moment(), response: payload },
      },
    }
  },

  // used everywhere
  cacheClear: (state: Dictionary<any>, { payload }: CacheClearAction) => {
    const { matchName, includeDates } = payload || {}

    if (!matchName && !includeDates) {
      return {
        ...state,
        cache: {},
      }
    }

    const toUniqueSortedMomentDates = (dates: string[]) =>
      Array.from(new Set(dates))
        .map((value) => moment(value))
        .sort((a, b) => a.valueOf() - b.valueOf())

    const keysToOmit = Object.keys(state.cache).filter((key) => {
      const [name, cacheStartDate, cacheEndDate] = key.split(':')

      const hasMatchingName = matchName ? name === matchName : true

      if (!hasMatchingName) {
        return false
      }

      if (!cacheStartDate || !cacheEndDate || !includeDates) {
        return true
      }

      const uniqueCacheDates = toUniqueSortedMomentDates([cacheStartDate, cacheEndDate])
      const uniqueIncludeDates = toUniqueSortedMomentDates(includeDates)

      const payloadStartDate = uniqueIncludeDates[0]
      const payloadEndDate = uniqueIncludeDates[uniqueIncludeDates.length - 1]

      // We should handle 2 cases here:
      const hasIntersectingDates =
        // First one: cache dates are broader than include dates
        // 01 May - 31 May - cache dates
        // 09 May - 13 May - include dates
        uniqueIncludeDates.some((date) =>
          date.isBetween(cacheStartDate, cacheEndDate, 'day', '[]'),
        ) ||
        // Second one: include dates are broader than cache dates
        // 11 May - 11 May - cache dates
        // 09 May - 13 May - include dates
        uniqueCacheDates.some((date) =>
          date.isBetween(payloadStartDate, payloadEndDate, 'day', '[]'),
        )

      return hasIntersectingDates
    })

    return {
      ...state,
      cache: omit(state.cache, keysToOmit),
    }
  },

  // used in  events and meals
  updateSort: (state: Dictionary<any>, { payload }: Action) => ({
    ...state,
    sort: {
      ...state.sort,
      ...payload,
    },
  }),

  // used in ingredients
  setInitialOrder: (state: Dictionary<any>, { payload, dataPath }: Action) => {
    const orderCollection = get(payload, dataPath ?? '', [])
    const defaultOrder = orderCollection.map((object: any) => object.id)
    const newItems = defaultOrder.filter((id: any) => !state.order?.includes(id))

    if (!isEmpty(state.order)) {
      if (newItems.length === 0) {
        return state
      }

      return {
        ...state,
        order: [...newItems, ...state.order],
      }
    }

    return {
      ...state,
      order: defaultOrder,
    }
  },

  // used in ingredients
  updateOrder: (state: Dictionary<any>, { payload }: Action) => ({
    ...state,
    order: payload,
  }),

  // used in  ingredients
  updateOrderByItem: (state: Dictionary<any>, action: Action) => {
    const { id } = action.payload
    const { order } = state

    return { ...state, order: xor([id], order) }
  },
}

export default commonReducers
