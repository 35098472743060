import moment, { Moment } from 'moment'
import { lastOpenedSelector, calendarDateSelector } from '@src/selectors/app'

export function* unlessEventHasSelectedDate(_: any, { effectPayload, select }: any) {
  const { startDate, endDate } = yield select(calendarDateSelector)

  const startTime = moment(startDate).startOf('day')
  const endTime = moment(endDate).endOf('day')

  if (effectPayload.startedAt && effectPayload.endedAt) {
    return !(
      moment(effectPayload.startedAt).isBetween(startTime, endTime, undefined, '[]') ||
      moment(effectPayload.endedAt).isBetween(startTime, endTime, undefined, '[]')
    )
  }

  return !moment(effectPayload.occurredAt).isBetween(startTime, endTime, undefined, '[]')
}

export function* unlessHasOpenedAppToday(_: any, { select }: any) {
  const lastOpened: Moment = yield select(lastOpenedSelector)

  return moment().isSame(lastOpened, 'day')
}
