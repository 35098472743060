import React, { useEffect, useState } from 'react'
import DateTimePicker from 'react-native-modal-datetime-picker'
import { useTheme } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useStyleSheet, StyleService } from '@src/style/service'
import { useIsDarkMode } from '@config/theme'
import { formatDate, FULL_DATE_TIME_FORMAT } from '@config/momentFormat'
import { hoverTime } from '@src/screens/Home/utils/useChartInteractions'
import { PillButton } from '@screens/Meals'
import { useUpdateDraftMeal } from '@screens/Meals/hooks'
import { mealDraftSelector } from '@selectors/meals'
import { Global } from '@utils'

interface DatePickerProps {
  testID?: string
}

export const DatePicker = ({ testID }: DatePickerProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const isDarkMode = useIsDarkMode()
  const updateDraftMeal = useUpdateDraftMeal()

  const { occurredAt } = useSelector(mealDraftSelector)

  const [date, setDate] = useState(() => moment(occurredAt || hoverTime).toDate())

  const [isVisible, setPickerVisible] = useState(false)

  useEffect(() => {
    updateDraftMeal({ occurredAt: moment(date).local().format() })
  }, [date, updateDraftMeal])

  const onConfirm = (date: Date) => {
    setPickerVisible(false)
    setDate(date)
  }

  return (
    <>
      <PillButton
        icon="calendar-blank"
        text={formatDate(date, FULL_DATE_TIME_FORMAT)}
        testID={testID}
        accessibilityLabel="Meal Time"
        onPress={() => setPickerVisible(true)}
      />
      <DateTimePicker
        isDarkModeEnabled={isDarkMode}
        date={Global.toLocalTimezoneDate(date)}
        isVisible={isVisible}
        onConfirm={onConfirm}
        onCancel={() => setPickerVisible(false)}
        mode="datetime"
        textColor={theme['theme.text.primary']}
        pickerContainerStyleIOS={styles.pickerContainerIOS}
        themeVariant={isDarkMode ? 'dark' : 'light'}
      />
    </>
  )
}

const themedStyle = StyleService.create({
  pickerContainerIOS: {
    backgroundColor: 'theme.secondary.base',
  },
})
