import defaultReducers from '@models/defaultReducers'
import { transformCharts } from '@src/models/dailyCharts/chart.transforms'
import { DailyChartsStoreState } from './types'
import { defaultDailyChartsState } from './constants'

const reducers = {
  ...defaultReducers,

  fetchChartsData: (state: DailyChartsStoreState, { payload }: { payload: any }) => {
    return {
      ...state,
      charts: {
        ...defaultDailyChartsState.charts,
        ...transformCharts(payload.charts),
      },
    }
  },

  backup: (state: DailyChartsStoreState) => {
    const { charts } = state
    return {
      ...state,
      backup: {
        charts,
      },
    }
  },

  restore: (state: DailyChartsStoreState) => {
    if (!state.backup) {
      return state
    }
    return {
      ...state,
      charts: state.backup.charts,
    }
  },
}

export default reducers
