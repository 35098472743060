import React, { useEffect } from 'react'
import { useWindowDimensions, View } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { isEmpty, isNumber } from 'lodash'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { Score } from '@components'
import { ActivityChart } from '@src/containers/ActivityChart/ActivityChart'
import { formatScore } from '@src/components/nutritionCard/utils'
import { extractIntervalValues } from '@src/components/list/utils/utils'
import { LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { IngredientsTotal } from '@screens/Ingredients'
import { StatScores } from '@screens/Meals/components/StatScores'
import { Device } from '@src/config/index'
import { GlucoseStatistics } from '@src/components/list'

interface MealShareDetailProps {
  item: Meal
  onLoad: () => void
}

const MealDetailShareableView = (props: MealShareDetailProps) => {
  const styles = useStyleSheet(themedStyle)
  const dimensions = useWindowDimensions()

  const { item, onLoad } = props
  const { statistics, description, ingredients } = item

  const showMealGraph = !!extractIntervalValues(item)
  const hasIngredients = !isEmpty(ingredients)

  useEffect(() => {
    if (!showMealGraph) {
      onLoad()
    }
  }, [showMealGraph, onLoad])

  const source = { uri: item?.avatar }
  const hasUri = !!source && !!source.uri

  const descriptionContent =
    description || item.ingredients.map(({ description }) => description).join(', ')

  return (
    <View>
      {(hasUri || !!descriptionContent) && (
        <>
          <View style={styles.descriptionContainer}>
            {hasUri && <FastImage style={styles.image as ImageStyle} source={source} />}
            <Text type="regular">{descriptionContent}</Text>
          </View>
          <Divider appearance="large" />
        </>
      )}
      {hasIngredients && (
        <>
          <IngredientsTotal ingredients={ingredients} />
          <Divider appearance="large" />
        </>
      )}
      {isNumber(statistics?.glucoseScore) && (
        <>
          <View style={Device.hasLargeScreen(dimensions) ? styles.largeScreenCard : {}}>
            <View style={styles.sectionHeader}>
              <Text type="regular" bold>
                Meal Score
              </Text>
              <Score number={formatScore(statistics.glucoseScore)} />
            </View>
            <StatScores statistics={statistics} style={styles.paddingHorizontal} />
          </View>
          <Divider appearance="large" />
        </>
      )}
      {showMealGraph && (
        <>
          <View style={Device.hasLargeScreen(dimensions) ? styles.largeScreenCard : {}}>
            <View style={styles.sectionHeader}>
              <Text type="regular" bold>
                Meal Graph
              </Text>
            </View>
            <View style={styles.paddedRow}>
              <GlucoseStatistics statistics={statistics} mode="large" style={styles.statistics} />
              <ActivityChart disableAnimation onLoad={onLoad} item={item} />
            </View>
          </View>
          <Divider appearance="large" />
        </>
      )}
    </View>
  )
}

export default MealDetailShareableView

const themedStyle = StyleService.create({
  descriptionContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginVertical: 16,
  },
  image: {
    height: 128,
    aspectRatio: 1,
    marginRight: 24,
    borderRadius: 24,
  },
  statistics: {
    marginRight: 24,
  },
  largeScreenCard: {
    maxWidth: 360,
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 48,
    padding: 16,
  },
  paddingHorizontal: {
    paddingHorizontal: 16,
  },
  paddedRow: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
})
