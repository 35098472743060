import { Model } from '@models'
import { Caching } from '@utils'
import { Calendar } from '@src/models/app.types'
import { calendarDateSelector } from '@src/selectors/app'
import nutritionReducers from './reducers'
import { defaultDailyNutritionState } from './constants'
import ALL_NUTRITION from './graphql/allNutrition'

export default class DailyNutrition {
  namespace = 'dailyNutrition'

  state = {
    ...Model.defaultState,
    ...defaultDailyNutritionState,
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ALL_NUTRITION,
      dataPath: 'allNutrition',
      *variables(payload: any, { select }: any) {
        let queryVariables = payload

        if (!queryVariables?.filter?.startDate || !queryVariables?.filter?.endDate) {
          const { startDate, endDate }: Partial<Calendar> = yield select(calendarDateSelector)
          queryVariables = { filter: { startDate, endDate } }
        }

        return queryVariables
      },
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey(`${this.namespace}/fetch`, variables),
      reducers: [{ name: 'setNutrition' }],
    }),

    setGlucoseThresholdsUpdated: Model.buildEffect({
      name: `${this.namespace}/setGlucoseThresholdsUpdated`,
      reducers: [{ name: 'glucoseThresholdsUpdated' }],
    }),
  }
  reducers = nutritionReducers
}
