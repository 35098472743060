import React from 'react'
import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { CircleIcon } from '@src/components'
import { LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'

interface MealListItemProps {
  item: Meal
  onItemSelected: () => void
  setFavorite: () => void
}

export const MealListItem = ({ item, onItemSelected, setFavorite }: MealListItemProps) => {
  const styles = useStyleSheet(themedStyle)

  const iconOrAvatar = item.avatar ? (
    <ImageWithPlaceholder
      style={styles.itemImage as ImageStyle}
      source={{ uri: item.avatar }}
      placeholderComponent={<ActivityIndicator animating size="small" {...styles.spinner} />}
    />
  ) : (
    <CircleIcon name="fork-knife" style={styles.itemImage} />
  )

  return (
    <TouchableOpacity
      accessibilityLabel={item.title}
      style={styles.itemContainer}
      activeOpacity={0.7}
      onPress={onItemSelected}
    >
      {iconOrAvatar}
      <View style={styles.itemContent}>
        <Text type="large" bold numberOfLines={2}>
          {item.title}
        </Text>
        <Text type="small" style={styles.itemSubtitle} numberOfLines={2}>
          {item.description || item.ingredients.map(({ description }) => description).join(' • ')}
        </Text>
      </View>
      <TouchableOpacity
        accessibilityLabel="Toggle favorite"
        style={styles.iconButton}
        onPress={setFavorite}
      >
        <Icon
          name="heart"
          style={item.favorite && styles.iconFavorite}
          weight={item.favorite ? 'fill' : 'regular'}
        />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    paddingHorizontal: 8,
    paddingVertical: 12,
  },
  itemImage: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 56,
    width: 56,
    marginRight: 16,
    backgroundColor: 'theme.secondary.base',
    borderRadius: 28,
  },
  itemContent: {
    flex: 1,
    justifyContent: 'center',
  },
  itemSubtitle: {
    color: 'theme.text.secondary',
  },
  iconButton: {
    width: 48,
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  iconFavorite: {
    color: 'theme.like',
  },
  spinner: {
    color: 'theme.surface.base',
  },
})
