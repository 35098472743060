import { gql } from '@apollo/client'

export const FETCH_EHR_EARLIER_AVAILABLE_SLOTS = gql`
  query ehrEarlierAvailableSlots($appointmentId: ID!) {
    ehrEarlierAvailableSlots(appointmentId: $appointmentId) {
      slots {
        time
        providerId
        providerUserId
      }
    }
  }
`
