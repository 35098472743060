import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'
import { DATE_FORMAT } from '@src/config/momentFormat'

const getEditRoute = (item: {
  __typename: string
}): 'EditMeasurement' | 'MealDetail' | 'ActivityDetail' | 'EditJournalEntry' | null => {
  switch (item.__typename) {
    case 'Measurement':
      return 'EditMeasurement'
    case 'Meal':
      return 'MealDetail'
    case 'Activity':
      return 'ActivityDetail'
    case 'JournalEntry':
      return 'EditJournalEntry'
  }
  return null
}

const getNamespaceForType = (item: { __typename: string }) => {
  switch (item.__typename) {
    case 'Measurement': {
      return 'measurements'
    }
    case 'Meal': {
      return 'meals'
    }
    case 'Ingredient': {
      return 'ingredients'
    }
    case 'Activity': {
      return 'activities'
    }
    case 'JournalEntry': {
      return 'journalEntries'
    }
  }
  return ''
}

export const useMenuHandling = (item: any, canJumpToDate?: boolean) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { showDestructiveActionSheet } = useApplicationActionSheet()

  const handleItemDelete = () => {
    const namespace = getNamespaceForType(item)
    if (namespace) {
      dispatch({
        type: `${namespace}/delete`,
        payload: item,
      })
    }
  }
  const onItemEdit = () => {
    const route = getEditRoute(item)
    if (route) {
      navigation.navigate(route as any, { item })
    }
  }
  const onItemDelete = () => {
    showDestructiveActionSheet({
      title: 'Delete',
      message: 'This will delete the selected item. Are you sure?',
      label: 'Delete',
      handler: () => handleItemDelete(),
    })
  }
  const onJumpToDate = () => {
    const startDate = moment(item.occurredAt).format(DATE_FORMAT)
    const endDate = startDate

    navigation.navigate('Events')
    dispatch({
      type: 'app/changeDateRange',
      payload: {
        startDate,
        endDate,
      },
    })
  }

  return {
    onItemEdit,
    onItemDelete,
    onJumpToDate,
    defaultMenuItems: [
      { title: 'Delete', onPress: onItemDelete, isEnabled: !item.fake },
      ...(canJumpToDate ? [{ title: 'Jump to Date', onPress: onJumpToDate, isEnabled: true }] : []),
    ],
  }
}
