import React, { useEffect, useRef, useState } from 'react'
import { View, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Button, Text, IconName } from '@components/base'
import { AppStackParamList, RootStackParamList, ScreenAndProps } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  Appointment,
  AppointmentDynamicFilterField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  EhrAppointment,
  EhrAppointmentAttendanceChargeKind,
  EhrAppointmentCategory,
  EhrAppointmentKind,
  FilterAppointmentsKind,
  InsuranceBerryStreetAppointmentSchedulingRule,
  InsuranceBerryStreetAppointmentStatus,
  InsuranceBerryStreetAppointmentType,
  Product,
} from '@src/types'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { Intercom, useGoBack } from '@src/utils'
import { ALL_APPOINTMENTS } from '@screens/NutritionistHub/graphql/allAppointments.ts'
import { AllAppointmentsQuery } from '@screens/NutritionistHub/graphql/allAppointments.generated.ts'
import {
  ehrSuggestedAvailabilitySelector,
  suggestedAvailabilitySelector,
} from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import { userSelector } from '@src/selectors/app'
import { EhrAppointmentsQuery } from '@src/screens/NutritionistHub/graphql/ehrAppointments.generated'
import { ProductIcon } from '../types/types'

const iconNameForProduct = (product: Product): IconName | undefined => {
  switch (product.icon) {
    case ProductIcon.Question:
      return 'seal-question'
    case ProductIcon.Goals:
      return 'list-checks'
    case ProductIcon.Nutrition:
      return 'bowl-food'
    case ProductIcon.VideoCall:
      return 'video-camera'
    default:
      return
  }
}
const POLLING_INTERVAL = 3000 // 3 seconds
const TOTAL_POLLING_DURATION = 30 * 1000 // 30 seconds

export const DietitianAddonOrderConfirmed = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const goBack = useGoBack()
  const dispatch = useDispatch()
  const {
    params: { product, groupedProducts, goBackOnSuccess, suggestedCallFlow },
  } = useRoute<RouteProp<AppStackParamList, 'DietitianAddonOrderConfirmed'>>()

  const successRef = useRef(false)

  const user = useSelector(userSelector)
  const ehrSuggestedAvailability = useSelector(ehrSuggestedAvailabilitySelector)
  const suggestedAvailability = useSelector(suggestedAvailabilitySelector)

  const { getRequiredPolicyConsents } = useGetRequiredPolicyConsents()

  const titleIcon = iconNameForProduct(product)

  const [appointmentLoadingError, setAppointmentLoadingError] = useState(false)

  const isSchedulingRequired =
    product.schedulingRule.key !== InsuranceBerryStreetAppointmentSchedulingRule.NoScheduling

  // If product has scheduling rule which requires scheduling we need to have pending appointment from backend.
  // Here we start polling to receive appointment once invoice is turned into paid state.
  const { data: pendingAppointmentsData, stopPolling } = useQuery<AllAppointmentsQuery>(
    ALL_APPOINTMENTS,
    {
      skip: !isSchedulingRequired,
      pollInterval: POLLING_INTERVAL,
      variables: {
        dynamicFilters: {
          items: [
            {
              field: AppointmentDynamicFilterField.Status,
              operation: DynamicFilterItemOperation.Eq,
              value: InsuranceBerryStreetAppointmentStatus.SchedulingPending,
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
    },
  )
  const {
    data: pendingEhrAppointmentsData,
    stopPolling: stopEhrPolling,
  } = useQuery<EhrAppointmentsQuery>(ALL_APPOINTMENTS, {
    skip: !isSchedulingRequired,
    pollInterval: POLLING_INTERVAL,
    variables: {
      variables: {
        filterKind: FilterAppointmentsKind.PendingSchedule,
        category: EhrAppointmentCategory.Coaching,
      },
    },
  })

  const allPendingAppointments = pendingAppointmentsData?.allAppointments?.appointments || []
  const allPendingEhrAppointments = pendingEhrAppointmentsData?.ehrAppointments?.appointments || []

  const pendingAppointment = user?.migrationFromInsuranceEnabled
    ? allPendingEhrAppointments[0]
    : allPendingAppointments[0]

  const handleDonePress = () => {
    if (goBackOnSuccess) {
      goBack()
      return
    }

    if (!isSchedulingRequired) {
      navigation.navigate('Subscriptions')
      return
    }

    const pendingAppointmentId = pendingAppointment?.id

    if (user?.migrationFromInsuranceEnabled) {
      navigation.navigate('CallScheduling', {
        appointmentKind: EhrAppointmentKind.NoneCovered,
        pendingAppointmentId,
        allowBackNavigation: true,
      })
      return
    }

    navigation.navigate('CallScheduling', {
      appointmentType: InsuranceBerryStreetAppointmentType.NoneCovered,
      pendingAppointmentId,
      allowBackNavigation: true,
    })
  }

  const handleErrorPress = () => {
    Intercom.showIntercomMessenger({ source: 'DietitianAddonOrderConfirmed' })
    navigation.navigate('Dashboard')
  }

  // Set timeout for polling if we need to receive appointment to proceed
  useEffect(() => {
    if (!isSchedulingRequired) {
      return
    }
    if (pendingAppointment) {
      return
    }

    const timeoutHandle = setTimeout(() => {
      stopPolling()
      stopEhrPolling()
      if (!pendingAppointment) {
        setAppointmentLoadingError(true)
      }
    }, TOTAL_POLLING_DURATION)

    return () => {
      stopPolling()
      stopEhrPolling()
      clearTimeout(timeoutHandle)
    }
  }, [pendingAppointment, isSchedulingRequired, stopPolling, stopEhrPolling])

  // Once pending appointment is present we can stop polling and proceed
  useEffect(() => {
    if (pendingAppointment) {
      stopPolling()
      stopEhrPolling()

      if (user?.migrationFromInsuranceEnabled) {
        if (suggestedCallFlow && ehrSuggestedAvailability) {
          const ehrPendingAppointment = pendingAppointment as EhrAppointment

          const appointmentKind = ehrPendingAppointment.kind || EhrAppointmentKind.NoneCovered

          dispatch({
            type: 'nutritionistHub/bookAppointment',
            payload: {
              appointmentKind,
              pendingAppointmentId: pendingAppointment.id,
              date: ehrSuggestedAvailability.meetingAt,
              coachId: ehrSuggestedAvailability.providerId,
              attendanceChargeKind: EhrAppointmentAttendanceChargeKind.BsPaid,
            },
            success: async () => {
              if (successRef.current) {
                // Prevent the success callback from being called multiple times due to polling
                return
              }
              successRef.current = true

              dispatch({ type: 'nutritionistHub/fetchUpcomingEhrAppointments' })
              dispatch({ type: 'nutritionistHub/fetchEhrSuggestedAvailability' })
              dispatch({ type: 'nutritionistHub/fetchInsurancePolicy' })

              const nextScreen: ScreenAndProps = {
                screen: 'Drawer',
                params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } },
              }
              const requiredPolicies = await getRequiredPolicyConsents()

              if (requiredPolicies.length > 0) {
                navigation.replace('PolicyConsent', { policy: requiredPolicies[0], nextScreen })
              } else {
                navigation.replace(nextScreen.screen, nextScreen.params)
              }
            },
            failure: () => {
              navigation.replace('CallScheduling', {
                appointmentKind,
                pendingAppointmentId: pendingAppointment.id,
                allowBackNavigation: true,
                errorMessage:
                  'The suggested appointment time is no longer available. Please choose a different time slot',
              })
            },
          })
        }

        return
      }

      if (suggestedCallFlow && suggestedAvailability) {
        const pendingInsuranceAppointment = pendingAppointment as Appointment
        const appointmentType =
          pendingInsuranceAppointment.appointmentType ||
          InsuranceBerryStreetAppointmentType.NoneCovered
        dispatch({
          type: 'nutritionistHub/bookVideoCall',
          payload: {
            appointmentType,
            pendingAppointmentId: pendingAppointment.id,
            date: suggestedAvailability.meetingAt,
            coachId: suggestedAvailability.nutritionistId,
          },
          success: async () => {
            if (successRef.current) {
              // Prevent the success callback from being called multiple times due to polling
              return
            }
            successRef.current = true

            dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
            dispatch({ type: 'nutritionistHub/fetchSuggestedAvailability' })
            dispatch({ type: 'nutritionistHub/fetchInsurancePolicy' })

            const nextScreen: ScreenAndProps = {
              screen: 'Drawer',
              params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } },
            }
            const requiredPolicies = await getRequiredPolicyConsents()

            if (requiredPolicies.length > 0) {
              navigation.replace('PolicyConsent', { policy: requiredPolicies[0], nextScreen })
            } else {
              navigation.replace(nextScreen.screen, nextScreen.params)
            }
          },
          failure: () => {
            navigation.replace('CallScheduling', {
              appointmentType,
              pendingAppointmentId: pendingAppointment.id,
              allowBackNavigation: true,
              errorMessage:
                'The suggested appointment time is no longer available. Please choose a different time slot',
            })
          },
        })
      }
    }
  }, [
    pendingAppointment,
    stopPolling,
    stopEhrPolling,
    suggestedAvailability,
    ehrSuggestedAvailability,
    suggestedCallFlow,
    dispatch,
    navigation,
    getRequiredPolicyConsents,
    user?.migrationFromInsuranceEnabled,
  ])

  const { title, description, details } = groupedProducts ?? product

  if (
    isSchedulingRequired &&
    (!pendingAppointment || (suggestedCallFlow && suggestedAvailability))
  ) {
    return (
      <LoadingContainer
        loadingMessage="Confirming your purchase"
        errorMessage="Can't confirm your purchase"
        error={appointmentLoadingError}
      >
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Continue"
          onPress={handleErrorPress}
        >
          Contact Support
        </Button>
      </LoadingContainer>
    )
  }

  return (
    <NavigationContainer title={title}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <SafeAreaView style={styles.container} edges={['bottom']}>
          <View style={styles.confirmationContainer}>
            <View>
              <Icon name="confetti" style={styles.congratsIcon} />
              <Text type="title-3" style={styles.congratsTitle}>
                Success!
              </Text>
              <Text type="large" bold style={styles.congratsSubtitle}>
                You have purchased a new nutrition coaching service:
              </Text>
            </View>
            <View style={styles.productContainer}>
              <View style={styles.productTitle}>
                {titleIcon && <Icon name={titleIcon} style={styles.productIcon} />}
                <Text type="large" bold style={styles.productName}>
                  {title}
                </Text>
              </View>
              <Text type="regular" style={styles.productDescription}>
                {description ?? ''}
              </Text>
            </View>
            {details && !isSchedulingRequired && (
              <View style={styles.nextStepsContainer}>
                <Text type="regular" bold style={styles.nextStepsTitle}>
                  WHAT'S NEXT?
                </Text>
                {details.map((detailsText) => (
                  <Text type="small" key={detailsText} style={styles.nextStepsDescription}>
                    {detailsText}
                  </Text>
                ))}
              </View>
            )}
          </View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Done"
            style={styles.doneButton}
            onPress={handleDonePress}
          >
            {isSchedulingRequired ? 'Schedule call' : 'Done'}
          </Button>
        </SafeAreaView>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 0,
    paddingHorizontal: 16,
  },
  confirmationContainer: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
  },
  congratsIcon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
  },
  congratsTitle: {
    marginTop: 24,
    textAlign: 'center',
  },
  congratsSubtitle: {
    marginTop: 16,
    textAlign: 'center',
  },
  productContainer: {
    marginTop: 16,
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'theme.primary.base',
    backgroundColor: 'theme.surface.base2',
  },
  productTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  productIcon: {
    marginRight: 8,
    width: 16,
    height: 16,
  },
  productName: {
    color: 'theme.primary.base',
  },
  productDescription: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
  nextStepsContainer: {
    marginTop: 32,
  },
  nextStepsTitle: {
    color: 'theme.primary.base',
  },
  nextStepsDescription: {
    marginTop: 2,
  },
  doneButton: {
    marginVertical: 16,
  },
})
