import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { BillingProductCategory, EhrAppointment } from '@src/types'
import { videoCallProductSelector } from '@src/selectors/app'
import { CustomEventTypes } from '@config'
import {
  AppointmentDetails,
  getAppointmentDetails,
  isAppointmentScheduled,
  isEhrBookingAvailable,
} from '../utils'
import { DEFAULT_VIDEO_CALL_INFO, INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN } from '../constants'
import { insurancePolicySelector } from '../models/nutritionistHub.selectors'
import { useNutritionistHubTracker } from '../hooks'
import { Card } from './Card'
import { AppointmentScheduledInfoCard } from './AppointmentScheduledInfoCard'
import { AppointmentNotScheduledCard } from './AppointmentNotScheduledCard'

interface AppointmentInfoProps {
  appointment: EhrAppointment | null
}

export const AppointmentInfoCard = (props: AppointmentInfoProps) => {
  const styles = useStyleSheet(themedStyles)
  const { appointment } = props
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const appointmentDetails = appointment
    ? getAppointmentDetails(appointment)
    : ({} as AppointmentDetails)

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: BillingProductCategory.NutritionistHub },
    })
  }, [dispatch])

  const videoCallProduct = useSelector(videoCallProductSelector)
  const insurancePolicy = useSelector(insurancePolicySelector)

  const track = useNutritionistHubTracker()
  const insurancePolicyDeclined = !!insurancePolicy?.declined

  if (isAppointmentScheduled(appointmentDetails)) {
    return <AppointmentScheduledInfoCard appointmentDetails={appointmentDetails} />
  }

  if (isEhrBookingAvailable(insurancePolicy, appointmentDetails)) {
    return <AppointmentNotScheduledCard appointmentDetails={appointmentDetails} />
  }

  const onButtonPress = () => {
    if (videoCallProduct) {
      track(CustomEventTypes.NutritionistHubLearnMoreTapped)

      if (insurancePolicyDeclined || (insurancePolicy && !insurancePolicy.hasUnusedCalls)) {
        navigation.navigate('PaidCallsIntroScreen')
        return
      }
      navigation.navigate('VideoCallsIntroModal')
    }
  }

  return (
    <Card buttonText="Learn More" onButtonPress={onButtonPress}>
      <Icon name="video-camera" size="32" style={styles.header} />
      <Text type="large" lineSpacing="tight" style={styles.title}>
        {DEFAULT_VIDEO_CALL_INFO.title}
      </Text>
      <Text type="small" lineSpacing="none" style={styles.description}>
        {(insurancePolicyDeclined || (insurancePolicy && !insurancePolicy.hasUnusedCalls)) &&
        videoCallProduct
          ? `$${videoCallProduct.price} for 30min`
          : INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN}
      </Text>
    </Card>
  )
}

const themedStyles = StyleService.create({
  header: {
    marginBottom: 8,
  },
  title: {
    marginBottom: 8,
  },
  description: {
    color: 'theme.text.secondary',
  },
})
