import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { FlatList, TouchableOpacity, View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { ProgramCard, LoadingProgramCard } from '@src/screens/Learn/components/ProgramCard'
import { AppStackParamList, DrawerParamList } from '@src/navigation/types'
import { useProgramsWithContent } from '@src/screens/Learn/hooks'
import { ListHeader } from '@src/screens/Learn/components/ListHeader'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { ProgramWithContent } from '../models/courses.types'

type NavigationProps = DrawerNavigationProp<DrawerParamList> &
  StackNavigationProp<AppStackParamList>

export const LearnContainer = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<NavigationProps>()
  const { programs, isLoading } = useProgramsWithContent()

  const renderItem = ({ item: program }: { item: ProgramWithContent }) => (
    <TouchableOpacity
      key={program.id}
      onPress={() => navigation.navigate('CoursesProgram', { programId: program.id })}
      accessibilityLabel="programCard"
    >
      <ProgramCard program={program} style={styles.programCard} />
    </TouchableOpacity>
  )

  const renderLoadingItem = () => <LoadingProgramCard style={styles.programCard} />

  const listHeaderComponent = (
    <View style={styles.listHeader}>
      <ListHeader />
      <Text type="large" bold style={styles.title}>
        Courses
      </Text>
    </View>
  )

  return (
    <AccountAvatarNavigationBar title="Learn">
      <FlatList
        ListHeaderComponent={listHeaderComponent}
        style={styles.programsList}
        data={!isLoading ? programs : Array(3).fill(0)}
        renderItem={isLoading ? renderLoadingItem : renderItem}
      />
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  programsList: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  programCard: {
    flexBasis: 'auto',
    marginBottom: 16,
    borderRadius: 14,
    height: 213,
  },
  listHeader: {
    marginBottom: 16,
    backgroundColor: 'theme.background',
  },
  title: {
    backgroundColor: 'theme.background',
    color: 'theme.text.secondary',
    marginTop: 24,
  },
})
