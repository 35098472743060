import { gql } from '@apollo/client'
import { EHR_APPOINTMENT_FIELDS } from '@src/graphql/fragments/ehrAppointment'

const CLAIM_FREE_APPOINTMENT = gql`
  ${EHR_APPOINTMENT_FIELDS}
  mutation claimFreeEhrAppointment {
    claimFreeEhrAppointment {
      ...EhrAppointmentFields
    }
  }
`

export default CLAIM_FREE_APPOINTMENT
