import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { take } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { Button, Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollViewWithFade } from '@src/components'
import {
  ehrSuggestedAvailabilitySelector,
  insurancePolicySelector,
  pendingEhrAppointmentsSelector,
  upcomingEhrAppointmentsSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { AppStackParamList } from '@navigation/types'
import { SuggestedCallCard } from '@screens/NutritionistHub/components/UpcomingCalls/SuggestedCallCard.tsx'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'
import { CustomEventTypes, ErrorMessages } from '@config'
import { EhrAppointment, EhrAppointmentKind, EhrAppointmentStateKind } from '@src/types'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import { useSnack } from '@src/utils'
import { DEFAULT_VIDEO_CALL_INFO } from '../../constants'

const SLOT_TAKEN_ERROR = 'slot is already taken'

export const UpcomingAppointments = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const track = useNutritionistHubTracker()

  const upcomingAppointments = useSelector(upcomingEhrAppointmentsSelector)
  const suggestedAvailability = useSelector(ehrSuggestedAvailabilitySelector)
  const insurancePolicy = useSelector(insurancePolicySelector)
  const pendingAppointments = useSelector(pendingEhrAppointmentsSelector)

  const canBookCall =
    insurancePolicy?.inReview || insurancePolicy?.hasUnusedCalls || pendingAppointments.length > 0

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchUpcomingEhrAppointments' })
    dispatch({ type: 'nutritionistHub/fetchPendingEhrAppointments' })
    dispatch({ type: 'nutritionistHub/fetchEhrSuggestedAvailability' })
  }, [dispatch])

  const handleSeeAllPress = () => {
    track(CustomEventTypes.NutritionistHubSeeAllCallsTapped)
    navigation.navigate('AllVideoCalls')
  }

  const { getRequiredPolicyConsents, loading } = useGetRequiredPolicyConsents()

  const onOpenSchedulerPress = ({ appointmentKind }: { appointmentKind: EhrAppointmentKind }) => {
    track(CustomEventTypes.NutritionistHubSuggestedCallEditTapped)

    if (canBookCall) {
      navigation.navigate('CallScheduling', {
        appointmentKind,
        allowBackNavigation: true,
      })
    } else {
      navigation.navigate('VideoCallCheckout', {})
    }
  }

  const onSchedulePress = ({
    appointmentKind,
    meetingAt,
    nutritionistId,
  }: {
    appointmentKind: EhrAppointmentKind
    meetingAt: string
    nutritionistId: string
  }) => {
    track(CustomEventTypes.NutritionistHubSuggestedCallConfirmTapped)

    if (!canBookCall) {
      navigation.navigate('VideoCallCheckout', { suggestedCallFlow: true })
      return
    }

    dispatch({
      type: 'nutritionistHub/bookAppointment',
      payload: {
        appointmentKind,
        date: meetingAt,
        coachId: nutritionistId,
        pendingAppointmentId: pendingAppointments[0]?.id,
      },
      success: async () => {
        dispatch({ type: 'nutritionistHub/fetchUpcomingEhrAppointments' })
        dispatch({ type: 'nutritionistHub/fetchEhrSuggestedAvailability' })
        dispatch({ type: 'nutritionistHub/fetchInsurancePolicy' })

        const requiredPolicies = await getRequiredPolicyConsents()
        if (requiredPolicies.length > 0) {
          navigation.navigate('PolicyConsent', { policy: requiredPolicies[0] })
        }
      },
      failure: (error: Error) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        if (errorMessage.includes(SLOT_TAKEN_ERROR)) {
          navigation.navigate('CallScheduling', {
            appointmentKind,
            allowBackNavigation: true,
            errorMessage:
              'The suggested appointment time is no longer available. Please choose a different time slot',
          })
        } else {
          showSnack(errorMessage, null, 'error')
        }
      },
    })
  }

  const onCallCardPress = (appointment: EhrAppointment) => {
    const {
      id,
      meetingAt,
      title,
      providerName,
      unauthenticatedIcsLink,
      addToGcalLink,
      kind,
    } = appointment

    if (appointment.lastState.kind === EhrAppointmentStateKind.Scheduled) {
      navigation.navigate('VideoCallConfirmedModal', {
        id,
        title: title || DEFAULT_VIDEO_CALL_INFO.title,
        nutritionistName: providerName,
        date: meetingAt,
        addToGoogleCalendarLink: addToGcalLink,
        unauthenticatedIcsLink,
        appointmentKind: kind || EhrAppointmentKind.Initial_60,
        chargeKind: appointment.attendances[0].chargeKind,
      })
      return
    }

    navigation.navigate('VideoCallInReviewModal', {
      title: title || DEFAULT_VIDEO_CALL_INFO.title,
      nutritionistName: providerName,
      date: meetingAt,
    })
  }

  return (
    <ScrollViewWithFade>
      <View style={styles.container}>
        <View style={styles.sectionHeader}>
          <View style={styles.header}>
            <Icon name="calendar-blank" />
            <View style={styles.headerText}>
              <Text type="regular" bold>
                Upcoming Calls
              </Text>
            </View>
          </View>
          <Button
            accessibilityLabel="seeAllCalls"
            type="outline"
            size="small"
            style={styles.seeAllButton}
            onPress={handleSeeAllPress}
          >
            See All
          </Button>
        </View>
        <View style={styles.callsContainer}>
          {take(upcomingAppointments, 5).map((appointment) => {
            const { id, meetingAt, title, lastState, providerName, kind, recurrent } = appointment
            const inFuture = meetingAt && moment(meetingAt).isAfter(moment())
            return (
              <CallCard
                key={id}
                title={title}
                meetingAt={meetingAt}
                lastStateKind={lastState.kind}
                nutritionistName={providerName}
                appointmentKind={kind}
                recurrent={recurrent}
                onPress={inFuture ? () => onCallCardPress(appointment) : undefined}
              />
            )
          })}
          {suggestedAvailability && insurancePolicy && (
            <SuggestedCallCard
              pillText="Suggested"
              title={suggestedAvailability.title}
              meetingAt={suggestedAvailability.meetingAt}
              nutritionistName={suggestedAvailability.providerName}
              appointmentKind={suggestedAvailability.appointmentKind}
              textStyle={styles.suggestedCallText}
              onOpenSchedulerPress={() => {
                onOpenSchedulerPress({
                  appointmentKind: suggestedAvailability.appointmentKind,
                })
              }}
              onSchedulePress={() => {
                onSchedulePress({
                  appointmentKind: suggestedAvailability.appointmentKind,
                  meetingAt: suggestedAvailability.meetingAt,
                  nutritionistId: suggestedAvailability.providerId,
                })
              }}
              schedulingDisabled={loading}
            />
          )}
        </View>
      </View>
    </ScrollViewWithFade>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 24,
  },
  sectionHeader: {
    marginTop: 24,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    marginLeft: 20,
    lineHeight: 24,
    flexDirection: 'column',
  },
  seeAllButton: {
    alignSelf: 'center',
  },
  callsContainer: {
    rowGap: 24,
  },
  suggestedCallText: {
    color: 'theme.text.tertiary',
    marginTop: 12,
  },
})
