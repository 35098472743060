import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { EhrAppointmentAttendanceChargeKind, User } from '@src/types'
import { appointmentKindsSelector, userSelector } from '@src/selectors/app'
import {
  AppointmentDetails,
  getEhrSchedulingConfig,
  isEhrFollowupSchedulingAvailable,
} from '../utils'
import { useInitialAppointmentKind } from '../hooks'
import { Card } from './Card'

interface AppointmentInfoProps {
  appointmentDetails: AppointmentDetails
}

export const AppointmentNotScheduledCard = (props: AppointmentInfoProps) => {
  const { appointmentDetails } = props
  const navigation = useNavigation()

  const user = useSelector(userSelector)
  const appointmentKinds = useSelector(appointmentKindsSelector)

  const initialAppointmentKind = useInitialAppointmentKind()
  const followupCallNotScheduled = isEhrFollowupSchedulingAvailable(
    appointmentDetails,
    user as User,
  )

  const schedulingConfig = getEhrSchedulingConfig(
    appointmentDetails,
    followupCallNotScheduled,
    initialAppointmentKind,
    appointmentKinds,
  )

  const userAttendance = appointmentDetails?.attendances?.find(
    (attendance) => attendance.user.id === user?.id,
  )
  const isCallFree = userAttendance?.chargeKind === EhrAppointmentAttendanceChargeKind.BsFree

  const [buttonText, description] = isCallFree
    ? ['Book Call', 'Free Call']
    : ['Schedule', 'Not Scheduled']

  const styles = useStyleSheet(themedStyle)
  const onSchedulePress = () => {
    navigation.navigate('CallScheduling', schedulingConfig.navigationParams)
  }

  return (
    <Card buttonText={buttonText} onButtonPress={onSchedulePress}>
      <Icon name="video-camera" size="32" style={styles.header} />
      <Text type="large" lineSpacing="tight" style={styles.title}>
        {schedulingConfig.title}
      </Text>
      <Text
        type="small"
        bold
        lineSpacing="none"
        style={isCallFree ? styles.text : styles.textWarning}
      >
        {description}
      </Text>
    </Card>
  )
}

const themedStyle = StyleService.create({
  header: {
    marginBottom: 8,
  },
  title: {
    marginBottom: 8,
  },
  text: {
    color: 'theme.text.secondary',
  },
  textWarning: {
    color: 'theme.error.base',
  },
})
