import moment from 'moment'
import { sortBy } from 'lodash'

import { SensorStatus } from 'react-native-freestyle-libre'
import { Model } from '@models'
import { HealthKit } from '@services'
import { Caching, User } from '@utils'
import { Device } from '@config'

import commonReducers from '@src/models/commonReducers'
import { healthKitSyncStatusSelector } from '@src/selectors/integrations'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { Scan } from '@src/types'
import { RootStoreState } from '@src/models/app.types'
import CREATE_SCAN from '../graphql/createScan'
import CREATE_ACTIVATION from '../graphql/createActivation'
import ALL_SCANS from '../graphql/allScans'

import { transform } from '../transforms'
import CREATE_BLUETOOTH_SCAN from '../graphql/createBluetoothScan'

const scansSubmitCacheClearPayload = (response: Scan) => {
  const includeDates = [response.createdAt] // scan doesn't have occurredAt field
  const { measurements } = response
  if (measurements.length > 0) {
    const firstMeasurement = sortBy(measurements, 'occurredAt')[0]
    includeDates.unshift(firstMeasurement.occurredAt)
  }

  return { includeDates }
}

const NAMESPACE = 'scans'

const submitScanEffectName = `${NAMESPACE}/submit`
const submitBluetoothScanEffectName = `${NAMESPACE}/submitBluetooth`

const isHealthKitSyncDisabled = function* (_: any, { call, select }: any) {
  const isImpersonating: boolean = yield call(User.isImpersonating)
  const isEnabled: boolean = yield select(healthKitSyncStatusSelector)
  const healthKitSync = Device.hasHealthKit && !isImpersonating && isEnabled

  return !healthKitSync
}

const createScanReducers = [
  { name: 'fetchListInfinite', dataPath: 'measurements', metadata: { isRefresh: false } },
  {
    name: 'syncHealthKit',
    unless: isHealthKitSyncDisabled,
  },
  {
    name: 'app/updateAppState',
    payload: () => ({
      lastScanned: moment().toISOString(),
    }),
  },
  {
    name: 'sensor/updateSensor',
    payload: (response: Scan) => response.sensor,
  },
  {
    // hide sensor after scanning expired one
    name: 'users/fetch',
    unless: (response: Scan) => response.sensor && response.sensor.status !== SensorStatus.Expired,
  },
  { name: 'notifications/fetch' },
]

const createScanCacheReducers = [
  { name: 'cacheClear', payload: { matchName: 'scans/fetch' } },
  {
    name: 'events/cacheClear',
    payload: { ...scansSubmitCacheClearPayload, matchName: 'events/fetch' },
  },
  { name: 'insights/cacheClear', payload: scansSubmitCacheClearPayload },
  { name: 'app/cacheClear', payload: scansSubmitCacheClearPayload },
]

export default class Measurements {
  namespace = NAMESPACE

  state = {
    ...Model.defaultState,
    measurements: [],
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ALL_SCANS,
      dataPath: 'allMeasurements',
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('scans/fetch', variables),
      reducers: [
        {
          name: 'fetchListInfinite',
          dataPath: 'measurements',
          transform,
        },
        {
          name: 'syncHealthKit',
          unless: isHealthKitSyncDisabled,
        },
      ],
    }),

    submitBluetooth: Model.buildEffect({
      name: submitBluetoothScanEffectName,
      query: CREATE_BLUETOOTH_SCAN,
      dataPath: 'createBluetoothScan',
      reducers: createScanReducers,
      cacheReducers: createScanCacheReducers,
    }),

    submit: Model.buildEffect({
      name: submitScanEffectName,
      query: CREATE_SCAN,
      dataPath: 'createScan',
      reducers: [
        ...createScanReducers,
        {
          name: 'app/changeDateRange',
          payload: (response: Scan) => {
            // allow for at least 1 new reading before switching calendar days
            let date = moment(response.createdAt).format(DATE_FORMAT)
            if (moment(response.createdAt).diff(moment(date), 'minutes') < 20) {
              date = moment(response.createdAt).subtract(1, 'd').format(DATE_FORMAT)
            }
            return { startDate: date, endDate: date }
          },
        },
      ],
      cacheReducers: createScanCacheReducers,
    }),

    activate: Model.buildEffect({
      name: `${this.namespace}/activate`,
      query: CREATE_ACTIVATION,
      dataPath: 'createActivation',
      reducers: [{ name: 'sensor/updateSensor' }],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    fetchListInfinite: commonReducers.fetchListInfinite,

    syncHealthKit: this.healthKitReducer,
  }

  retryIfOffline = [submitScanEffectName]

  // Not a true reducer, just uploads data to HealthKits
  //
  healthKitReducer(state: RootStoreState, { payload }: { payload: Scan }) {
    const { measurements } = payload
    const samples = measurements.filter((x) => x.type === 'Glucose')

    HealthKit.saveGlucoseSamples(samples)

    return state
  }
}
