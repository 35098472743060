import { createMigrate, PersistConfig, Storage } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import { persistStore, persistReducer } from 'redux-persist'
import { Action, Reducer } from 'redux'
import { Storage as MMKVStorage } from '@src/utils'
import { migrations } from './migrations'

const persistStorage: Storage = {
  setItem: (key, value) => {
    MMKVStorage.set(key, value)
    return Promise.resolve(true)
  },
  getItem: (key) => {
    const value = MMKVStorage.get(key)
    return Promise.resolve(value)
  },
  removeItem: (key) => {
    MMKVStorage.remove(key)
    return Promise.resolve()
  },
}

const persistConfig: PersistConfig<any> = {
  timeout: 0,
  key: 'root',
  version: 46,
  blacklist: ['loading'],
  stateReconciler: autoMergeLevel2,
  storage: persistStorage,
  migrate: createMigrate(migrations, { debug: true }),
}

const persistEnhancer = (createStore: any) => (
  reducer: Reducer<unknown, Action<any>>,
  initialState: any,
) => {
  const store = createStore(persistReducer(persistConfig, reducer), initialState)
  const persistor = persistStore(store)
  return {
    ...store,
    persistor,
  }
}

export default persistEnhancer
