import React from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@src/components/base'
import { preferredHeroChartSelector } from '@src/selectors/dailyActions'
import { IconTooltip } from '@src/components'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { HeroChartType } from '../models/dailyActions.types'
import { ChartTypePreviewCard } from './ChartTypePreviewCard'

export const ChartTypeSelectorSection = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const preferredHeroChart = useSelector(preferredHeroChartSelector)
  const showCgmUpsell = !User.hasFeature(MobileAppFeature.ViewGlucoseChart)

  const handleCardPress = (chartType: HeroChartType) => {
    dispatch({
      type: 'dailyActions/setPreferredHeroChart',
      payload: { chartType },
    })
  }

  const onUnlockCgmPress = () => {
    navigation.navigate('Subscriptions')
  }

  const message =
    'Unlock personalized glucose insights \n with a CGM. Get real-time data \n to take control of your health!'

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text type="large" bold>
          Chart Style
        </Text>
        {showCgmUpsell && (
          <View style={styles.upsellContainer}>
            <IconTooltip
              iconName="info"
              placement="bottom"
              message={message}
              accessibilityLabel="Upsell CGM Info"
            />
            <Button
              size="small"
              type="primary"
              onPress={onUnlockCgmPress}
              accessibilityLabel="Upsell CGM Button"
            >
              Unlock
            </Button>
          </View>
        )}
      </View>
      <View style={styles.chartStyleSelectorContainer}>
        <ChartTypePreviewCard
          key={HeroChartType.GlucoseChart}
          title="Glucose Chart"
          content="See how your levels have trended over the day, then tap for details."
          onPress={() => handleCardPress(HeroChartType.GlucoseChart)}
          checked={preferredHeroChart === HeroChartType.GlucoseChart}
          chartType={HeroChartType.GlucoseChart}
        />
        <ChartTypePreviewCard
          key={HeroChartType.DialChart}
          title="Dial Chart"
          content="Focus on your current glucose level, then tap for more details."
          onPress={() => handleCardPress(HeroChartType.DialChart)}
          checked={preferredHeroChart === HeroChartType.DialChart}
          chartType={HeroChartType.DialChart}
        />
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
  },
  headerContainer: {
    flexDirection: 'row',
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chartStyleSelectorContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
  },
  upsellContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
