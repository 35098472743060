import { Model } from '@src/models'
import { SortType } from '@src/models/app.types'

export const EVENTS_DEFAULT_SORT_STATE = {
  orderBy: 'occurred_at',
  order: 'descending' as SortType,
  types: {},
  query: '',
  startDate: '',
  endDate: '',
  startHour: 0,
  endHour: 24,
}

export const EVENTS_DEFAULT_STATE = {
  ...Model.defaultState,
  backup: null,
  sort: EVENTS_DEFAULT_SORT_STATE,
  events: [],
}
