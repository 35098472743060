import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { markModalAsShown, shouldShowModalOnce } from '@src/hooks/useOneTimeVisitedState'
import Storage from '@src/utils/storage'
import {
  EhrAppointmentAttendanceChargeKind,
  EhrAppointmentKind,
  EhrAppointmentStateKind,
  InsuranceBerryStreetAppointmentChargeType,
  InsuranceBerryStreetAppointmentStatus,
  InsuranceBerryStreetAppointmentType,
} from '@src/types'
import { User } from '@src/utils'
import { useGetRequiredPolicyConsents } from '@src/screens/PolicyConsents/hooks/useGetRequiredPolicyConsents'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import {
  insurancePolicySelector,
  latestUserAppointmentEarlierAvailableSlotsSelector,
  latestUserAppointmentSelector,
  latestUserEhrAppointmentEarlierAvailableSlotsSelector,
  latestUserEhrAppointmentSelector,
} from '../models/nutritionistHub.selectors'

const defaultNoShow = {
  shouldShow: () => false,
  markAsShown: () => {},
  getRoute: () => ({ name: '', params: undefined }),
  wasShown: () => false,
}

const useDefaultStateInteractions = (modalKey: string) => {
  const shouldShow = useCallback(() => shouldShowModalOnce(modalKey), [modalKey])

  const markAsShown = useCallback(() => markModalAsShown(modalKey), [modalKey])

  return { shouldShow, markAsShown }
}

const useClaimFreeVideoCallPromptInteractions = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const latestAppointment = useSelector(latestUserAppointmentSelector)

  const modalKey = `${Storage.CLAIM_FREE_VIDEO_CALL_MODAL_VISITED_KEY}_${insurancePolicy?.id}`

  const { shouldShow, markAsShown } = useDefaultStateInteractions(modalKey)

  const getRoute = useCallback(() => {
    if (!latestAppointment?.id) {
      return defaultNoShow.getRoute()
    }

    return {
      name: 'ClaimFreeVideoCallModal',
      params: {
        appointmentId: latestAppointment.id,
        appointmentType:
          latestAppointment.appointmentType || InsuranceBerryStreetAppointmentType.NoneCovered,
      },
    }
  }, [latestAppointment?.appointmentType, latestAppointment?.id])

  const wasShown = useCallback(() => !shouldShow(), [shouldShow])

  if (
    !insurancePolicy ||
    !latestAppointment ||
    latestAppointment.status !== InsuranceBerryStreetAppointmentStatus.SchedulingPending ||
    latestAppointment.chargeType !== InsuranceBerryStreetAppointmentChargeType.Free
  ) {
    return defaultNoShow
  }

  return {
    shouldShow,
    markAsShown,
    getRoute,
    wasShown,
  }
}

const useInsurancePolicyRejectedPromptInteractions = () => {
  const {
    shouldShow: shouldShowFreeCallModal,
    wasShown: freeCallModalWasShown,
  } = useClaimFreeVideoCallPromptInteractions()
  const insurancePolicy = useSelector(insurancePolicySelector)

  const userWasGrantedFreeCall = shouldShowFreeCallModal() || freeCallModalWasShown()

  const modalKey = `${Storage.INSURANCE_POLICY_REJECTED_MODAL_VISITED_KEY}_${insurancePolicy?.id}`

  const { shouldShow, markAsShown } = useDefaultStateInteractions(modalKey)

  const getRoute = useCallback(() => {
    return {
      name: 'InsuranceNotEligibleModal',
    }
  }, [])

  if (!insurancePolicy || userWasGrantedFreeCall || !insurancePolicy?.declined) {
    return defaultNoShow
  }

  return {
    shouldShow,
    markAsShown,
    getRoute,
  }
}

const useEarlierSlotAvailablePromptInteractions = () => {
  const latestAppointment = useSelector(latestUserAppointmentSelector)
  const user = useSelector(userSelector)

  const earlierAvailableSlotsCheckFeatureEnabled = useFeatureFlag(
    Feature.VideoCallsEarlierAvailableSlotsCheck,
  )

  const earlierAvailableSlots = useSelector(latestUserAppointmentEarlierAvailableSlotsSelector)

  const earlierSlotsAvailable = earlierAvailableSlots.length > 0

  const modalKey = `${Storage.EARLIER_APPOINTMENT_SLOT_AVAILABLE_MODAL_VISITED_KEY}_${user?.id}`

  const { shouldShow, markAsShown } = useDefaultStateInteractions(modalKey)

  const getRoute = useCallback(() => {
    if (!latestAppointment?.id) {
      return defaultNoShow.getRoute()
    }

    return {
      name: 'EarlierSlotAvailableModal',
      params: {
        appointmentType: latestAppointment.appointmentType as InsuranceBerryStreetAppointmentType,
        appointmentId: latestAppointment.id,
        allowBackNavigation: true,
      },
    }
  }, [latestAppointment?.appointmentType, latestAppointment?.id])

  if (!latestAppointment || !earlierAvailableSlotsCheckFeatureEnabled || !earlierSlotsAvailable) {
    return defaultNoShow
  }

  return {
    shouldShow,
    markAsShown,
    getRoute,
  }
}

export const useNutritionistHubModals = () => {
  const navigation = useNavigation()

  const {
    shouldShow: shouldShowClaimFreeVideoCallPrompt,
    markAsShown: markClaimFreeVideoPrompt,
    getRoute: getClaimFreeVideoPromptRoute,
  } = useClaimFreeVideoCallPromptInteractions()

  const {
    shouldShow: shouldShowInsuranceRejectedPrompt,
    markAsShown: markInsuranceRejectedPrompt,
    getRoute: getInsuranceRejectedPromptRoute,
  } = useInsurancePolicyRejectedPromptInteractions()

  const {
    shouldShow: shouldShowEarlierSlotAvailablePrompt,
    markAsShown: markEarlierSlotAvailablePromptAsShown,
    getRoute: getEarlierSlotAvailablePromptRoute,
  } = useEarlierSlotAvailablePromptInteractions()

  const { getRequiredPolicyConsents } = useGetRequiredPolicyConsents()

  const handleModals = useCallback(async () => {
    if (User.isImpersonating()) {
      // using the useImpersonationContext leads to a race condition that shows the welcome tour during impersonation
      return
    }

    const showClaimFreeVideoCallPrompt = shouldShowClaimFreeVideoCallPrompt()
    const showInsuranceRejectedPrompt = shouldShowInsuranceRejectedPrompt()
    const showEarlierSlotAvailablePrompt = shouldShowEarlierSlotAvailablePrompt()

    const routes: { name: string; params?: any }[] = []

    if (showClaimFreeVideoCallPrompt) {
      routes.push(getClaimFreeVideoPromptRoute())
      markClaimFreeVideoPrompt()
    }

    if (showInsuranceRejectedPrompt) {
      routes.push(getInsuranceRejectedPromptRoute())
      markInsuranceRejectedPrompt()
    }

    if (showEarlierSlotAvailablePrompt) {
      routes.push(getEarlierSlotAvailablePromptRoute())
      markEarlierSlotAvailablePromptAsShown()
    }

    // Routes will be pushed in reverse order and we always want the consent modals to show first if present.
    // So, this should be the last item added to the stack.
    //
    const requiredPolicies = await getRequiredPolicyConsents()
    if (requiredPolicies.length > 0) {
      routes.push({ name: 'RequiredPolicyConsent' })
    }

    routes.forEach(({ name, params }) => {
      navigation.navigate(name as any, params)
    })
  }, [
    shouldShowClaimFreeVideoCallPrompt,
    shouldShowInsuranceRejectedPrompt,
    shouldShowEarlierSlotAvailablePrompt,
    getRequiredPolicyConsents,
    getClaimFreeVideoPromptRoute,
    markClaimFreeVideoPrompt,
    getInsuranceRejectedPromptRoute,
    markInsuranceRejectedPrompt,
    getEarlierSlotAvailablePromptRoute,
    markEarlierSlotAvailablePromptAsShown,
    navigation,
  ])

  return { handleModals }
}

/**
 * EHR Domain
 */

const useClaimFreeAppointmentPromptInteractions = () => {
  const insurancePolicy = useSelector(insurancePolicySelector)
  const latestAppointment = useSelector(latestUserEhrAppointmentSelector)

  const modalKey = `${Storage.CLAIM_FREE_VIDEO_CALL_MODAL_VISITED_KEY}_${insurancePolicy?.id}`

  const { shouldShow, markAsShown } = useDefaultStateInteractions(modalKey)

  const getRoute = useCallback(() => {
    if (!latestAppointment?.id) {
      return defaultNoShow.getRoute()
    }

    return {
      name: 'ClaimFreeVideoCallModal',
      params: {
        appointmentId: latestAppointment.id,
        appointmentKind: latestAppointment.kind || EhrAppointmentKind.NoneCovered,
      },
    }
  }, [latestAppointment?.kind, latestAppointment?.id])

  const wasShown = useCallback(() => !shouldShow(), [shouldShow])
  console.log({ latestAppointment })
  const attendance = latestAppointment?.attendances[0]

  if (
    !insurancePolicy ||
    !latestAppointment ||
    latestAppointment.lastState.kind !== EhrAppointmentStateKind.PendingSchedule ||
    attendance?.chargeKind !== EhrAppointmentAttendanceChargeKind.BsFree
  ) {
    return defaultNoShow
  }

  return {
    shouldShow,
    markAsShown,
    getRoute,
    wasShown,
  }
}

const useEhrInsurancePolicyRejectedPromptInteractions = () => {
  const {
    shouldShow: shouldShowFreeCallModal,
    wasShown: freeCallModalWasShown,
  } = useClaimFreeAppointmentPromptInteractions()
  const insurancePolicy = useSelector(insurancePolicySelector)

  const userWasGrantedFreeCall = shouldShowFreeCallModal() || freeCallModalWasShown()

  const modalKey = `${Storage.INSURANCE_POLICY_REJECTED_MODAL_VISITED_KEY}_${insurancePolicy?.id}`

  const { shouldShow, markAsShown } = useDefaultStateInteractions(modalKey)

  const getRoute = useCallback(() => {
    return {
      name: 'InsuranceNotEligibleModal',
    }
  }, [])

  if (!insurancePolicy || userWasGrantedFreeCall || !insurancePolicy?.declined) {
    return defaultNoShow
  }

  return {
    shouldShow,
    markAsShown,
    getRoute,
  }
}

const useEhrEarlierSlotAvailablePromptInteractions = () => {
  const latestAppointment = useSelector(latestUserEhrAppointmentSelector)
  const user = useSelector(userSelector)

  const earlierAvailableSlotsCheckFeatureEnabled = useFeatureFlag(
    Feature.VideoCallsEarlierAvailableSlotsCheck,
  )

  const earlierAvailableSlots = useSelector(latestUserEhrAppointmentEarlierAvailableSlotsSelector)

  const earlierSlotsAvailable = earlierAvailableSlots.length > 0

  const modalKey = `${Storage.EARLIER_APPOINTMENT_SLOT_AVAILABLE_MODAL_VISITED_KEY}_${user?.id}`

  const { shouldShow, markAsShown } = useDefaultStateInteractions(modalKey)

  const getRoute = useCallback(() => {
    if (!latestAppointment?.id) {
      return defaultNoShow.getRoute()
    }

    return {
      name: 'EarlierSlotAvailableModal',
      params: {
        appointmentKind: latestAppointment.kind as EhrAppointmentKind,
        appointmentId: latestAppointment.id,
        allowBackNavigation: true,
      },
    }
  }, [latestAppointment?.kind, latestAppointment?.id])

  if (!latestAppointment || !earlierAvailableSlotsCheckFeatureEnabled || !earlierSlotsAvailable) {
    return defaultNoShow
  }

  return {
    shouldShow,
    markAsShown,
    getRoute,
  }
}

export const useEhrNutritionistHubModals = () => {
  const navigation = useNavigation()

  const {
    shouldShow: shouldShowClaimFreeVideoCallPrompt,
    markAsShown: markClaimFreeVideoPrompt,
    getRoute: getClaimFreeVideoPromptRoute,
  } = useClaimFreeAppointmentPromptInteractions()

  const {
    shouldShow: shouldShowInsuranceRejectedPrompt,
    markAsShown: markInsuranceRejectedPrompt,
    getRoute: getInsuranceRejectedPromptRoute,
  } = useEhrInsurancePolicyRejectedPromptInteractions()

  const {
    shouldShow: shouldShowEarlierSlotAvailablePrompt,
    markAsShown: markEarlierSlotAvailablePromptAsShown,
    getRoute: getEarlierSlotAvailablePromptRoute,
  } = useEhrEarlierSlotAvailablePromptInteractions()

  const { getRequiredPolicyConsents } = useGetRequiredPolicyConsents()

  const handleModals = useCallback(async () => {
    if (User.isImpersonating()) {
      // using the useImpersonationContext leads to a race condition that shows the welcome tour during impersonation
      return
    }

    const showClaimFreeVideoCallPrompt = shouldShowClaimFreeVideoCallPrompt()
    const showInsuranceRejectedPrompt = shouldShowInsuranceRejectedPrompt()
    const showEarlierSlotAvailablePrompt = shouldShowEarlierSlotAvailablePrompt()

    const routes: { name: string; params?: any }[] = []

    if (showClaimFreeVideoCallPrompt) {
      routes.push(getClaimFreeVideoPromptRoute())
      markClaimFreeVideoPrompt()
    }

    if (showInsuranceRejectedPrompt) {
      routes.push(getInsuranceRejectedPromptRoute())
      markInsuranceRejectedPrompt()
    }

    if (showEarlierSlotAvailablePrompt) {
      routes.push(getEarlierSlotAvailablePromptRoute())
      markEarlierSlotAvailablePromptAsShown()
    }

    // Routes will be pushed in reverse order and we always want the consent modals to show first if present.
    // So, this should be the last item added to the stack.
    //
    const requiredPolicies = await getRequiredPolicyConsents()
    if (requiredPolicies.length > 0) {
      routes.push({ name: 'RequiredPolicyConsent' })
    }

    routes.forEach(({ name, params }) => {
      navigation.navigate(name as any, params)
    })
  }, [
    shouldShowClaimFreeVideoCallPrompt,
    shouldShowInsuranceRejectedPrompt,
    shouldShowEarlierSlotAvailablePrompt,
    getRequiredPolicyConsents,
    getClaimFreeVideoPromptRoute,
    markClaimFreeVideoPrompt,
    getInsuranceRejectedPromptRoute,
    markInsuranceRejectedPrompt,
    getEarlierSlotAvailablePromptRoute,
    markEarlierSlotAvailablePromptAsShown,
    navigation,
  ])

  return { handleModals }
}
