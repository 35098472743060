import { gql } from '@apollo/client'
import { EHR_APPOINTMENT_FIELDS } from '@src/graphql/fragments/ehrAppointment'

export const EHR_APPOINTMENTS = gql`
  ${EHR_APPOINTMENT_FIELDS}
  query ehrAppointments(
    $sorts: [EhrAppointmentSort!]
    $filterKind: FilterAppointmentsKind
    $category: EhrAppointmentCategory
  ) {
    ehrAppointments(sorts: $sorts, filterKind: $filterKind, category: $category) {
      appointments {
        ...EhrAppointmentFields
      }
    }
  }
`
