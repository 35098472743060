import {
  AppointmentType,
  ClientConfigEhrAppointmentKind,
  EhrAppointment,
  EhrAppointmentCategory,
  EhrAppointmentKind,
  EhrAppointmentStateKind,
  InsuranceBerryStreetAppointmentChargeType,
  InsuranceBerryStreetAppointmentMeetingStatus,
  InsuranceBerryStreetAppointmentStatus,
  InsuranceBerryStreetAppointmentType,
  InsurancePolicy,
  LatestUserAppointment,
  User,
} from '@src/types'

import { DEFAULT_VIDEO_CALL_INFO } from './constants'

export interface VideoCallDetails extends LatestUserAppointment {
  id: string
  title: string
  date: string | null
  meetingStatus?: InsuranceBerryStreetAppointmentMeetingStatus | null
  appointmentType: InsuranceBerryStreetAppointmentType
  isSchedulingPending: boolean
  isCallConfirmed: boolean
  isCallInReview: boolean
  isInsuranceCallCancelled: boolean
  nutritionistName?: string | null
  addToGoogleCalendarLink?: string | null
  unauthenticatedIcsLink?: string | null
  chargeType?: InsuranceBerryStreetAppointmentChargeType | null
}

export interface AppointmentDetails extends EhrAppointment {
  id: string
  title: string
  isSchedulingPending: boolean
  isCallConfirmed: boolean
  isCallInReview: boolean
  isCallCancelled: boolean
  isCallOccurred: boolean
  isInsuranceCallCancelled: boolean
  addToGoogleCalendarLink?: string | null
  unauthenticatedIcsLink?: string | null
}

export const isCallConfirmed = (
  appointmentStatus: InsuranceBerryStreetAppointmentStatus,
): boolean => appointmentStatus === InsuranceBerryStreetAppointmentStatus.ReviewCompleted

export const getVideoCallDetails = (appointment: LatestUserAppointment): VideoCallDetails => {
  return {
    ...appointment,
    date: appointment.date || null,
    appointmentType: appointment.appointmentType || InsuranceBerryStreetAppointmentType.Initial_60,
    title: appointment.title || DEFAULT_VIDEO_CALL_INFO.title,
    isSchedulingPending:
      appointment.status === InsuranceBerryStreetAppointmentStatus.SchedulingPending,
    isCallConfirmed: isCallConfirmed(appointment.status),
    isCallInReview: appointment.status === InsuranceBerryStreetAppointmentStatus.InReview,
    isInsuranceCallCancelled:
      appointment.meetingStatus === InsuranceBerryStreetAppointmentMeetingStatus.Cancelled &&
      appointment.appointmentType !== InsuranceBerryStreetAppointmentType.NoneCovered,
  }
}

export const getAppointmentDetails = (appointment: EhrAppointment): AppointmentDetails => {
  const lastState = appointment.lastState

  return {
    ...appointment,
    title: appointment.title || DEFAULT_VIDEO_CALL_INFO.title,
    isSchedulingPending: lastState.kind === EhrAppointmentStateKind.PendingSchedule,
    isCallConfirmed: lastState.kind === EhrAppointmentStateKind.Scheduled,
    isCallInReview: lastState.kind === EhrAppointmentStateKind.InReview,
    isCallCancelled: lastState.kind === EhrAppointmentStateKind.Cancelled,
    isCallOccurred: lastState.kind === EhrAppointmentStateKind.Occurred,
    isInsuranceCallCancelled:
      lastState.kind === EhrAppointmentStateKind.Occurred &&
      appointment.category === EhrAppointmentCategory.Coaching &&
      appointment.kind !== EhrAppointmentKind.NoneCovered,
  }
}

export const insuranceSubmittedWithoutCall = (
  insurancePolicy: InsurancePolicy | null,
  videoCallDetails: VideoCallDetails | AppointmentDetails,
): boolean => {
  return !!insurancePolicy && !videoCallDetails.id && !insurancePolicy.declined
}

export const isFollowupSchedulingAvailable = (videoCallDetails: VideoCallDetails): boolean => {
  if (isCallScheduled(videoCallDetails)) {
    return false
  }

  return videoCallDetails.followupSchedulingAvailable
}

export const isEhrFollowupSchedulingAvailable = (
  appointment: AppointmentDetails,
  user: User,
): boolean => {
  if (isAppointmentScheduled(appointment)) {
    return false
  }

  return user.followupSchedulingAvailable
}

export const isUnusedCallsInfoAvailable = (insurancePolicy: InsurancePolicy | null): boolean => {
  if (!insurancePolicy) {
    return false
  }

  return insurancePolicy.unitsLeft !== null && insurancePolicy.unitsLeft !== undefined
}

export const isBookingAvailable = (
  insurancePolicy: InsurancePolicy | null,
  videoCallDetails: VideoCallDetails,
) => {
  return (
    insurancePolicy?.hasUnusedCalls ||
    videoCallDetails.isSchedulingPending ||
    insuranceSubmittedWithoutCall(insurancePolicy, videoCallDetails)
  )
}

export const isEhrBookingAvailable = (
  insurancePolicy: InsurancePolicy | null,
  appointmentDetails: AppointmentDetails,
) => {
  return (
    insurancePolicy?.hasUnusedCalls ||
    appointmentDetails.isSchedulingPending ||
    insuranceSubmittedWithoutCall(insurancePolicy, appointmentDetails)
  )
}

// when meeting status is present, it means the call is either occurred or
// won't happen(cancelled, no_show or rescheduled)
export const isCallScheduled = (videoCallDetails: VideoCallDetails): boolean =>
  (videoCallDetails.isCallConfirmed || videoCallDetails.isCallInReview) &&
  !videoCallDetails.meetingStatus

export const isAppointmentScheduled = (appointmentDetails: AppointmentDetails): boolean =>
  appointmentDetails.isCallConfirmed || appointmentDetails.isCallInReview

const appointmentTypeTitle = (
  appointmentTypes: AppointmentType[],
  appointmentType?: InsuranceBerryStreetAppointmentType,
) => {
  const type = appointmentTypes.find((type) => type.key === appointmentType)
  return type?.title || DEFAULT_VIDEO_CALL_INFO.title
}

const appointmentKindTitle = (
  appointmentKinds: ClientConfigEhrAppointmentKind[],
  appointmentKind?: EhrAppointmentKind,
) => {
  const type = appointmentKinds.find((type) => type.key === appointmentKind)
  return type?.title || DEFAULT_VIDEO_CALL_INFO.title
}

export const getSchedulingConfig = (
  videoCallDetails: VideoCallDetails,
  followupCallNotScheduled: boolean,
  initialAppointmentType: InsuranceBerryStreetAppointmentType | undefined,
  appointmentTypes: AppointmentType[],
) => {
  if (videoCallDetails.isSchedulingPending) {
    return {
      navigationParams: {
        appointmentType: videoCallDetails.appointmentType,
        pendingAppointmentId: videoCallDetails.id,
        allowBackNavigation: true,
      },
      title: videoCallDetails.title,
    }
  }

  if (followupCallNotScheduled) {
    return {
      navigationParams: {
        appointmentType: InsuranceBerryStreetAppointmentType.Followup,
        allowBackNavigation: true,
      },
      title: appointmentTypeTitle(appointmentTypes, InsuranceBerryStreetAppointmentType.Followup),
    }
  }

  if (videoCallDetails.isInsuranceCallCancelled) {
    return {
      navigationParams: {
        appointmentType: videoCallDetails.appointmentType,
        allowBackNavigation: true,
      },
      title: videoCallDetails.title,
    }
  }

  return {
    navigationParams: {
      appointmentType: initialAppointmentType || InsuranceBerryStreetAppointmentType.Initial_60,
      allowBackNavigation: true,
    },
    title: appointmentTypeTitle(appointmentTypes, initialAppointmentType),
  }
}

export const getEhrSchedulingConfig = (
  appointmentDetails: AppointmentDetails,
  followupCallNotScheduled: boolean,
  initialAppointmentKind: EhrAppointmentKind | undefined,
  appointmentKinds: ClientConfigEhrAppointmentKind[],
) => {
  if (appointmentDetails.isSchedulingPending) {
    return {
      navigationParams: {
        appointmentKind: appointmentDetails.kind,
        pendingAppointmentId: appointmentDetails.id,
        allowBackNavigation: true,
      },
      title: appointmentDetails.title,
    }
  }

  if (followupCallNotScheduled) {
    return {
      navigationParams: {
        appointmentKind: EhrAppointmentKind.Followup,
        allowBackNavigation: true,
      },
      title: appointmentKindTitle(appointmentKinds, EhrAppointmentKind.Followup),
    }
  }

  if (appointmentDetails.isInsuranceCallCancelled) {
    return {
      navigationParams: {
        appointmentKind: appointmentDetails.kind,
        allowBackNavigation: true,
      },
      title: appointmentDetails.title,
    }
  }

  return {
    navigationParams: {
      appointmentKind: initialAppointmentKind || EhrAppointmentKind.Initial_60,
      allowBackNavigation: true,
    },
    title: appointmentKindTitle(appointmentKinds, initialAppointmentKind),
  }
}
