import { createSelector } from 'reselect'
import { eventsFiltersSelector, mealsFiltersSelector } from './app'
import { eventsStoreStateSelector } from './events'

const filtersSelector = createSelector(
  [eventsFiltersSelector, mealsFiltersSelector],
  (events, meals) => ({
    events,
    meals,
  }),
)

export const listFilterSelector = createSelector(
  [filtersSelector, eventsStoreStateSelector],
  (filters, events) => ({
    filters,
    events,
  }),
)
