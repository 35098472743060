import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

export const dailyNutritionStoreStateSelector = (state: RootStoreState) => state.dailyNutrition

export const dailyNutritionSelector = createSelector(
  dailyNutritionStoreStateSelector,
  (dailyNutrition) => dailyNutrition.nutrition,
)

export const dailyNutritionStatisticsSelector = createSelector(
  dailyNutritionStoreStateSelector,
  (dailyNutrition) => dailyNutrition.statistics,
)

export const dailyNutritionScoreSelector = createSelector(
  dailyNutritionStoreStateSelector,
  (dailyNutrition) => dailyNutrition.score,
)
