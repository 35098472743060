import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppRouteProp, AppStackParamList } from '@src/navigation/types'
import { Capture, Share } from '@utils'
import { NavigationContainer } from '@src/screens/Common/containers'
import { LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { ShareableViews } from './constants'
import MealDetailShareableView from './MealDetailShareableView'
import ItemShareableView from './ItemShareableView'

export const ShareViewModal = () => {
  const styles = useStyleSheet(themedStyle)
  const shareableViewRef = useRef() as MutableRefObject<View>
  const route = useRoute<AppRouteProp<'ShareViewModal'>>()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { shareableViewName, item } = route.params

  const [loaded, setLoaded] = useState(false)
  const [transitionEnd, setTransitionEnd] = useState(false)

  useEffect(() => {
    const subscription = navigation.addListener('transitionEnd', () => {
      setTransitionEnd(true)
    })
    return subscription
  }, [navigation])

  const closedRef = useRef(false)

  const goBack = useCallback(() => {
    if (closedRef.current) {
      return
    }

    closedRef.current = true
    navigation.goBack()
  }, [navigation])

  useEffect(() => {
    const share = () => {
      Capture.captureView(shareableViewRef, ({ uri }: { uri: string | null }) => {
        if (uri) {
          Share.share({ url: uri, handleResult: goBack })
        }
      })
    }

    if (loaded && transitionEnd && !closedRef.current) {
      share()
    }
  }, [loaded, transitionEnd, goBack])

  const onLoad = () => {
    setLoaded(true)
  }

  const renderShareableView = () => {
    if (shareableViewName === ShareableViews.ActivityGlucoseGraph) {
      return <ItemShareableView shareableViewName={shareableViewName} item={item} onLoad={onLoad} />
    }

    if (shareableViewName === ShareableViews.MealDetail) {
      return <MealDetailShareableView item={item as Meal} onLoad={onLoad} />
    }

    return null
  }

  return (
    <NavigationContainer
      style={styles.container}
      title="Share"
      leftIcon={{ pack: 'eva', name: 'close' }}
      goBack={goBack}
    >
      <View style={styles.content} ref={shareableViewRef} collapsable={false}>
        {renderShareableView()}
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  content: {
    paddingBottom: 12,
  },
})
