import React from 'react'
import { ScrollView } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { NutritionSummaryCard } from '@components/NutritionSummaryCard/NutritionSummaryCard'
import { GlucoseSummaryCard } from '../GlucoseSummary/GlucoseSummaryCard'
import { GlucoseScoreCard } from '../GlucoseScore/GlucoseScoreCard'

export const HighlightsSection = () => {
  const styles = useStyleSheet(themedStyles)
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      horizontal
      showsHorizontalScrollIndicator={false}
    >
      {isCGMFeatureAvailable && (
        <>
          <GlucoseSummaryCard />
          <GlucoseScoreCard />
        </>
      )}

      <NutritionSummaryCard />
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginTop: 8,
    height: 136,
  },
  contentContainer: {
    flexDirection: 'row',
    marginRight: 8,
    paddingHorizontal: 16,
  },
})
