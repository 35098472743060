import { Calendar } from '@src/models/app.types'
import { Caching } from '@src/utils'
import { calendarDateSelector } from '@src/selectors/app'
import { Model } from '..'
import { defaultDailyChartsState } from './constants'
import ALL_CHARTS from './graphql/allCharts'
import reducers from './reducers'

export default class DailyCharts {
  namespace = 'dailyCharts'

  state = {
    ...Model.defaultState,
    ...defaultDailyChartsState,
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetchCharts`,
      query: ALL_CHARTS,
      *variables(payload: any, { select }: any) {
        const { startDate, endDate }: Partial<Calendar> = yield select(calendarDateSelector)
        const payloadFilter = payload?.filter || {}
        return {
          filter: {
            types: [{ key: 'timeline', value: [] }],
            ...payloadFilter,
            startDate: payloadFilter.startDate || startDate,
            endDate: payloadFilter.endDate || endDate,
          },
        }
      },
      dataPath: 'allCharts',
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('dailyCharts/fetch', variables),
      reducers: [{ name: 'fetchChartsData' }],
    }),
  }

  reducers = reducers
}
