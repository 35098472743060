import { gql } from '@apollo/client'

export const EHR_APPOINTMENT_ATTENDANCE_FIELDS = gql`
  fragment EhrAppointmentAttendanceFields on EhrAppointmentAttendance {
    id
    user {
      id
    }
    chargeKind
    state
  }
`
