import React from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { capitalize } from 'lodash'
import { ICON_NAME_BY_MEAL_TYPE, LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { ListItemProps } from '@src/components/list/ListItemFactory'
import { useMenuHandling } from '@src/components/list/MenuHandler'
import { ListItemWithScore } from '@src/components/list/common/ListItemWithScore'
import { formatScore } from '@src/components/nutritionCard/utils'
import { MenuInfoItem } from '@src/components/list/common/Menu'
import { copyMeal, getDraftMeal } from '@screens/Meals/utils'
import { Feature, useFeatureFlag } from '@src/components'
import { MealSource } from '@src/types'

const TIME_FORMAT = 'h:mm a'

type MealListItemProps = ListItemProps<Meal>

export const MealListItem = ({ item, highlighted, canJumpToDate }: MealListItemProps) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { defaultMenuItems, onItemEdit, onJumpToDate } = useMenuHandling(item, canJumpToDate)
  const isMealExperimentationEnabled = useFeatureFlag(Feature.MealExperimentation)

  const { kind, statistics, avatar, occurredAt, description, ingredients, fake } = item

  const onMealEdit = () => {
    navigation.navigate('EditMeal', { id: item.id })
  }

  const onMealCopy = () => {
    dispatch({
      type: 'meals/saveDraft',
      payload: getDraftMeal(copyMeal(item)),
    })

    navigation.navigate('AddMeal')
  }

  const onMealCompare = () => {
    navigation.navigate('CompareMeals', { initialMealIds: item.id })
  }

  const compareMenuItem =
    isMealExperimentationEnabled && item.statistics?.glucoseScore
      ? [{ title: 'Compare', onPress: onMealCompare, isEnabled: true }]
      : []

  const menuItems: MenuInfoItem[] = [
    { title: 'Edit', onPress: onMealEdit, isEnabled: true },
    { title: 'Copy', onPress: onMealCopy, isEnabled: true },
    ...compareMenuItem,
    ...defaultMenuItems,
  ]

  const iconName = ICON_NAME_BY_MEAL_TYPE[kind]

  return (
    <ListItemWithScore
      title={capitalize(kind)}
      subtitle={description || ingredients.map((ingredient) => ingredient.description).join(', ')}
      info={moment(occurredAt).format(TIME_FORMAT)}
      imageUri={avatar}
      placeholderImageUri={avatar}
      iconName={iconName}
      score={formatScore(statistics?.glucoseScore)}
      menuItems={
        fake && canJumpToDate
          ? [{ title: 'Jump to Date', onPress: onJumpToDate, isEnabled: true }]
          : menuItems
      }
      onItemPress={fake ? undefined : onItemEdit}
      numberOfLines={1}
      highlighted={highlighted}
      warningTooltipMessage={
        item.source === MealSource.MyFitnessPal && item.generatedOccurredAt
          ? 'This meal was imported, \nplease check if time is correct.'
          : undefined
      }
    />
  )
}
