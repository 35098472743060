import { Model } from '@models'
import reducers from '../reducers'
import ALL_FAVORITE_ACTIVITIES from '../graphql/allFavoriteActivities'
import CREATE_ACTIVITY from '../graphql/createActivity'
import DELETE_ACTIVITY from '../graphql/deleteActivity'

const activitiesCacheClearPayload = (response: { startedAt: string; endedAt: string }) => ({
  includeDates: [response.startedAt, response.endedAt],
})

export default class Activities {
  namespace = 'activities'

  state = {
    ...Model.defaultState,

    favorites: [],
    recentActivities: [],
  }

  effects = {
    submit: Model.buildEffect({
      name: `${this.namespace}/submit`,
      optimistic: true,
      query: CREATE_ACTIVITY,
      dataPath: 'createActivity',
      optimisticReducers: [
        { name: 'events/backup', storePath: 'events' },
        { name: 'events/appendOrReplaceList', storePath: 'events' },
      ],
      reducers: [
        { name: 'events/appendOrReplaceList', replaceFake: true, storePath: 'events' },
        {
          name: 'updateActivityFavoriteStatus',
          payload: (response: any) => ({ activity: response }),
        },
        {
          name: 'updateRecentActivities',
          payload: (response: any) => ({ ...response, recentItem: true }),
        },
        { name: 'notifications/fetch' },
      ],
      errorReducers: [{ name: 'events/restore', storePath: 'events' }],
      cacheReducers: [
        { name: 'events/cacheClear', payload: { matchName: 'events/fetch' } },
        {
          name: 'insights/cacheClear',
          payload: activitiesCacheClearPayload,
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'activities/fetchFavorites' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'activities/fetchRecentActivities' },
        },
      ],
    }),

    delete: Model.buildEffect({
      name: `${this.namespace}/delete`,
      optimistic: true,
      query: DELETE_ACTIVITY,
      dataPath: 'deleteActivity',
      variables: (payload: { id: string }) => ({ id: payload.id }),
      optimisticReducers: [
        { name: 'events/backup', storePath: 'events' },
        { name: 'events/deleteList', storePath: 'events' },
      ],
      reducers: [
        {
          name: 'updateActivityFavoriteStatus',
          payload: (response: any) => ({ activity: { ...response, favorite: false } }),
        },
        {
          name: 'updateRecentActivities',
          payload: (response: any) => ({ ...response, recentItem: false }),
        },
      ],
      errorReducers: [{ name: 'events/restore', storePath: 'events' }],
      cacheReducers: [
        { name: 'events/cacheClear', payload: { matchName: 'events/fetch' } },

        {
          name: 'insights/cacheClear',
          payload: activitiesCacheClearPayload,
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'activities/fetchFavorites' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'activities/fetchRecentActivities' },
        },
      ],
    }),

    fetchFavorites: Model.buildEffect({
      name: `${this.namespace}/fetchFavorites`,
      query: ALL_FAVORITE_ACTIVITIES,
      caching: true,
      dataPath: 'allActivities',
      reducers: [{ name: 'fetchList', storePath: () => 'favorites' }],
    }),

    fetchRecentActivities: Model.buildEffect({
      name: `${this.namespace}/fetchRecentActivities`,
      query: ALL_FAVORITE_ACTIVITIES,
      caching: true,
      dataPath: 'allActivities',
      reducers: [{ name: 'fetchList', storePath: () => 'recentActivities' }],
    }),

    updateFavorite: Model.buildEffect({
      name: `${this.namespace}/updateFavorite`,
      optimistic: true,
      query: CREATE_ACTIVITY,
      dataPath: 'createActivity',
      optimisticReducers: [
        {
          name: 'updateActivityFavoriteStatus',
          payload: (_: any, { effectPayload }: any) => ({ activity: effectPayload }),
        },
      ],
      reducers: [
        {
          name: 'updateActivityFavoriteStatus',
          payload: (response: any) => ({ activity: response }),
        },
        { name: 'events/updateListItem', storePath: 'events' },
      ],
      errorReducers: [
        {
          name: 'updateActivityFavoriteStatus',
          payload: (_: any, { effectPayload }: any) => ({
            activity: { ...effectPayload, favorite: !effectPayload.favorite },
          }),
        },
      ],
      cacheReducers: [
        { name: 'events/cacheClear', payload: { matchName: 'events/fetch' } },
        {
          name: 'cacheClear',
          payload: { matchName: 'activities/fetchFavorites' },
        },
        {
          name: 'cacheClear',
          payload: { matchName: 'activities/fetchRecentActivities' },
        },
      ],
    }),
  }

  reducers = reducers
}
