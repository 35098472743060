import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Debug } from '@config'
import { Intercom } from '@utils'
import { ChatbotIcon } from '@src/screens/Home/containers/ChatbotIcon'
import { MessageIcon } from '@src/screens/Home/containers/MessageIcon'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { userSelector } from '@src/selectors/app'
import { NutritionistHubScreen } from '../components/NutritionistHubScreen'
import { EhrNutritionistHubScreen } from '../components/EhrNutritionistHubScreen'

export const NutritionistHubContainer = () => {
  const rightAccessories: NavigationBarAccessory[] = []
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyle)
  const user = useSelector(userSelector)

  if (useFeatureFlag(Feature.Chatbot)) {
    rightAccessories.push({
      renderIconComponent: () => <ChatbotIcon />,
      onPress: () => navigation.navigate('Chat'),
      accessibilityLabel: 'Chat',
    })
  }

  const showMessages = Debug.shouldEnableIntercom()
  if (showMessages) {
    rightAccessories.push({
      renderIconComponent: () => <MessageIcon />,
      onPress: () => Intercom.showIntercomMessenger({ source: 'NutritionistHub' }),
      accessibilityLabel: 'Message',
    })
  }

  return (
    <AccountAvatarNavigationBar
      title="Nutritionist"
      rightAccessories={rightAccessories}
      style={styles.container}
    >
      {user?.migrationFromInsuranceEnabled ? (
        <EhrNutritionistHubScreen />
      ) : (
        <NutritionistHubScreen />
      )}
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
})
