import { gql } from '@apollo/client'
import { EHR_APPOINTMENT_FIELDS } from '@src/graphql/fragments/ehrAppointment'

export const FETCH_APPOINTMENT_INFO = gql`
  ${EHR_APPOINTMENT_FIELDS}
  query latestUserEhrAppointment($category: EhrAppointmentCategory) {
    latestUserEhrAppointment(category: $category) {
      ...EhrAppointmentFields
      id
    }
  }
`
