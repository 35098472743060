import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Button, Icon, Text } from '@src/components/base'
import { NavigationContainer } from '@src/screens/Common/containers'

export const PauseSubscriptionSuccess = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  const handleDone = () => {
    navigation.navigate('Dashboard', { screen: 'Home' })
  }

  return (
    <NavigationContainer
      title="Snooze your Program"
      goBack={handleDone}
      leftIcon={{ pack: 'eva', name: 'close' }}
    >
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <View style={styles.heading}>
          <Icon
            name="check-circle"
            weight="fill"
            color={styles.successIcon.backgroundColor as string}
            size={48}
          />
          <Text type="title-2" style={styles.title}>
            Success!
          </Text>
          <Text type="regular" style={styles.description}>
            Your subscription may appear to resume up to 48 hours earlier than scheduled to ensure
            your CGM gets to you when you need it!
          </Text>
        </View>
        <View style={styles.actionsContainer}>
          <Button type="primary" size="block" accessibilityLabel="Done" onPress={handleDone}>
            Go to Dashboard
          </Button>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    justifyContent: 'space-between',
  },
  heading: {
    alignItems: 'center',
    paddingTop: 32,
    paddingHorizontal: 16,
  },
  successIcon: {
    backgroundColor: 'theme.primary.base',
  },
  title: {
    marginTop: 16,
  },
  description: {
    marginTop: 16,
    textAlign: 'center',
  },
  actionsContainer: {
    padding: 16,
  },
})
