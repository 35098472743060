import { CommonActions, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect, useRef } from 'react'
import { RootStackParamList } from '@src/navigation/types'
import {
  BillingProduct,
  EhrAppointmentKind,
  InsuranceBerryStreetAppointmentType,
  Invoice,
  Product,
} from '@src/types.ts'
import { ProductSelection } from '@screens/Marketplace/types/types.ts'
import { usePurchaseOneTimePayment } from '@screens/Marketplace/utils/hooks.ts'
import { useDispatchAsync, useSnack } from '@src/utils'
import { ErrorMessages } from '@src/config'
import Storage from '@utils/storage.ts'
import { NavigationRouteConfig } from '@utils/analytics.ts'

const MAX_RETRY_COUNT = 15

export const useChargeFee = ({
  videoCallId,
  feeProduct,
  appointmentType,
  appointmentKind,
}: {
  videoCallId: string
  feeProduct: Product | undefined
  appointmentType?: InsuranceBerryStreetAppointmentType
  appointmentKind?: EhrAppointmentKind
}) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const purchaseOneTimePayment = usePurchaseOneTimePayment()
  const dispatchAsync = useDispatchAsync()
  const showSnack = useSnack()
  const pollTimeout = useRef<NodeJS.Timeout | undefined>(undefined)
  const invoiceIdKey = `${Storage.VIDEO_CALL_FEE_INVOICE_ID_KEY}_${videoCallId}`

  const clearPollTimeout = () => {
    if (pollTimeout.current) {
      clearTimeout(pollTimeout.current)
    }
  }

  useEffect(() => {
    return () => {
      clearPollTimeout()
    }
  }, [])

  const navigateToNutritionistHub = (topRoute?: NavigationRouteConfig) => {
    navigation.dispatch((state) => {
      // Remove current route
      const routes = state.routes.slice(0, -1)

      // navigate to NutritionistHub
      const newRoutes = [
        ...routes,
        {
          name: 'Drawer',
          params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } },
        },
      ]

      if (topRoute) {
        newRoutes.push(topRoute)
      }

      return CommonActions.reset({
        index: newRoutes.length - 1,
        routes: newRoutes,
      } as any)
    })
  }

  const onSuccess = async () => {
    if (feeProduct?.key === BillingProduct.VideoCallLateCancellationFee) {
      try {
        await dispatchAsync({
          type: 'nutritionistHub/cancelVideoCall',
          payload: {
            id: videoCallId,
          },
        })

        navigateToNutritionistHub({ name: 'FeePaymentSuccessfulModal' } as NavigationRouteConfig)
      } catch (error: any) {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
        navigateToNutritionistHub()
      }
    } else if (feeProduct?.key === BillingProduct.VideoCallLateRescheduleFee) {
      navigation.replace('CallScheduling', {
        appointmentType,
        appointmentKind,
        appointmentId: videoCallId,
        allowBackNavigation: true,
      })
    }
  }

  let retryCount = 0

  const pollInvoiceStatus = async (invoiceId: string) => {
    const invoice: Invoice = await dispatchAsync({
      type: 'marketplace/fetchInvoice',
      payload: {
        id: invoiceId,
      },
    })

    if (invoice.status === 'paid') {
      clearPollTimeout()
      await onSuccess()
      return
    }

    retryCount += 1
    if (retryCount >= MAX_RETRY_COUNT) {
      retryCount = 0
      clearPollTimeout()
      navigation.replace('PaymentFailed', {
        videoCallId,
        feeProduct,
        appointmentType,
        appointmentKind,
      })

      return
    }

    pollTimeout.current = setTimeout(() => {
      pollInvoiceStatus(invoiceId)
    }, 3000)
  }

  const chargeFee = async () => {
    const selection = { product: feeProduct, quantity: 1 } as ProductSelection

    try {
      const invoiceId = Storage.get<string>(invoiceIdKey)

      if (invoiceId) {
        await pollInvoiceStatus(invoiceId)
        return
      }

      const metadata = [{ key: 'appointment_id', value: videoCallId }]
      const invoice = await purchaseOneTimePayment(selection, null, metadata)

      if (!invoice) {
        navigation.replace('PaymentFailed', {
          videoCallId,
          feeProduct,
          appointmentType,
          appointmentKind,
        })
        return
      }

      Storage.set(invoiceIdKey, invoice.id)

      await pollInvoiceStatus(invoice.id)
    } catch (error: any) {
      showSnack(error?.message || ErrorMessages.ServerError, null, 'error')

      // purchaseOneTimePayment failed
      if (retryCount === 0) {
        navigation.replace('PaymentFailed', {
          videoCallId,
          feeProduct,
          appointmentType,
          appointmentKind,
        })
      }
    }
  }

  return chargeFee
}
