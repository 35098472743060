import moment from 'moment'
import { Action } from '@models/commonReducers'
import defaultReducers from '@models/defaultReducers'
import { DailyNutritionStoreState } from './types'
import { defaultDailyNutritionState } from './constants'
import { transformNutrition } from './transforms'

const { cacheSet: defaultCacheSet } = defaultReducers

const nutritionReducers = {
  ...defaultReducers,

  // Custom cache reducer that skips caching allNutrition query results if
  // thresholds were recently updated. Without this, stale nutrition data
  // will be cached before the backend finishes recalculating daily statistics.
  //
  cacheSet: (state: DailyNutritionStoreState, action: Action) => {
    if (!action.cacheKey?.startsWith('dailyNutrition/fetch')) {
      return defaultCacheSet(state, action)
    }

    const { glucoseThresholdLastUpdatedAt: lastUpdatedAt } = state
    const payloadUpdatedAt = moment(action.payload?.updatedAt)

    if (lastUpdatedAt && payloadUpdatedAt.isValid() && payloadUpdatedAt.isBefore(lastUpdatedAt)) {
      // Thresholds have previously been updated but the payload data is stale
      return state
    }

    return defaultCacheSet(state, action)
  },

  glucoseThresholdsUpdated: (state: DailyNutritionStoreState, { payload }: { payload: any }) => ({
    ...state,
    glucoseThresholdLastUpdatedAt: moment(payload.updatedAt),
  }),

  setNutrition: (state: DailyNutritionStoreState, { payload }: { payload: any }) => ({
    ...state,
    ...transformNutrition(payload),
  }),

  clear: (state: DailyNutritionStoreState) => ({
    ...state,
    ...defaultDailyNutritionState,
  }),
}

export default nutritionReducers
