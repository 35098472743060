import { useSelector } from 'react-redux'
import { EhrAppointmentKind, InsuranceBerryStreetAppointmentType } from '@src/types.ts'
import { clientConfigStoreStateSelector } from '@selectors/app.ts'

export const useVideoCallDurations = () => {
  const clientConfig = useSelector(clientConfigStoreStateSelector)

  return {
    getVideoCallDuration: (type: InsuranceBerryStreetAppointmentType | undefined | null) => {
      if (!type) {
        return 0
      }

      return (
        clientConfig.appointmentTypes.find((appointmentType) => appointmentType.key === type)
          ?.duration || 0
      )
    },
    getAppointmentDuration: (kind: EhrAppointmentKind | undefined | null) => {
      if (!kind) {
        return 0
      }

      return (
        clientConfig.appointmentKinds.find((appointmentKind) => appointmentKind.key === kind)
          ?.duration || 0
      )
    },
  }
}
