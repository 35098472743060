import React from 'react'
import { View, Keyboard } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Button, Input } from '@components/base'
import { Validators } from '@utils'
import { SecureInput } from '@components/inputs/SecureInput'
import { useSnack } from '@utils/navigatorContext'
import { useSignIn } from '@src/hooks/useSignIn'

export const SignInForm = () => {
  const styles = useStyleSheet(themedStyle)
  const showSnack = useSnack()
  const { signInWithEmail } = useSignIn()

  const {
    control,
    formState: { errors, isValid },
    setFocus,
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSignInPress = handleSubmit(async ({ email, password }) => {
    Keyboard.dismiss()

    try {
      await signInWithEmail(email, password)
    } catch (error: any) {
      showSnack(error.message, null, 'error')
    }
  })

  return (
    <View testID="SigninScreen/SigninForm">
      <Controller
        control={control}
        name="email"
        rules={{ required: true, validate: Validators.EmailValidator }}
        render={({ field }) => (
          <Input
            {...field}
            accessibilityLabel="Email"
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="email-address"
            hasError={!!errors.email}
            label="Email"
            placeholder="Enter your email address"
            returnKeyType="next"
            testID="SigninScreen/Email"
            onSubmitEditing={() => setFocus('password')}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{ required: true }}
        render={({ field }) => (
          <SecureInput
            {...field}
            hasError={!!errors.password}
            label="Password"
            placeholder="Enter your password"
            returnKeyType="done"
            style={styles.passwordInput}
            testID="SigninScreen/Password"
            onSubmitEditing={onSignInPress}
          />
        )}
      />
      <Button
        allowPressWhenDisabled
        accessibilityLabel="Sign In"
        disabled={!isValid}
        size="block"
        testID="SigninScreen/SigninButton"
        type="primary"
        onPress={onSignInPress}
      >
        Log In
      </Button>
    </View>
  )
}

const themedStyle = StyleService.create({
  passwordInput: {
    marginTop: 16,
    marginBottom: 24,
  },
})
