import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { TabView } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { MealEditor, MealsList } from '@screens/Meals'
import { copyMeal, getDraftMeal, hasUnsavedChanges } from '@screens/Meals/utils'
import { favoriteMealsSelector, mealDraftSelector, recentMealsSelector } from '@selectors/meals'
import { useCancelModal } from '@utils/hooks'
import { useGoBack } from '@utils/navigation'

export const MealEditorWrapper = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()

  const [tabIndex, setTabIndex] = useState(0)

  const draftMeal = useSelector(mealDraftSelector)
  const initialFavorites = useSelector(favoriteMealsSelector)
  const recents = useSelector(recentMealsSelector)

  // List to keep the initial favorite items. All initial favorite meals
  // should be displayed for the current meal session, even when they are toggled off
  const [favorites, setFavorites] = useState(initialFavorites)

  const openCancelModal = useCancelModal({
    goBack,
    isModified: hasUnsavedChanges(draftMeal),
    itemName: 'meal',
  })

  const cancelEditing = () => {
    dispatch({ type: 'meals/saveDraft' })
    goBack()
  }

  const onToggleFavorite = (item: Meal) => {
    setFavorites((prevFavorites) => {
      const isExistingFavorite = prevFavorites.some((meal) => meal.id === item.id)

      if (!isExistingFavorite) {
        return [{ ...item, favorite: true }, ...prevFavorites]
      }

      return prevFavorites.map((meal) =>
        meal.id === item.id ? { ...meal, favorite: !meal.favorite } : meal,
      )
    })

    dispatch({
      type: 'meals/updateFavorite',
      payload: {
        ...item,
        favorite: !item.favorite,
        time: item.occurredAt, // `time` field is required until BE is updated
      },
    })
  }

  const onItemSelected = (item: Meal) => {
    dispatch({
      type: 'meals/updateDraft',
      payload: copyMeal(getDraftMeal(item)),
    })
    setTabIndex(0)
  }

  const isNewMeal = !draftMeal.id

  const handleGoBack = () => {
    if (isNewMeal) {
      dispatch({
        type: 'meals/saveDraft',
        payload: hasUnsavedChanges(draftMeal) ? { ...draftMeal, hasUnsavedChanges: true } : {},
      })
      openCancelModal()
    } else {
      cancelEditing()
    }
  }

  return (
    <NavigationContainer
      showLoadingIndicator
      title={isNewMeal ? 'Add Meal' : 'Edit Meal'}
      goBack={handleGoBack}
      leftIcon={isNewMeal ? { pack: 'eva', name: 'close' } : undefined}
    >
      {isNewMeal ? (
        <TabView
          swipeEnabled
          tabIndex={tabIndex}
          onChangeTabIndex={setTabIndex}
          routes={[
            {
              key: 'new-meal',
              title: 'New Meal',
              sceneComponent: <MealEditor />,
            },
            {
              key: 'favorite-meals',
              title: 'Favorites',
              sceneComponent: (
                <MealsList
                  items={favorites}
                  emptyListText={
                    'Your favorite meals will appear here.\n' +
                    "Press the heart icon on the meal you'd like to favorite and it'll appear here."
                  }
                  emptyListIcon={<Icon name="heart" style={styles.heartIcon} />}
                  onItemSelected={onItemSelected}
                  onToggleFavorite={onToggleFavorite}
                />
              ),
            },
            {
              key: 'recent-meals',
              title: 'Recents',
              sceneComponent: (
                <MealsList
                  items={recents}
                  emptyListIcon={<Icon name="clock" style={styles.clockIcon} />}
                  emptyListText={
                    "Meals you're logging will appear here.\n" +
                    'Start logging your meals to get them here.'
                  }
                  onItemSelected={onItemSelected}
                  onToggleFavorite={onToggleFavorite}
                />
              ),
            },
          ]}
        />
      ) : (
        <MealEditor />
      )}
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  heartIcon: {
    color: 'theme.like',
    width: 48,
  },
  clockIcon: {
    color: 'theme.text.secondary',
    width: 48,
  },
})
