import React from 'react'
import { View, Dimensions } from 'react-native'
import {
  TabBar,
  TabView as NativeTabView,
  TabBarProps,
  TabViewProps as TabViewNativeProps,
  Route,
  SceneRendererProps,
  NavigationState,
  TabDescriptor,
} from 'react-native-tab-view'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface TabRoute extends Route {
  sceneComponent: React.ReactNode
}

interface RenderTabBarParams extends SceneRendererProps {
  navigationState: NavigationState<TabRoute>
}

export interface TabViewProps extends Omit<Partial<TabViewNativeProps<TabRoute>>, 'tabIndex'> {
  tabIndex: number
  onChangeTabIndex: (tabIndex: number) => void
  tabBarProps?: Partial<TabBarProps<TabRoute>>
  routes: TabRoute[]
  staticHeader?: React.ReactNode
}

const renderScene = ({ route }: { route: TabRoute }) => route.sceneComponent

const getOptions = (routes: TabRoute[]) => {
  return routes.reduce((acc, route) => {
    acc[route.key] = {
      label: ({ route, color }) => (
        <Text type="large" style={{ color }} numberOfLines={1}>
          {route.title}
        </Text>
      ),
    }
    return acc
  }, {} as Record<string, TabDescriptor<TabRoute>>)
}

const initialLayout = { width: Dimensions.get('window').width }

export const TabView = ({
  tabIndex,
  onChangeTabIndex,
  tabBarProps,
  routes,
  staticHeader,
  ...restProps
}: TabViewProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const renderTabBar = (props: RenderTabBarParams) => (
    <View>
      <TabBar
        {...props}
        {...tabBarProps}
        options={getOptions(routes)}
        style={styles.tabBar}
        tabStyle={styles.tab}
        indicatorStyle={styles.tabIndicator}
        activeColor={theme['theme.text.primary']}
        inactiveColor={theme['theme.text.tertiary']}
      />
      {staticHeader}
    </View>
  )

  return (
    <View style={styles.container}>
      <NativeTabView
        style={styles.container}
        navigationState={{ index: tabIndex, routes }}
        onIndexChange={onChangeTabIndex}
        renderScene={renderScene}
        renderTabBar={renderTabBar}
        initialLayout={initialLayout}
        {...restProps}
      />
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  tabBar: {
    justifyContent: 'center',
    height: 48,
    backgroundColor: 'theme.background',
    boxShadow: 'none',
  },
  tab: {
    padding: 0,
  },
  tabIndicator: {
    height: 4,
    borderRadius: 2,
    backgroundColor: 'theme.text.primary',
  },
})
