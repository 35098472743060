import { DataPoint } from '@src/components/charts/timelineChart'
import { ChartData } from '@src/models/dailyCharts/types'
import { isChartValue, isRealTimeValue } from './utils'

export const useTimelineChartData = (data: ChartData) => {
  // Split data into separate categories so that we can handle them differently on the chart
  const { chartValues, noData, events } = data.values
    .sort((a, b) => a.x.valueOf() - b.x.valueOf())
    .reduce(
      (acc, item, index, values) => {
        const hasPrevData = isChartValue(values[index - 1])
        const hasNextData = isChartValue(values[index + 1])
        const hasNextRealTimeData =
          isRealTimeValue(values[index + 1]) || isRealTimeValue(values[index + 2])

        /* Drop the one or two null data points right before a realTime glucose value to connect the curve
         There is a gap of about ~15-20 minutes between a persisted glucose data point and the real time glucose value
         This gap could include up to 2 null data points, between which we normally do not connect the curve
         Drop at max two null data points, as otherwise we may connect lines between longer gaps in persisted data */
        if (hasNextRealTimeData && item.y === null) {
          return acc
        }

        if (item.tag === null) {
          // Default data, used to make empty areas of the chart interactive
          acc.noData.push({ ...item, y: -100, dataType: 'empty' })
          // Add a data point with null value to the chart data in order to display a gap on the chart
          if (hasPrevData || hasNextData) {
            acc.chartValues.push({ ...item, y: null, dataType: 'data' })
          }
        } else if (isChartValue(item)) {
          // Glucose and ketones data
          acc.chartValues.push({ ...item, dataType: 'data' })
        } else {
          // Events (Meal, Activity, Measurement, etc.)
          acc.events.push({
            ...item,
            y: -100,
            displayY: item.y,
            dataType: 'event',
          })
          // Add a data point to the chart if there is data displayed on either side of the event position
          // This is necessary to make sure the event point is placed exactly on the chart line
          if (hasPrevData || hasNextData) {
            acc.chartValues.push({ ...item, dataType: 'data' })
          }
        }
        return acc
      },
      { chartValues: [], noData: [], events: [] } as Record<string, DataPoint[]>,
    )

  const chartData = {
    ...data,
    values: [...chartValues, ...noData, ...events],
  }

  return { chartData, events }
}
