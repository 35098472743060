import React, { MutableRefObject } from 'react'
import { useNavigation } from '@react-navigation/native'
import { TouchableOpacity, View } from 'react-native'
import { Timeline } from '@src/components/Timeline/Timeline'
import { Text } from '@src/components/base'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Analytics } from '@src/config'
import { CustomEventTypes } from '@src/config/analytics'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import {
  HomeScreenWalkthroughSteps,
  useHomeScreenWalkthrough,
} from '@src/context/homeScreenWalkthroughContext'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { DialChart } from './DialChart/DialChart'
import { NutritionSummaryChart } from './NutritionSummaryChart/NutritionSummaryChart'
import { useHomeScreenChart } from './useHomeScreenChart'

export const HOME_SCREEN_CHART_CARD_HEIGHT = 232
export const CUSTOM_CHART_HEIGHT = HOME_SCREEN_CHART_CARD_HEIGHT - 8
export interface HomeScreenChartProps {
  primaryChartRef: MutableRefObject<any>
  secondaryChartRef: MutableRefObject<any>
  onChartLoadEnd: () => void
}

export const HomeScreenChart = ({
  primaryChartRef,
  secondaryChartRef,
  onChartLoadEnd,
}: HomeScreenChartProps) => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyle)
  const { advanceWalkthroughToNextStep, isStepVisible } = useHomeScreenWalkthrough()
  const { shouldShowDialChart, shouldShowNutritionSummaryChart } = useHomeScreenChart()

  if (shouldShowNutritionSummaryChart) {
    return <NutritionSummaryChart />
  }

  const chartTitle = shouldShowDialChart ? 'Latest Glucose Response' : 'Daily Glucose Responses'
  const chart = shouldShowDialChart ? (
    <WithWalkthrough
      isVisible={isStepVisible(HomeScreenWalkthroughSteps.DialChart)}
      onClose={advanceWalkthroughToNextStep}
      allowChildInteraction={false}
      accessibilityLabel="Glucose Dial Chart"
      content={
        <WalkthroughTooltip
          title="Your Glucose at a Glance"
          subtitle={'See your latest reading. \n Tap to visit your full interactive chart.'}
        />
      }
      placement="bottom"
    >
      <View style={styles.chartWalkthroughFocusContainer}>
        <DialChart />
      </View>
    </WithWalkthrough>
  ) : (
    <TouchableOpacity
      accessibilityLabel="Glucose Chart"
      onPress={() => {
        Analytics.track(CustomEventTypes.HomeScreenGlucoseChartTapped)
        navigation.navigate('Events', { resetFilters: true })
      }}
    >
      <View pointerEvents="none">
        <Timeline
          primaryChartRef={primaryChartRef}
          secondaryChartRef={secondaryChartRef}
          onChartLoadEnd={onChartLoadEnd}
          hideMultiSlider
          hideChartHeader
          showLastReadingTooltip
          customChartHeight={CUSTOM_CHART_HEIGHT}
        />
      </View>
    </TouchableOpacity>
  )

  return (
    <View>
      <View style={styles.chartHeader}>
        <Text type="large" bold lineSpacing="none">
          {chartTitle}
        </Text>
        <WithWalkthrough
          isVisible={isStepVisible(HomeScreenWalkthroughSteps.Customize)}
          onClose={() => {
            Analytics.track(CustomEventTypes.HomeScreenWalkthroughCustomizeTapped)
            advanceWalkthroughToNextStep()
          }}
          allowChildInteraction={false}
          accessibilityLabel="Customize Home Screen"
          content={
            <WalkthroughTooltip
              title="Personalize Your Actions"
              subtitle="Customize these actions to fit your health goals."
            />
          }
          placement="bottom"
        >
          <View style={styles.walkthroughFocusContainer}>
            <TouchableOpacity
              accessibilityLabel="Customize Home Screen"
              onPress={() => {
                navigation.navigate('EditDailyActionsModal')
              }}
              style={styles.customizeAction}
            >
              <Text type="regular" bold lineSpacing="none" style={styles.customizeActionText}>
                Customize
              </Text>
            </TouchableOpacity>
          </View>
        </WithWalkthrough>
      </View>

      <View style={styles.chartCard}>{chart}</View>
    </View>
  )
}

const themedStyle = StyleService.create({
  chartCard: {
    marginHorizontal: 16,
    paddingVertical: 8,
    paddingLeft: 0,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    height: HOME_SCREEN_CHART_CARD_HEIGHT,
    overflow: 'hidden',
    backgroundColor: 'theme.background',
  },
  chartHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 16,
    marginBottom: 8,
    marginTop: 8,
  },
  customizeAction: {
    alignSelf: 'center',
  },
  customizeActionText: {
    color: 'theme.text.link',
    margin: 8,
  },
  walkthroughFocusContainer: {
    borderRadius: 24,
    backgroundColor: 'theme.background',
  },
  chartWalkthroughFocusContainer: {
    borderRadius: 12,
    marginTop: -8,
    backgroundColor: 'theme.background',
  },
})
