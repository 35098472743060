import React, { useCallback, useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { useDispatch, useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { RootStackParamList } from '@src/navigation/types'
import {
  latestUserAppointmentSelector,
  latestUserEhrAppointmentSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { userSelector } from '@src/selectors/app'

export const FreeVideoCall = () => {
  const styles = useStyleSheet(themedStyles)

  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const [claimingFreeCall, setClaimingFreeCall] = useState(true)

  const user = useSelector(userSelector)

  const latestAppointment = useSelector(latestUserAppointmentSelector)
  const latestEhrAppointment = useSelector(latestUserEhrAppointmentSelector)

  const insets = useSafeAreaInsets()

  const onDismiss = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.replace('Drawer', { screen: 'Dashboard' })
    }
  }, [navigation])

  const navigateToScheduling = () => {
    if (user?.migrationFromInsuranceEnabled) {
      if (!latestEhrAppointment) {
        onDismiss()
        return
      }

      navigation.reset({
        index: 1,
        routes: [
          { name: 'Drawer', state: { index: 0, routes: [{ name: 'Dashboard' }] } },
          {
            name: 'CallScheduling',
            params: {
              appointmentKind: latestEhrAppointment?.kind,
              pendingAppointmentId: latestEhrAppointment?.id,
              allowBackNavigation: true,
            },
          },
        ],
      })
    }

    if (!latestAppointment) {
      onDismiss()
      return
    }

    navigation.reset({
      index: 1,
      routes: [
        { name: 'Drawer', state: { index: 0, routes: [{ name: 'Dashboard' }] } },
        {
          name: 'CallScheduling',
          params: {
            appointmentType: latestAppointment?.appointmentType,
            pendingAppointmentId: latestAppointment?.id,
            allowBackNavigation: true,
          },
        },
      ],
    })
  }

  useEffect(() => {
    if (user?.migrationFromInsuranceEnabled) {
      dispatch({
        type: 'nutritionistHub/claimFreeAppointment',
        failure: () => {
          onDismiss()
        },
        success: () => {
          setClaimingFreeCall(false)
        },
      })

      return
    }

    dispatch({
      type: 'nutritionistHub/claimFreeVideoCall',
      failure: () => {
        onDismiss()
      },
      success: () => {
        setClaimingFreeCall(false)
      },
    })
  }, [dispatch, onDismiss, user?.migrationFromInsuranceEnabled])

  if (claimingFreeCall) {
    return <LoadingContainer loadingMessage="Loading..." />
  }

  return (
    <ScrollView
      bounces={false}
      style={styles.container}
      contentContainerStyle={[styles.viewContent, { paddingBottom: Math.max(insets.bottom, 16) }]}
    >
      <View style={styles.topContainer}>
        <Icon name="gift" style={styles.successIcon} />
        <Text type="title-3" style={styles.text}>
          Free Nutrition Call
        </Text>
        <Text type="regular" style={styles.text}>
          To get you started at Nutrisense, we want to offer you a complimentary free 30 minute call
          with one of our Nutritionists. This is a limited time offer!
        </Text>
      </View>
      <Button
        size="block"
        accessibilityLabel="Schedule call"
        type="primary"
        style={{ marginBottom: 8 }}
        onPress={navigateToScheduling}
      >
        Schedule call
      </Button>
      <Button size="block" accessibilityLabel="Dismiss" type="transparent" onPress={onDismiss}>
        Dismiss
      </Button>
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  viewContent: {
    paddingTop: 60,
    paddingHorizontal: 24,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  topContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    marginBottom: 24,
  },
  successIcon: {
    alignSelf: 'center',
    width: 48,
    marginVertical: 24,
    color: 'theme.text.primary',
  },
})
