import moment from 'moment'
import { Model } from '@models'
import { DailyAction, Success, UserDailyActionStatus } from '@src/types'
import { DATE_FORMAT } from '@src/config/momentFormat'
import reducers from '../reducers'

import { defaultDailyActionsState } from '../constants'
import { LIST_USER_DAILY_ACTIONS } from '../graphql/listUserDailyActions'
import { MARK_DAILY_ACTION_COMPLETE } from '../graphql/markDailyActionComplete'
import { MARK_DAILY_ACTION_INCOMPLETE } from '../graphql/markDailyActionIncomplete'
import { UPSERT_DAILY_ACTION } from '../graphql/upsertDailyAction'

const toggleCompletionPayloadCallback = (nextCompleted: boolean) => (
  _response: { markDailyActionComplete: Success },
  { effectPayload }: { effectPayload: { dailyActionId: string } },
) => ({
  completed: nextCompleted,
  dailyActionId: effectPayload.dailyActionId,
})

export default class DailyActions {
  namespace = 'dailyActions'

  state = {
    ...Model.defaultState,
    ...defaultDailyActionsState,
  }

  effects = {
    list: Model.buildEffect({
      name: `${this.namespace}/list`,
      query: LIST_USER_DAILY_ACTIONS,
      variables(_payload: any) {
        return {
          startDate: moment().format(DATE_FORMAT),
          endDate: moment().format(DATE_FORMAT),
          status: UserDailyActionStatus.Active,
        }
      },

      reducers: [{ name: 'listData' }],
    }),

    markComplete: Model.buildEffect({
      name: `${this.namespace}/markComplete`,
      optimistic: true,
      variables(payload: { dailyActionId: string }) {
        const { dailyActionId } = payload

        return {
          dailyActionId,
          date: moment().format(DATE_FORMAT),
        }
      },
      query: MARK_DAILY_ACTION_COMPLETE,
      optimisticReducers: [
        {
          name: 'toggleCompletion',
          payload: toggleCompletionPayloadCallback(true),
        },
      ],
      reducers: [
        {
          name: 'toggleCompletion',
          payload: toggleCompletionPayloadCallback(true),
        },
      ],
      errorReducers: [
        {
          name: 'toggleCompletion',
          payload: toggleCompletionPayloadCallback(false),
        },
      ],
    }),

    markIncomplete: Model.buildEffect({
      name: `${this.namespace}/markIncomplete`,
      optimistic: true,
      variables(payload: { dailyActionId: string }) {
        const { dailyActionId } = payload

        return {
          dailyActionId,
          date: moment().format(DATE_FORMAT),
        }
      },
      query: MARK_DAILY_ACTION_INCOMPLETE,
      optimisticReducers: [
        {
          name: 'toggleCompletion',
          payload: toggleCompletionPayloadCallback(false),
        },
      ],
      reducers: [
        {
          name: 'toggleCompletion',
          payload: toggleCompletionPayloadCallback(false),
        },
      ],
      errorReducers: [
        {
          name: 'toggleCompletion',
          payload: toggleCompletionPayloadCallback(true),
        },
      ],
    }),

    upsert: Model.buildEffect({
      name: `${this.namespace}/upsert`,
      variables(payload: { kind: string; status: UserDailyActionStatus }) {
        const { kind, status } = payload

        return {
          kind,
          status,
        }
      },
      query: UPSERT_DAILY_ACTION,
      reducers: [
        {
          name: 'upsertData',
          payload: (response: { upsertDailyAction: DailyAction }) => {
            const { kind, status } = response.upsertDailyAction
            return {
              kind,
              status,
            }
          },
        },
      ],
    }),

    reorder: Model.buildEffect({
      name: `${this.namespace}/reorder`,
      reducers: [{ name: 'reorderDailyActions' }],
    }),

    setPreferredHeroChart: Model.buildEffect({
      name: `${this.namespace}/setPreferredHeroChart`,
      reducers: [
        {
          name: 'setPreferredHeroChartType',
        },
      ],
    }),
  }

  reducers = reducers
}
