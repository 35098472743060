import React from 'react'
import { View } from 'react-native'
import { compact, sortBy, first, last, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { StyledComponentProps, useTheme } from '@ui-kitten/components'
import { StyleService } from '@src/style/service'
import { Units } from '@utils'
import { CommonChart } from '@components'
import { activityMiniChart } from '@src/components/charts/activityMiniChart'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { TWELVE_HOURS_TIME_FORMAT } from '@src/config/momentFormat'
import { getChartData, toChartData } from '@src/containers/ActivityChart/utils'
import { UnitSystem } from '@src/screens/Settings'
import { useGlucosePrecision } from '@src/screens/Settings/utils/hooks'
import { Activity, Measurement } from '@src/types'
import metricPlaceholderValues from '../../components/charts/data/defaultMetricActivityChartData.json'
import imperialPlaceholderValues from '../../components/charts/data/defaultImperialActivityChartData.json'

const ACTIVITY_CHART_HEIGHT = 111

export interface ActivityChartProps extends StyledComponentProps {
  item: Meal | Activity
  onLoad?: () => void
  disableAnimation?: boolean
}

export const ActivityChart = ({ item, disableAnimation = false, onLoad }: ActivityChartProps) => {
  const theme = useTheme()
  const unitSystem = useSelector(unitSystemStoreStateSelector)

  const isImperial = unitSystem === UnitSystem.Imperial
  const precision = useGlucosePrecision()
  const glucoseUnit = Units.getGlucoseUnit(unitSystem)

  const placeholderValues = (isImperial
    ? imperialPlaceholderValues
    : metricPlaceholderValues) as Measurement[]

  const chartData = getChartData(item)
  const { activityData, centeredChartData, startTimeTick, endTimeTick } = chartData

  const usePlaceholderData = isEmpty(centeredChartData) || !item.glucose

  const pointsData = activityData.filter((point) => !point.fake)

  const itemPoint = first(pointsData)
  const limitPoint = last(pointsData)
  const maxPoint = last(sortBy(pointsData, 'y'))

  const points = compact([
    maxPoint && {
      ...maxPoint,
      name: 'max',
      x: moment(maxPoint.x).unix(),
      tag: '',
      time: moment(maxPoint.x).format(TWELVE_HOURS_TIME_FORMAT),
      tagPosition: 'tc',
    },
    itemPoint && {
      ...itemPoint,
      name: 'item',
      x: moment(itemPoint.x).unix(),
      tag: itemPoint.y.toFixed(precision),
      time: moment(itemPoint.x).format(TWELVE_HOURS_TIME_FORMAT),
      tagPosition: 'tc',
    },
    limitPoint && {
      ...limitPoint,
      name: 'limit',
      x: moment(limitPoint.x).unix(),
      tag: limitPoint.y.toFixed(precision),
      time: moment(limitPoint.x).format(TWELVE_HOURS_TIME_FORMAT),
      tagPosition: 'tc',
    },
  ])

  const data = !usePlaceholderData ? centeredChartData : toChartData(placeholderValues, false)

  const dataValues = data.map((value) => value.y)
  const min = Math.max(Math.min(...dataValues), 0)
  let max = Math.max(...dataValues)
  max = max + (max - min) * 0.175 //we add offset based of delta to be able to draw guide text above chart

  const horizontalPadding =
    activityData.length === centeredChartData.length && !usePlaceholderData ? 25 : 0

  const chartStyle: { [key: string]: any } = {
    ...styles.chart,
    ...chartPalette(theme),
  }
  const placeholderStyle = {
    ...chartStyle,
    scorePointColor: theme['theme.surface.base'],
    strokeColor: undefined,
  }

  const chartScript = activityMiniChart({
    style: !usePlaceholderData ? chartStyle : placeholderStyle,
    data: data.map((value) => ({ ...value, x: value.x.unix() })),
    points: !usePlaceholderData ? points : [],
    min,
    max,
    startTime: !usePlaceholderData ? startTimeTick : undefined,
    endTime: !usePlaceholderData ? endTimeTick : undefined,
    unit: glucoseUnit,
    height: ACTIVITY_CHART_HEIGHT,
    disableAnimation,
    horizontalPadding,
  })

  return (
    <View style={styles.chartContainer}>
      <CommonChart chartScript={chartScript} height={ACTIVITY_CHART_HEIGHT} onLoad={onLoad} />
    </View>
  )
}

const chartPalette = (theme: Record<string, string>) => ({
  itemPointColor: theme['theme.range.good'],
  maxPointColor: theme['theme.error.base'],
  changePointColor: theme['theme.warning.base'],
  limitPointColor: theme['theme.range.good'],
  scorePointColor: theme['theme.primary.base'],
  deltaPointColor: theme['theme.info.base'],
  stroke: theme['theme.text.primary'],
  textColor: theme['theme.text.primary'],
  areaColor: theme['theme.surface.base2'],
  highlightColor: theme['theme.info.lighter'],
  overflowColor: theme['theme.error.lighter'],
  strokeColor: theme['theme.surface.base'],
})

const styles = StyleService.create({
  chartContainer: {
    flex: 1,
  },
  chart: {
    opacity: 1,
    fontSize: 13,
  },
})
