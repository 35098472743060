import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService } from '@src/style/service'
import { useVariantStyleSheet } from '@src/hooks/useVariantStyleSheet'
import { scoreSelector } from '@src/selectors/app'
import { Text } from '@components/base'
import { useScoreRepresentations } from '@src/screens/Events/utils/useScoreRepresentations'
import { GaugeIndicator } from './GaugeIndicator'

type GlucoseScoreGaugeVariant = 'small' | 'medium'

interface GlucoseScoreGaugeProps {
  score: number
  style?: StyleProp<ViewStyle>
  variant?: GlucoseScoreGaugeVariant
}

export const GlucoseScoreGauge = ({ score, style, variant = 'small' }: GlucoseScoreGaugeProps) => {
  const hasScore = score > 0
  const { max: maxScore } = useSelector(scoreSelector)
  const value = score / maxScore

  const { scoreTitle, scoreColor } = useScoreRepresentations(score)
  const scoreStyle = hasScore && { color: scoreColor }
  const styles = useVariantStyleSheet(sharedStyles, variant, {
    small: smallStyles,
    medium: mediumStyles,
  })

  return (
    <View style={[styles.container, style]}>
      <GaugeIndicator style={[styles.gauge, { color: scoreColor }]} progress={value} />
      {variant === 'small' ? (
        <Text type="large" bold lineSpacing="none">
          {hasScore ? score : ' '}
        </Text>
      ) : (
        <Text type="title-1">{hasScore ? score : ' '}</Text>
      )}

      <Text
        type={variant === 'small' ? 'tiny' : 'large'}
        bold
        lineSpacing="none"
        style={[styles.description, scoreStyle]}
      >
        {hasScore ? scoreTitle : 'No Data'}
      </Text>
    </View>
  )
}

const sharedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  gauge: {
    position: 'absolute',
  },
  description: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'theme.text.tertiary',
  },
})

const smallStyles = StyleService.create({
  container: {
    paddingTop: 20,
    width: 112,
    height: 62,
  },
  gauge: {
    width: 112,
    height: 62,
  },
  description: {
    marginHorizontal: 22,
    fontSize: 9,
    lineHeight: 12,
  },
})

const mediumStyles = StyleService.create({
  container: {
    paddingTop: 32,
    width: 170,
    height: 96,
  },
  gauge: {
    width: 170,
    height: 96,
  },
  description: {
    marginHorizontal: 28,
    fontSize: 15,
    lineHeight: 20,
  },
})
