import React from 'react'
import { View, Image, useWindowDimensions, ImageStyle, StyleProp } from 'react-native'
import PushNotificationIOS from '@react-native-community/push-notification-ios'
import { requestNotifications } from 'react-native-permissions'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text, Button } from '@components/base'
import {
  notificationsPermissionDarkImage,
  notificationsPermissionLightImage,
} from '@src/assets/images'
import { ScrollViewWithFade } from '@src/components'
import { Analytics, CustomEventTypes, Device } from '@src/config'
import { PermissionsCarouselScreenProps } from '@src/screens/Home/components/Permissions/PermissionsCarousel'
import { useIsDarkMode } from '@src/config/theme'

export const NotificationPermission = ({
  width,
  onAllowButtonPressCallback,
}: PermissionsCarouselScreenProps) => {
  const styles = useStyleSheet(themedStyles)
  const dimensions = useWindowDimensions()

  const hasSmallScreen = Device.hasSmallScreen(dimensions)
  const textType = hasSmallScreen ? 'small' : 'regular'

  const onAllowButtonPress = async () => {
    let notificationsRegisterResponse = null
    try {
      if (Device.ios) {
        notificationsRegisterResponse = await PushNotificationIOS.requestPermissions({
          sound: true,
          alert: true,
          badge: true,
        })
      } else {
        notificationsRegisterResponse = await requestNotifications([])
      }
    } finally {
      Analytics.track(CustomEventTypes.OnboardingPushNotificationsRegister, {
        notificationsRegisterResponse,
      })
      onAllowButtonPressCallback()
    }
  }

  const isDarkMode = useIsDarkMode()
  const imageSource = isDarkMode
    ? notificationsPermissionDarkImage.imageSource
    : notificationsPermissionLightImage.imageSource

  return (
    <SafeAreaView edges={['bottom']} style={[styles.container, { width }]}>
      <ScrollViewWithFade fadeColor={styles.container.backgroundColor}>
        <Image
          source={imageSource}
          style={styles.image as StyleProp<ImageStyle>}
          resizeMode="contain"
        />
        <View style={styles.textContent}>
          <Text type="title-2" style={styles.title}>
            Never Miss an Insight
          </Text>
          <Text type="large" style={styles.description}>
            Boost your self-discovery and accountability with notifications.
          </Text>
          <View style={styles.line}>
            <Icon name="check" style={styles.checkmark} />
            <Text type={textType}>Live patterns and trends in your data</Text>
          </View>
          <View style={styles.line}>
            <Icon name="check" style={styles.checkmark} />
            <Text type={textType}>Messages from your personal nutritionist</Text>
          </View>
          <View style={styles.line}>
            <Icon name="check" style={styles.checkmark} />
            <Text type={textType}>Sensor alerts and reminders</Text>
          </View>
        </View>
      </ScrollViewWithFade>
      <Button
        type="primary"
        size="block"
        onPress={onAllowButtonPress}
        accessibilityLabel="Allow Notifications"
      >
        Allow Notifications
      </Button>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  image: {
    width: 230,
    height: 250,
    alignSelf: 'center',
    marginVertical: 20,
  },
  textContent: {
    flex: 1,
    paddingVertical: 16,
  },
  title: {
    textAlign: 'center',
  },
  description: {
    paddingVertical: 24,
    textAlign: 'center',
  },
  line: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    paddingVertical: 8,
  },
  checkmark: {
    width: 24,
    height: 24,
    marginRight: 8,
    color: 'theme.success.base',
  },
})
