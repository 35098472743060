import React, { useEffect } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { GestureDismissibleStackedModal, OutlinedIcon } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { BillingProductCategory } from '@src/types'
import { CustomEventTypes } from '@src/config'
import { useNutritionistHubTracker } from '../hooks'

export const ScheduleCallOrPurchasePlanModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const route = useRoute<RouteProp<AppStackParamList, 'ScheduleCallOrPurchasePlanModal'>>()
  const { appointmentType, appointmentKind } = route.params

  const track = useNutritionistHubTracker()

  const onScheduleCallPress = () => {
    track(CustomEventTypes.NutritionistHubScheduleCallTapped)
    navigation.navigate('CallScheduling', {
      appointmentType,
      appointmentKind,
      allowBackNavigation: true,
    })
  }

  const onPurchasePlanPress = () => {
    track(CustomEventTypes.NutritionistHubPurchasePlanTapped)
    navigation.navigate('MonthlyNutritionCoachingCheckout')
  }

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: BillingProductCategory.Marketplace },
    })
  }, [dispatch])

  return (
    <GestureDismissibleStackedModal
      actionTitle="Schedule Call"
      secondaryTitle="Purchase Plan"
      onActionPress={onScheduleCallPress}
      onSecondaryPress={onPurchasePlanPress}
      dismissButtonText="Dismiss"
    >
      <OutlinedIcon iconName="warning" style={styles.warning} />
      <Text style={styles.content} type="title-2">
        You must schedule your next call or pay for monthly nutrition coaching to send more
        messages.
      </Text>
    </GestureDismissibleStackedModal>
  )
}

const themedStyles = StyleService.create({
  warning: {
    alignSelf: 'center',
  },
  content: {
    textAlign: 'center',
    margin: 8,
  },
})
