import { Dispatch } from 'react'
import { NativeModules } from 'react-native'
import { AnyAction } from 'redux'
import { upperFirst } from 'lodash'
import { Analytics, CustomEventTypes } from '@src/config'
import { Notification, NotificationEngineNotificationActionKind } from '@src/types'
import { openUrl } from '@src/utils'
import { ScoreSectionEnum } from '@src/screens/Insights/utils/pageConfig'
interface EngineNotificationHandlerParams {
  notificationData: any
  userId: string
  dispatch: Dispatch<AnyAction>
}

const BASE_URL = `${NativeModules.Configuration.scheme}://`

const ACTION_HANDLERS: {
  [key in NotificationEngineNotificationActionKind]?: (
    handlerArgs: EngineNotificationHandlerParams,
  ) => void
} = {
  [NotificationEngineNotificationActionKind.DeepLink]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { deepLinkPath } = notificationData

    openUrl(`${BASE_URL}${deepLinkPath}`)
  },
  [NotificationEngineNotificationActionKind.NoraPrompt]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { cannedPromptKey } = notificationData

    const url = `${BASE_URL}app/chat?cannedPromptKey=${cannedPromptKey}`
    openUrl(url)
  },
  [NotificationEngineNotificationActionKind.ScanScreen]: (_handlerArgs) => {
    const url = `${BASE_URL}app/modals/newScan`
    openUrl(url)
  },
  [NotificationEngineNotificationActionKind.InsightsGlucoseScreen]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { startDate, endDate } = notificationData

    openUrl(
      `${BASE_URL}app/insights/score?statMetaKey=${ScoreSectionEnum.DAILY_AVERAGE}&startDate=${startDate}&endDate=${endDate}`,
    )
  },
  [NotificationEngineNotificationActionKind.InsightsMealScreen]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { startDate, endDate } = notificationData
    openUrl(
      `${BASE_URL}app/insights/score?statMetaKey=${ScoreSectionEnum.MEALS_AVERAGE}&startDate=${startDate}&endDate=${endDate}`,
    )
  },
  [NotificationEngineNotificationActionKind.NewMealScreen]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { mealType } = notificationData
    openUrl(`${BASE_URL}app/modals/newMeal?mealKind=${mealType}`)
  },
  [NotificationEngineNotificationActionKind.NewActivityScreen]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { activityType } = notificationData
    openUrl(`${BASE_URL}app/modals/newActivity?activityType=${upperFirst(activityType)}`)
  },
  [NotificationEngineNotificationActionKind.NewJournalScreen]: (_handlerArgs) => {
    openUrl(`${BASE_URL}app/modals/newJournalEntry`)
  },
  [NotificationEngineNotificationActionKind.EditJournalScreen]: (handlerArgs) => {
    const { notificationData, dispatch } = handlerArgs
    dispatch({
      type: 'events/fetch',
      useCache: false,
      metadata: { isRefresh: true },
    })

    const { noteId, body, occurredAt } = notificationData
    const url =
      `${BASE_URL}app/modals/newJournalEntry?id=${noteId}` +
      `&body=${encodeURIComponent(body)}` +
      `&occurredAt=${encodeURIComponent(occurredAt)}`

    openUrl(url)
  },
  [NotificationEngineNotificationActionKind.ExternalLink]: (handlerArgs) => {
    const { notificationData } = handlerArgs
    const { externalLink } = notificationData

    openUrl(externalLink)
  },
}

export const handleEngineNotification = (params: EngineNotificationHandlerParams) => {
  const { notificationData, userId, dispatch } = params
  const handler =
    ACTION_HANDLERS[notificationData.actionKind as NotificationEngineNotificationActionKind]
  if (handler) {
    handler(params)
  }

  dispatch({
    type: 'notifications/complete',
    payload: { id: notificationData.id },
    success: (notification: Notification) => {
      Analytics.track(CustomEventTypes.PushNotificationTapped, {
        ...notificationData,
        userId,
        dateSent: notification.createdAt,
      })
    },
  })
}
