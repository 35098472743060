import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { DATE_FORMAT } from '@config/momentFormat'
import { NavigationBarAccessory } from '@components'
import { ShareableViews } from '@containers/ShareViewModal/constants'
import { AppRouteProp, AppStackParamList } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { MealDetailComponent } from '@screens/Meals'
import { copyMeal, getDraftMeal } from '@screens/Meals/utils'

export const MealDetail = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const { params } = useRoute<AppRouteProp<'MealDetail'>>()
  const { item } = params

  const [favorite, originalSetFavorite] = useState(item.favorite)

  const editAction: NavigationBarAccessory = {
    iconDescriptor: { pack: 'eva', name: 'edit-outline' },
    onPress: () => navigation.navigate('EditMeal', { id: item.id }),
    accessibilityLabel: 'Edit Meal',
  }

  const onJumpToDate = () => {
    const startDate = moment(item.occurredAt).format(DATE_FORMAT)
    const endDate = startDate

    navigation.navigate('Drawer', {
      screen: 'Dashboard',
      params: { screen: 'Events' },
    })
    dispatch({
      type: 'app/changeDateRange',
      payload: {
        startDate,
        endDate,
      },
    })
  }

  const onMealCopy = () => {
    dispatch({
      type: 'meals/saveDraft',
      payload: getDraftMeal(copyMeal(item)),
    })

    navigation.navigate('AddMeal')
  }

  const onShare = () => {
    navigation.navigate('ShareViewModal', {
      item,
      shareableViewName: ShareableViews.MealDetail,
    })
  }

  const setFavorite = (newFavorite: boolean) => {
    if (!item.id) {
      return
    }

    originalSetFavorite(newFavorite)
    dispatch({
      type: 'meals/updateFavorite',
      payload: {
        ...item,
        favorite: newFavorite,
        time: item.occurredAt, // `time` field is required until BE is updated
      },
    })
  }

  return (
    <NavigationContainer title={item.title} rightAccessories={[editAction]}>
      <MealDetailComponent
        item={item}
        favorite={favorite}
        setFavorite={setFavorite}
        onJumpToDate={onJumpToDate}
        copyMeal={onMealCopy}
        onShare={onShare}
      />
    </NavigationContainer>
  )
}
