import { gql } from '@apollo/client'

export const FETCH_EHR_AVAILABLE_SLOTS = gql`
  query ehrAvailableDateSlots(
    $appointmentKind: EhrAppointmentKind
    $providerKind: EhrAppointmentProviderKind
    $timeZone: String
    $date: String!
  ) {
    ehrAvailableDateSlots(
      appointmentKind: $appointmentKind
      providerKind: $providerKind
      timeZone: $timeZone
      date: $date
    ) {
      slots {
        time
        providerId
        providerUserId
      }
    }
  }
`
