import { useDispatch } from 'react-redux'
import { useMemo } from 'react'
import { IconName } from '@components/base'
import { Feature, useFeatureFlag } from '@components'
import { useHasLargeScreen } from '@src/config/device'
import { DrawerParamList } from '@src/navigation/types'
import {
  EventsScreen,
  LearnScreen,
  ProfileScreen,
  InsightsScreen,
  NutritionistHubScreen,
} from '@screens'

export interface DrawerItemInfo {
  title: string
  routeName?: keyof DrawerParamList
  onItemPress?: () => void
  icon: IconName
  component?: React.ComponentType
  routeParams?: Record<string, any>
  tag?: string
  indicator?: string
  includeIf?: boolean
}

export const useDrawerItems = () => {
  const dispatch = useDispatch()
  const hasLargeScreen = useHasLargeScreen()

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)
  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  const allDrawerItems = useMemo((): DrawerItemInfo[] => {
    return [
      {
        title: 'Home',
        routeName: 'Dashboard',
        icon: 'house-simple',
        includeIf: hasLargeScreen,
      },
      {
        title: 'Events',
        routeName: 'Events',
        icon: 'clock',
        component: EventsScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: insightsTitle,
        routeName: 'Insights',
        icon: 'trend-up',
        component: InsightsScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: 'Nutritionist',
        routeName: 'NutritionistHub',
        icon: 'users',
        component: NutritionistHubScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: 'Learn',
        routeName: 'Learn',
        icon: 'book-open',
        component: LearnScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: 'Sign out',
        onItemPress: () => dispatch({ type: 'app/logout' }),
        icon: 'sign-out',
      },
    ]
  }, [dispatch, hasLargeScreen, insightsTitle])

  return {
    visibleDrawerItems: allDrawerItems.filter(({ includeIf = true }) => includeIf),
    allDrawerItems,
  }
}

export interface DrawerRoute {
  routeName: keyof DrawerParamList
  component: React.ComponentType
}

export const useDrawerRoutes = (): DrawerRoute[] => {
  const { allDrawerItems } = useDrawerItems()
  const drawerRoutes = allDrawerItems
    .map(({ routeName, component }) => ({ routeName, component }))
    .filter(({ routeName, component }) => routeName && component) as DrawerRoute[]

  return [
    ...drawerRoutes,
    {
      routeName: 'Account',
      component: ProfileScreen,
    },
  ]
}
