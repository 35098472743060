import React from 'react'
import { Keyboard } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { Haptic } from '@utils'
import { calendarDateSelector } from '@src/selectors/app'
import { useForceLandscapeOrientation } from '@src/utils/orientation'
import { DateSelector } from './DateSelector'
import { DateSelectorType } from './types'

interface DateSelectorControllerProps {
  type?: DateSelectorType
  allowRangeSelection?: boolean
}

export const DateSelectorController = ({
  type = 'app',
  allowRangeSelection = true,
}: DateSelectorControllerProps) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const forceLandscapeOrientation = useForceLandscapeOrientation()

  const { startDate, endDate } = useSelector(calendarDateSelector)

  const onDateChange = (startDate: string, endDate: string) => {
    Haptic.heavyTap()

    dispatch({
      type: `${type}/changeDateRange`,
      payload: { startDate, endDate },
    })
  }

  const onDatePickerPress = () => {
    if (forceLandscapeOrientation) {
      return
    }
    Haptic.heavyTap()
    Keyboard.dismiss()

    navigation.navigate('ChangeDateRange', { type, allowRangeSelection })
  }

  return (
    <DateSelector
      startDate={startDate}
      endDate={endDate}
      onDatePickerPress={onDatePickerPress}
      onDateChange={onDateChange}
    />
  )
}
