import { createSelector } from 'reselect'
import moment from 'moment'
import { RootStoreState } from '@src/models/app.types'
import { calendarDateSelector, eventsFiltersTypesSelector } from '@src/selectors/app'

export const eventsStoreStateSelector = (state: RootStoreState) => state.events

export const eventsObjectsSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.events,
)

export const eventsForDateSelector = createSelector(
  [eventsObjectsSelector, calendarDateSelector],
  (events, calendar) =>
    events.filter((event) => moment(event.occurredAt).isSame(calendar.startDate, 'day')),
)

export const eventsSortSelector = createSelector(
  [eventsStoreStateSelector],
  (events) => events.sort,
)

export const eventsSelector = createSelector(
  [eventsStoreStateSelector, calendarDateSelector, eventsFiltersTypesSelector],
  (events, calendar, filters) => ({
    sort: events.sort,
    items: events.events,
    calendar,
    filters,
  }),
)
