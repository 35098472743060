import { useSelector } from 'react-redux'
import { measurementsConfigSelector } from '@src/selectors/app'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import {
  dailyNutritionStatisticsSelector,
  dailyNutritionScoreSelector,
} from '@src/selectors/dailyNutrition'

export const useScoreStatistics = () => {
  const measurements = useSelector(measurementsConfigSelector)
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const units = measurements.find(({ name }) => name === 'glucose')?.[unitSystem]?.units ?? 'mg/dL'

  const summaryStats = useSelector(dailyNutritionStatisticsSelector)
  const summary = summaryStats.today ?? {}

  const scoreStats = useSelector(dailyNutritionScoreSelector)
  const score = scoreStats?.today ?? {}

  return { units, summary, score }
}
