import { first, last, isEmpty } from 'lodash'
import moment from 'moment-timezone'
import { LocalMeal as Meal } from '@src/screens/Events/models/events.types'
import { Activity } from '@src/types'

export const extractIntervalValues = (item: Meal | Activity): { start: any; end: any } | null => {
  const { glucose, occurredAt: startedAt } = item
  const start = moment(startedAt)
  const end = moment(startedAt).add(2, 'h')
  const data = glucose?.values.filter((v) => moment(v.timestamp).isBetween(start, end))

  if (!data || isEmpty(data)) {
    return null
  }

  return {
    start: first(data),
    end: last(data),
  }
}
