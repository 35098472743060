import React, { useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment/moment'
import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { compact, uniq } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Button, Text } from '@components/base'
import {
  Availability,
  EhrAppointmentRecurrenceCadence,
  EhrAppointmentRecurrenceDay,
} from '@src/types.ts'
import { CommonSelect, InlineAlert } from '@components'
import { MultiSelect } from '@components/MultiSelect.tsx'
import {
  dayOfWeekToRecurrenceDayLookup,
  MAX_RECURRENCE_CALLS,
  orderedCadenceOptions,
  TIME_SLOT_FORMAT,
  UNLIMITED_INSURANCE_CALLS,
  UPSERT_ERROR_MESSAGE,
} from '@screens/NutritionistHub/components/UpcomingCalls/constants.ts'
import {
  buildTimeSlotOptions,
  toStartCaseOption,
  toStringOption,
  toTimeSlotOption,
} from '@screens/NutritionistHub/components/UpcomingCalls/helpers.ts'
import { AppStackParamList } from '@navigation/types'
import { CustomEventTypes, ErrorMessages } from '@config'
import { useSnack } from '@utils'
import { useNutritionistHubTracker } from '@screens/NutritionistHub/hooks.ts'
import { userSelector } from '@src/selectors/app'

export const UpsertRecurrence = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const showSnack = useSnack()
  const track = useNutritionistHubTracker()
  const route = useRoute<RouteProp<AppStackParamList, 'UpsertRecurrence'>>()

  const user = useSelector(userSelector)

  const [availabilities, setAvailabilities] = useState<Availability[]>([])
  const [upsertLoading, setUpsertLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const { appointmentRecurrence, insurancePolicy } = route.params

  const hasUnlimitedCall = insurancePolicy.unitsLeft === UNLIMITED_INSURANCE_CALLS
  const maxCalls = hasUnlimitedCall ? MAX_RECURRENCE_CALLS : insurancePolicy.unitsLeft || 0

  const defaultValues = {
    cadence: appointmentRecurrence?.cadence && toStartCaseOption(appointmentRecurrence.cadence),
    days: (appointmentRecurrence?.days || []).map(toStartCaseOption),
    timeSlots: (appointmentRecurrence?.timeSlots || []).map(toTimeSlotOption),
    numberOfCalls:
      appointmentRecurrence?.numberOfCalls && toStringOption(appointmentRecurrence.numberOfCalls),
  }

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues,
  })

  const days = watch('days')

  const dayOptions = useMemo(() => {
    const availableDays = uniq(availabilities.map((availability) => availability.dayOfWeek))
      .sort()
      .map((dayOfWeek) => dayOfWeekToRecurrenceDayLookup[dayOfWeek])

    return [EhrAppointmentRecurrenceDay.All, ...availableDays].map(toStartCaseOption)
  }, [availabilities])

  const timeSlotOptions = useMemo(() => {
    return buildTimeSlotOptions(
      availabilities,
      days.map((day) => day.value as EhrAppointmentRecurrenceDay),
    )
  }, [availabilities, days])

  useEffect(() => {
    if (user?.migrationFromInsuranceEnabled) {
      dispatch({
        type: 'nutritionistHub/fetchEhrAllAvailabilities',
        success: (availabilities: Availability[]) => setAvailabilities(availabilities),
      })

      return
    }

    dispatch({
      type: 'nutritionistHub/fetchAllAvailabilities',
      success: (availabilities: Availability[]) => setAvailabilities(availabilities),
    })
  }, [dispatch, user?.migrationFromInsuranceEnabled])

  const onUpsertRecurrence = handleSubmit(({ cadence, days, numberOfCalls, timeSlots }) => {
    if (!cadence || !days || !numberOfCalls) {
      return
    }

    const recurrence = {
      cadence: cadence.value as EhrAppointmentRecurrenceCadence,
      days: days.map((day) => day.value as EhrAppointmentRecurrenceDay),
      numberOfCalls: numberOfCalls.value as number,
      timeSlots: timeSlots.map((timeSlot) => moment(timeSlot.value, TIME_SLOT_FORMAT)),
    }

    track(
      appointmentRecurrence
        ? CustomEventTypes.NutritionistHubRecurrenceUpdated
        : CustomEventTypes.NutritionistHubRecurrenceCreated,
    )

    setUpsertLoading(true)
    dispatch({
      type: 'nutritionistHub/upsertAppointmentRecurrence',
      payload: recurrence,
      success: () => {
        dispatch({ type: 'nutritionistHub/fetchAppointmentRecurrence' })
        navigation.dispatch((state) => {
          // Remove current route
          const routes = state.routes.slice(0, -1)

          // navigate to NutritionistHub with UpsertRecurrenceSuccessfulModal
          const newRoutes = [
            ...routes,
            {
              name: 'Drawer',
              params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } },
            },
            { name: 'UpsertRecurrenceSuccessfulModal' },
          ]

          return CommonActions.reset({
            index: newRoutes.length - 1,
            routes: newRoutes,
          } as any)
        })
      },
      failure: (error: any) => {
        const errorMessage = error?.message || UPSERT_ERROR_MESSAGE
        showSnack(errorMessage, null, 'error')
      },
      complete: () => {
        setUpsertLoading(false)
      },
    })
  })

  const onDeleteRecurrence = () => {
    track(CustomEventTypes.NutritionistHubRecurrenceDeleted)
    setDeleteLoading(true)
    dispatch({
      type: 'nutritionistHub/deleteAppointmentRecurrence',
      success: () => {
        dispatch({ type: 'nutritionistHub/fetchAppointmentRecurrence' })
        if (user?.migrationFromInsuranceEnabled) {
          dispatch({ type: 'nutritionistHub/fetchUpcomingEhrAppointments' })
        } else {
          dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
        }
        navigation.navigate('AllVideoCalls')
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
      complete: () => {
        setDeleteLoading(false)
      },
    })
  }

  const upsertButtonText = appointmentRecurrence ? 'Save' : 'Create a Recurring Call'

  return (
    <NavigationContainer
      title={`${appointmentRecurrence ? 'Edit' : 'Create'} Recurrence`}
      style={{ flex: 1 }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={[styles.container, { paddingBottom: insets?.bottom || 0 }]}
      >
        <View style={styles.form}>
          <Controller
            control={control}
            name="cadence"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <CommonSelect
                options={orderedCadenceOptions.map(toStartCaseOption)}
                selectedOption={value}
                label="How frequently would you like to have your calls?"
                placeholder="Cadence"
                onSelect={onChange}
                style={{ marginBottom: 24 }}
              />
            )}
          />
          <Controller
            control={control}
            name="days"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <View style={{ marginBottom: 24 }}>
                <MultiSelect
                  options={dayOptions}
                  selectedOptions={value}
                  label="What are your preferred day(s) of the week?"
                  placeholder="Days"
                  // compacting since previously selected day might not be available anymore
                  onSelect={(option) => onChange(compact(option))}
                />
                <Text type="regular" style={styles.caption}>
                  Select all that apply.
                </Text>
              </View>
            )}
          />
          <Controller
            control={control}
            name="timeSlots"
            render={({ field: { value, onChange } }) => (
              <View style={{ marginBottom: 24 }}>
                <MultiSelect
                  options={timeSlotOptions}
                  selectedOptions={value}
                  label="What are your preferred time slots?"
                  placeholder="Time Slots"
                  // compacting since previously selected slot might not be available anymore
                  onSelect={(option) => onChange(compact(option))}
                />
                <Text type="regular" style={styles.caption}>
                  Select all that apply.
                </Text>
              </View>
            )}
          />
          <Controller
            control={control}
            name="numberOfCalls"
            rules={{ required: true }}
            render={({ field: { value, onChange } }) => (
              <CommonSelect
                options={Array.from({ length: maxCalls }, (_, i) => i + 1).map(toStringOption)}
                selectedOption={value ? value : undefined}
                label="How many calls would you like to schedule?"
                placeholder="Number of Calls"
                onSelect={onChange}
                style={{ marginBottom: 24 }}
              />
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <InlineAlert
            style={styles.bottomItem}
            message={
              appointmentRecurrence
                ? 'Editing a recurrence will delete all your scheduled recurring calls ' +
                  'and replace them according to your new preferences.'
                : 'Your recurrence may take a few minutes to complete once submitted.'
            }
          />
          <Button
            type="primary"
            size="block"
            disabled={!isValid || upsertLoading}
            style={styles.bottomItem}
            onPress={onUpsertRecurrence}
            accessibilityLabel={upsertButtonText}
          >
            {upsertButtonText}
          </Button>
          {appointmentRecurrence && (
            <Button
              type="transparent"
              size="block"
              disabled={deleteLoading}
              style={styles.bottomItem}
              textStyle={styles.deleteButtonText}
              onPress={onDeleteRecurrence}
              accessibilityLabel="Delete Recurrence"
            >
              Delete Recurrence
            </Button>
          )}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  form: {
    flex: 1,
  },
  bottomContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  caption: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
  bottomItem: {
    marginTop: 'auto',
  },
  deleteButtonText: {
    color: 'theme.error.base',
  },
})
